import React from 'react';
import {ReactComponent as ErrorIcon} from '../../image/icon/ic_default_error.svg';

export default function InnerError({errorCode}) {
  // =================================================
  return (
    <div className="C_InnerError">
      {errorCode === 500 ? (
        <>
          <ErrorIcon />
          <h1>채팅방 접근 불가</h1>
          <div>
            <p>죄송합니다. 서버에 문제가 생겨 채팅방에 접근할 수 없습니다.</p>
            <p>메디스태프 관리자에게 문의하여주세요.</p>
          </div>
        </>
      ) : (
        <>
          <ErrorIcon />
          <h1>채팅방 접근 불가</h1>
          <div>
            <p>죄송합니다. 오류가 발생하여 채팅방에 접근할 수 없습니다.</p>
            <p>친구에게 새 링크를 요청하신 후 신규 채팅방을 이용해 주세요.</p>
          </div>
        </>
      )}
    </div>
  );
}
