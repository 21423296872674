import React, {useCallback, useRef} from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {ReactComponent as ArrowLeftIcon} from 'image/icon/arrow_left.svg';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal';

export default function FullMessage({message}) {
  const dispatch = useDispatch();
  const FullMessageScrollRef = useRef(null);
  const FullMessageOnScroll = useCallback((values) => {}, []);
  // ****************
  return (
    <session className="C_FullMessage">
      <div className="header">
        <div className="icon_box" onClick={() => dispatch(modalActions.setFullMessage(''))}>
          <ArrowLeftIcon />
        </div>
        전체보기
      </div>
      <Scrollbars autoHide={true} ref={FullMessageScrollRef} onScrollFrame={FullMessageOnScroll}>
        <p>{message}</p>
      </Scrollbars>
    </session>
  );
}
