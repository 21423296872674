import {generateKeyPair, sharedKey, sign, verify} from './curve25519';

export const fromHexString = (hexString) => {
  // if (hexString === undefined || typeof hexString !== 'string') {
  // 	throw new Error('Invalid hexString');
  // }
  return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
};

export const toHexString = (bytes) => {
  return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '').toUpperCase();
};

export async function generate_hash(str) {
  return toHexString(
    new Uint8Array(await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(str))),
  );
}

export function generate_key() {
  return toHexString(crypto.getRandomValues(new Uint8Array(32)));
}

export function generate_key_pair() {
  let key_pair = generateKeyPair(crypto.getRandomValues(new Uint8Array(32)));
  return {
    private_key: toHexString(key_pair.private),
    public_key: '05' + toHexString(key_pair.public),
  };
}

export function calculate_signature(private_key, message) {
  try {
    return toHexString(sign(fromHexString(private_key), fromHexString(message)));
  } catch (error) {
    console.error('<calculate_signature> 에러', error);
  }
}

export function verify_signature(public_key, signature, message) {
  return verify(
    fromHexString(public_key.slice(2)),
    fromHexString(message),
    fromHexString(signature),
  );
}

export async function x3dh_sender(message) {
  let dh_sender_ik_receiver_spk = sharedKey(
    fromHexString(message.sender_ik.private_key_hex),
    fromHexString(message.receiver_spk.public_key_hex.slice(2)),
  );
  let dh_sender_ek_receiver_ik = sharedKey(
    fromHexString(message.sender_ek.private_key_hex),
    fromHexString(message.receiver_ik.public_key_hex.slice(2)),
  );
  let dh_sender_ek_receiver_spk = sharedKey(
    fromHexString(message.sender_ek.private_key_hex),
    fromHexString(message.receiver_spk.public_key_hex.slice(2)),
  );
  let dh_sender_ek_receiver_opk = sharedKey(
    fromHexString(message.sender_ek.private_key_hex),
    fromHexString(message.receiver_opk.public_key_hex.slice(2)),
  );
  let shared_key = new Uint8Array(128);
  shared_key.set(dh_sender_ik_receiver_spk, 0);
  shared_key.set(dh_sender_ek_receiver_ik, 32);
  shared_key.set(dh_sender_ek_receiver_spk, 64);
  shared_key.set(dh_sender_ek_receiver_opk, 96);
  let info = new TextEncoder().encode('mschat_x3dh');
  var shared_key_struct = await window.crypto.subtle.importKey(
    'raw',
    shared_key,
    {name: 'HKDF'},
    false,
    ['deriveKey', 'deriveBits'],
  );

  return toHexString(
    new Uint8Array(
      await crypto.subtle.deriveBits(
        {name: 'HKDF', hash: 'SHA-256', salt: new Uint8Array([]), info: info},
        shared_key_struct,
        256,
      ),
    ),
  );
}

export async function x3dh_receiver(message) {
  let dh_sender_ik_receiver_spk = sharedKey(
    fromHexString(message.receiver_spk.private_key_hex),
    fromHexString(message.sender_ik.public_key_hex.slice(2)),
  );
  let dh_sender_ek_receiver_ik = sharedKey(
    fromHexString(message.receiver_ik.private_key_hex),
    fromHexString(message.sender_ek.public_key_hex.slice(2)),
  );
  let dh_sender_ek_receiver_spk = sharedKey(
    fromHexString(message.receiver_spk.private_key_hex),
    fromHexString(message.sender_ek.public_key_hex.slice(2)),
  );
  let dh_sender_ek_receiver_opk = sharedKey(
    fromHexString(message.receiver_opk.private_key_hex),
    fromHexString(message.sender_ek.public_key_hex.slice(2)),
  );
  let shared_key = new Uint8Array(128);
  shared_key.set(dh_sender_ik_receiver_spk, 0);
  shared_key.set(dh_sender_ek_receiver_ik, 32);
  shared_key.set(dh_sender_ek_receiver_spk, 64);
  shared_key.set(dh_sender_ek_receiver_opk, 96);
  let info = new TextEncoder().encode('mschat_x3dh');
  var shared_key_struct = await window.crypto.subtle.importKey(
    'raw',
    shared_key,
    {name: 'HKDF'},
    false,
    ['deriveKey', 'deriveBits'],
  );

  return toHexString(
    new Uint8Array(
      await crypto.subtle.deriveBits(
        {name: 'HKDF', hash: 'SHA-256', salt: new Uint8Array([]), info: info},
        shared_key_struct,
        256,
      ),
    ),
  );
}

export async function dh_ratchet(public_key, private_key, link_key) {
  let shared_key = sharedKey(fromHexString(private_key), fromHexString(public_key.slice(2)));
  let salt = fromHexString(link_key);
  let info = new TextEncoder().encode('mschat_dhratchet');
  var shared_key_struct = await window.crypto.subtle.importKey(
    'raw',
    shared_key,
    {name: 'HKDF'},
    false,
    ['deriveKey', 'deriveBits'],
  );

  let derived_key = new Uint8Array(
    await crypto.subtle.deriveBits(
      {name: 'HKDF', hash: 'SHA-256', salt: salt, info: info},
      shared_key_struct,
      512,
    ),
  );
  return {
    link_key: toHexString(derived_key.slice(0, 32)),
    output_key: toHexString(derived_key.slice(32)),
  };
}

export async function kdf_ratchet(link_key) {
  let salt = fromHexString(link_key);
  let info = new TextEncoder().encode('mschat_kdfratchet');
  let shared_key_struct = await window.crypto.subtle.importKey(
    'raw',
    new Uint8Array([0x01]),
    {name: 'HKDF'},
    false,
    ['deriveKey', 'deriveBits'],
  );
  let derived_key = new Uint8Array(
    await crypto.subtle.deriveBits(
      {name: 'HKDF', hash: 'SHA-256', salt: salt, info: info},
      shared_key_struct,
      512,
    ),
  );
  return {
    link_key: toHexString(derived_key.slice(0, 32)),
    output_key: toHexString(derived_key.slice(32)),
  };
}

export async function encrypt_message(plaintext_message, message_key, dh_chain_ind, kdf_chain_ind) {
  let nonce = crypto.getRandomValues(new Uint8Array(12));
  let aes_key_struct = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(message_key),
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
  let encrypt_result = new Uint8Array(
    await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: nonce,
        additionalData: new TextEncoder().encode(
          dh_chain_ind.toString() + kdf_chain_ind.toString(),
        ),
      },
      aes_key_struct,
      new TextEncoder().encode(plaintext_message),
    ),
  );

  return {
    encrypted_message: toHexString(encrypt_result.slice(0, encrypt_result.byteLength - 16)),
    tag: toHexString(encrypt_result.slice(encrypt_result.byteLength - 16)),
    nonce: toHexString(nonce),
  };
}

export async function decrypt_message(
  encrypted_message,
  message_key,
  dh_chain_ind,
  kdf_chain_ind,
  tag,
  nonce,
) {
  let aes_key_struct = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(message_key),
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
  let decrypt_result = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: fromHexString(nonce),
      additionalData: new TextEncoder().encode(dh_chain_ind.toString() + kdf_chain_ind.toString()),
    },
    aes_key_struct,
    fromHexString(encrypted_message + tag),
  );

  return new TextDecoder().decode(decrypt_result);
}

export async function encrypt_file(file_key, hmac_key, file) {
  let counter = crypto.getRandomValues(new Uint8Array(16));
  counter.set([0, 0, 0, 0], 12);
  let nonce = toHexString(counter.subarray(0, 12));
  let aes_key_struct = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(file_key),
    {
      name: 'AES-CTR',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
  let encrypt_result = new Uint8Array(
    await crypto.subtle.encrypt(
      {
        name: 'AES-CTR',
        counter: counter,
        length: 32,
      },
      aes_key_struct,
      await file.arrayBuffer(),
    ),
  );

  let hmac_key_struct = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(hmac_key),
    {
      name: 'HMAC',
      length: 256,
      hash: {name: 'SHA-256'},
    },
    true,
    ['sign', 'verify'],
  );

  let hmac_result = new Uint8Array(
    await window.crypto.subtle.sign('HMAC', hmac_key_struct, encrypt_result),
  );

  return {encrypted_file: encrypt_result, nonce: nonce, hmac_hash: toHexString(hmac_result)};
}

export async function decrypt_file(file_key, hmac_key, nonce, key, file) {
  let file_data = new Uint8Array(await file.arrayBuffer());

  let hmac_key_struct = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(hmac_key),
    {
      name: 'HMAC',
      length: 256,
      hash: {name: 'SHA-256'},
    },
    true,
    ['sign', 'verify'],
  );

  let hmac_result = await window.crypto.subtle.verify(
    'HMAC',
    hmac_key_struct,
    fromHexString(key),
    file_data,
  );

  if (!hmac_result) {
    throw new Error('Hmac 검증에 실패했습니다.');
  }

  let counter = new Uint8Array(16);
  counter.set(fromHexString(nonce), 0);
  counter.set([0, 0, 0, 0], 12);
  let aes_key_struct = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(file_key),
    {
      name: 'AES-CTR',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
  return new Uint8Array(
    await crypto.subtle.decrypt(
      {
        name: 'AES-CTR',
        counter: counter,
        length: 32,
      },
      aes_key_struct,
      file_data,
    ),
  );
}
export async function encrypt_key(key_to_encrypt_hex, password, input_for_salt) {
  let key_to_encrypt = await window.crypto.subtle.importKey(
    'raw',
    fromHexString(key_to_encrypt_hex),
    {
      name: 'AES-CTR',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  let enc_key_material = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(password),
    {name: 'PBKDF2'},
    false,
    ['deriveBits', 'deriveKey'],
  );
  let salt = new Uint8Array(16);
  let input_for_salt_bytes = new TextEncoder().encode(input_for_salt);
  if (input_for_salt_bytes.length > 16) {
    salt.set(input_for_salt_bytes.subarray(0, 16), 0);
  } else {
    salt.set(input_for_salt_bytes, 16 - input_for_salt_bytes.length);
  }
  let enc_key = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt,
      iterations: 100000,
      hash: 'SHA-256',
    },
    enc_key_material,
    {name: 'AES-KW', length: 256},
    true,
    ['wrapKey', 'unwrapKey'],
  );

  return toHexString(
    new Uint8Array(await crypto.subtle.wrapKey('raw', key_to_encrypt, enc_key, 'AES-KW')),
  );
}
/*
key_to_decrypt_hex = "2C15904D294C46A722FC1C8909415C164C5F65004C3C794375E57A80B5A0C4A74B9EC74FCD0FEFD3"
password = "1111"
input_for_salt = 22
*/
export async function decrypt_key(key_to_decrypt_hex, password, input_for_salt) {
  try {
    let dec_key_material = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(password),
      {name: 'PBKDF2'},
      false,
      ['deriveBits', 'deriveKey'],
    );
    let salt = new Uint8Array(16);
    let input_for_salt_bytes = new TextEncoder().encode(input_for_salt);
    if (input_for_salt_bytes.length > 16) {
      salt.set(input_for_salt_bytes.subarray(0, 16), 0);
    } else {
      salt.set(input_for_salt_bytes, 16 - input_for_salt_bytes.length);
    }
    let dec_key = await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: 100000,
        hash: 'SHA-256',
      },
      dec_key_material,
      {name: 'AES-KW', length: 256},
      true,
      ['wrapKey', 'unwrapKey'],
    );

    let result_key_object = await window.crypto.subtle.unwrapKey(
      'raw',
      fromHexString(key_to_decrypt_hex),
      dec_key,
      'AES-KW',
      'AES-CTR',
      true,
      ['encrypt', 'decrypt'],
    );
    return toHexString(new Uint8Array(await crypto.subtle.exportKey('raw', result_key_object)));
  } catch (error) {
    console.error('<decrypt_key> 에러', error);
  }
}
