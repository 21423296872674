import {useCallback, useRef, useState} from 'react';
import * as StompJs from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {Token} from 'class/Token';
import {useDispatch, useSelector} from 'react-redux';
import {chatActions} from 'modules/chat';
import {userActions} from '../modules/user';
import {useLocation, useNavigate} from 'react-router-dom';
import IndexedDB from '../class/IndexedDB';
import {getRoomUrl} from '../utils/Functions';
import {useQueryClient} from 'react-query';
import {store} from '../store';

let socket = undefined;
// 글로벌 훅에다가 소켓 정보를 저장
const useSocket = (workspace) => {
  const failCount = useRef(0);
  function getUserFromRedux() {
    return store.getState().user;
  }

  window.socket = socket;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dbInstance = new IndexedDB();
  const user = useSelector((state) => state.user);

  // const chat = useSelector((state) => state.chat);
  const baseBackendURL = process.env.REACT_APP_BACKEND_URL;
  const tokenClass = new Token(getUserFromRedux().token);
  const location = useLocation();
  const roomUrl = getRoomUrl(location);
  const queryClient = useQueryClient();
  const isSubscribeSuccess = useRef(true);

  // 1. 웹소캣 연결(JWT토큰 전달 필요)
  const socketConnect = useCallback(
    (visaCheckData) => {
      if (!getUserFromRedux().token) {
        console.error('웹소켓을 구독하기 위한 토큰 정보가 없습니다.', getUserFromRedux().token);
        return null;
      }

      if (socket) {
        if (getUserFromRedux().token !== socket.connectHeaders.token) {
          socket.deactivate();
          console.log('기존 불량 소켓 해제');
        } else {
          // console.log('이미 소켓이 연결되어 있습니다.');
          return;
        }
      }
      // console.log(
      //   `prev token : ${socket?.connectHeaders.token} / new Token :  ${getUserFromRedux().token}`,
      // );
      const url = new URL(baseBackendURL);
      socket = new StompJs.Client({
        webSocketFactory: () => {
          return new SockJS(`${url}${workspace}`);
        },
        connectHeaders: {token: getUserFromRedux().token},
        reconnectDelay: 0,
        heartbeatIncoming: 20000,
        heartbeatOutgoing: 20000,
        debug: function (str) {
          // console.log(str);
        },
        onConnect: () => {
          tokenClass.semaphore.lock();
          console.error(
            '웹소켓 연결시 아이디',
            getUserFromRedux().requestSession['mschat_session_idx'],
          );
          // console.log('subscribe 전의 token 정보', getUserFromRedux().token);
          socketSubscribeChat(visaCheckData);
        },
        onWebSocketClose: async (event) => {
          console.error('onWebSocketClose Event', event);
          console.log('onWebSocketClose failCount', failCount.current);
          if (failCount.current > 10 || event.code === 1008) {
            console.log('웹소켓의 계속된 실패로 로그아웃합니다.');
            navigate(`/${roomUrl}`);
          }
          await queryClient.invalidateQueries({queryKey: ['requestSession', visaCheckData]});
          failCount.current = failCount.current + 1;
        },
        onStompError: (frame) => {
          console.error('onStompError 웹소켓 연결 실패!!!!', frame);
        },
      });
      socket.activate();
    },
    [baseBackendURL, dispatch, tokenClass.semaphore, getUserFromRedux().token, workspace],
  );

  // 예시: 서버 또는 브로커에서 Lock 및 Unlock 상태 관리
  const socketSubscribeChat = useCallback(
    async (visaCheckData) => {
      isSubscribeSuccess.current = true;
      const serverIk = {
        id: user.roomInfo['server_ik_id'],
        public_key_hex: user.roomInfo['server_ik_public_key'],
      };

      socket.subscribe(
        `/mschat/session/${getUserFromRedux().requestSession['mschat_session_idx']}`,
        async (message) => {
          // 구독을 성공했지만 unread_messages처리가 완료될때까지 대기 + 메시지를 끊김 없이 하나씩 처리하기 위함
          await tokenClass.semaphore.lock();
          try {
            let result = await tokenClass.receiveMessage(
              JSON.parse(message.body),
              visaCheckData,
              getUserFromRedux().requestSession['mschat_session_idx'],
              getUserFromRedux().token,
              serverIk,
              socketSend,
              user.password,
            );
            if (result === undefined || result === null) {
              return;
            } else if (result === 'fail') {
              console.log('잘못된 결과로 인해 로그아웃합니다', result);
              await disConnectSocket(); // 기존 소켓의 연결을 끊고
              dispatch(userActions.deleteRoomInfo());
              navigate(`/password-check/${roomUrl}`);
            } else {
              // 내가 보냈을 경우 이미 messages는 optimistic UI를 사용했고 나머지 정보만 변경해줘야함
              if (!!result.client_message_id) {
                dispatch(chatActions.updateChatByClientMsgId(result));
              } else {
                // 이중에서 서버 메시지중 암호화 키 변경이 있을 경우 기록
                if (result.message_type === 'KEY_EXCHANGE') {
                  dispatch(chatActions.setCurrentX3dhKeyInfo(result.currentX3dhKeyInfo));
                }

                // 상대방이 나갔을때 room_info 만 다시 불러와야함
                else if (result.message_type === 'SERVER_MESSAGE') {
                  let roomInfoResult = await tokenClass.roomInfo(
                    visaCheckData['user_idx'],
                    visaCheckData['mschat_room_idx'],
                    getUserFromRedux().token,
                  );
                  dispatch(userActions.setRoomInfo(roomInfoResult));
                }
                dispatch(chatActions.addChatByTime(result));
              }
              failCount.current = 0;
            }
          } catch (error) {
            isSubscribeSuccess.current = false;
            console.error('구독 성공했지만 메시지 수신 처리중 실패', error);
          } finally {
            await tokenClass.semaphore.unlock();
          }
        },
        {
          token: getUserFromRedux().token,
          id: getUserFromRedux().requestSession['mschat_session_idx'],
        },
      );

      if (isSubscribeSuccess.current === false) {
        console.log('ㄱㅜ독 실패했으니 preprocessing 거넌뜀');
        return;
      }

      // 웹소켓 구독 후
      const updatedMessages = await tokenClass.PreProcessing(
        visaCheckData,
        user.roomInfo,
        user.password,
        getUserFromRedux().requestSession['mschat_session_idx'],
        getUserFromRedux().token,
        serverIk,
        socketSend,
      );
      if (updatedMessages.length !== 0) {
        dispatch(chatActions.updateMessages(updatedMessages));
      }

      let cur_x3dh_key_info = await dbInstance.get_last_x3dh_key(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
      );

      if (!cur_x3dh_key_info || (cur_x3dh_key_info && user.isOtherBrowserConnection === true)) {
        await tokenClass.handshake(visaCheckData, socketSend, user.requestSession);
      }
    },
    [
      getUserFromRedux().token,
      user.roomInfo,
      user.password,
      tokenClass,
      dbInstance,
      dispatch,
      isSubscribeSuccess,
    ],
  );

  const socketSend = useCallback((url, data) => {
    if (socket === undefined) {
      console.error('생성된 소켓이 없습니다.');
      return;
    }
    // console.error(
    //   'socketSend 보낼 경우 아이디',
    //   getUserFromRedux().requestSession['mschat_session_idx'],
    // );
    socket.publish({
      destination: url + getUserFromRedux().requestSession['mschat_session_idx'],
      body: data,
      headers: {token: getUserFromRedux().token},
    });
  }, []);

  // 소켓 연결 끊기
  const disConnectSocket = useCallback(() => {
    // console.log(`workspace ${workspace} / socket ${socket}`);
    if (workspace && socket) {
      // dispatch(chatActions.setSocketState('close'));
      console.log('소켓 연결을 중지합니다.');
      socket.deactivate();
      socket = undefined;
    }
  }, [socket, workspace]);

  return {
    socket,
    socketConnect,
    disConnectSocket,
    socketSend,
  };
};
export default useSocket;
