import React, {useEffect} from 'react';
import {CircularProgressBar} from '@tomickigrzegorz/react-circular-progress-bar';
import {useDispatch, useSelector} from 'react-redux';
import {chatActions} from '../../modules/chat';
import {userActions} from '../../modules/user';

export default function UnReadMessagePercent() {
  const chat = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  // *********************************************
  useEffect(() => {
    if (!!chat && !!chat.unReadMessage.percent) {
      if (chat.unReadMessage.percent === 100) {
        setTimeout(() => {
          dispatch(chatActions.setUnReadMessageShow(false));
        }, 1000);
      }
    }
  }, [chat.unReadMessage.percent]);
  if (!chat.unReadMessage.percent) return <></>;
  return (
    <section className="C_UnReadMessagePercent">
      <CircularProgressBar
        colorCircle="#f1f1f1"
        colorSlice="#2F5DEC"
        fontWeight={700}
        fontColor="#2F5DEC"
        fontSize="16px"
        size="160px"
        percent={chat.unReadMessage.percent}
      />
      <p>암호화된 메시지를 복호화하고 있습니다.</p>
    </section>
  );
}
