import axios from 'axios';
import {
  asyncDelay,
  convertFromHex,
  convertFromHexToUtf8,
  convertToHex,
  create_qr,
  createSemaphore,
  isEmptyText,
} from '../utils/Functions';
import {
  calculate_signature,
  decrypt_key,
  decrypt_message,
  dh_ratchet,
  encrypt_key,
  encrypt_message,
  generate_hash,
  kdf_ratchet,
  verify_signature,
  x3dh_receiver,
  x3dh_sender,
} from 'utils/ChatCryptoFunctions';
import {returnFormData} from 'utils/FormFuntions';
import {store} from 'store/index'; // 실제 스토어 경로에 맞게 수정
import {chatActions} from 'modules/chat';
import IndexedDB from '../class/IndexedDB';
import {Api} from 'api/api';
import {userActions} from '../modules/user';

axios.defaults.withCredentials = true;
const tokenInstance = axios.create();
// 본인인증 -> 세션 생성 후 토큰을 사용하는 API들
export class Token {
  #baseURL = process.env.REACT_APP_BACKEND_URL;
  #token = '';
  isLocked = false;
  queue = [];
  #dbInstance;
  constructor(token) {
    this.#token = token;
    this.#dbInstance = new IndexedDB();
    this.semaphore = createSemaphore(this.isLocked, this.queue);
  }

  get messageProto() {
    return {
      model: 'M',
      type: null,
      version: 1,
      client_message_id: null,
      receipt: 'FAIL',
      receipt_detail: null,
      mschat_room_idx: 0,
      sender_user_idx: 0,
      receiver_user_idx: 0,
      mschat_message_idx: 0,
      message_send_t: null,

      ///////
      cur_main_chain_hash: null,
      dh_cur_key: {},
      dh_chain_ind: 0,
      kdf_chain_ind: 0,
      nonce: null,
      tag: null,
      message: null,
      signature: null,

      file_type: 'NONE',
      file_path: null,

      ///////
      sender_ik: {},
      sender_ek: {},
      receiver_ik: {},
      receiver_spk: {},
      receiver_opk: {},
      sender_dh_ck_1: {},
      sender_dh_ck_2: {},
    };
  }
  get userFromRedux() {
    return store.getState().user;
  }
  get chatFromRedux() {
    return store.getState().chat;
  }
  // 방 정보 불러오기(setToken과 같은 시점이기 때문에 이 함수는 this.token 사용 못함)
  async roomInfo(user_idx, mschat_room_idx, token) {
    // DB 정보가 있을시 미아된 종이비행기 채팅을 꺼내서 room_info에 같이 보낸다
    // 해당 유저, 해당 방의 메시지에서 message_status 가 plane이고 mschat_message_idx가 없는 채팅들을 꺼내어 message_idx를 array에 넣는다
    // 영원히 미아인 채팅들은 매번 물어보면 낭비가 될텐데 어떻게 막을지 고민

    const lost_client_message_ids = await this.#dbInstance.get_lost_messages(
      user_idx,
      mschat_room_idx,
    );

    let url = `${this.#baseURL}/room/room_info?mschat_room_idx=${mschat_room_idx}`;
    if (lost_client_message_ids.length > 0) {
      url += '&lost_client_message_ids=' + lost_client_message_ids.join('^');
    }

    return tokenInstance
      .get(url, {
        headers: {token: token},
      })
      .then((result) => {
        return result.data.room_info;
      })
      .catch((error) => {
        return error.response.status;
      });
  }

  // 유저 프로필 정보 가져오기
  getUserProfile(data, token) {
    return tokenInstance
      .post(this.#baseURL + '/friend/user_info', returnFormData(data), {
        headers: {token: token},
      })
      .then((result) => {
        return result.data;
      });
  }

  // 유저 프로필 정보 가져오기
  async postLeaveRoom(data) {
    return tokenInstance
      .post(this.#baseURL + '/room/leave_room', returnFormData(data), {
        headers: {token: this.#token},
      })
      .then((result) => {
        // console.log('/room/leave_room', result.data);
        return result.data;
      });
  }

  // recvMessage
  async receiveMessage(
    receiveMessage,
    visaCheckData,
    mschat_session_idx,
    token,
    server_ik,
    socketSend,
    password,
  ) {
    let result = {};

    // 리시브 메시지가 시그널인 경우
    if (receiveMessage.model === 'S' && receiveMessage.receipt === 'PENDING') {
      let updatedMessages = [];

      // 상대방이 읽었을경우
      if (
        receiveMessage.type === 'READ_SIGNAL' &&
        receiveMessage.signal_map &&
        receiveMessage.signal_map.mschat_room_idx === visaCheckData['mschat_room_idx']
      ) {
        // 회색 방패 중에 message_send_t 가 READ_SIGNAL의 read_signal_t 보다 전인 메시지들을 꺼내온다
        // 각각 방패를 파란색으로 변경해서 저장한다
        const messages_to_read = await this.#dbInstance.get_messages_to_read(
          visaCheckData['user_idx'],
          receiveMessage.signal_map.mschat_room_idx,
          receiveMessage.signal_map.read_signal_t,
        );
        for (let i in messages_to_read) {
          const message = messages_to_read[i];
          message.message_status = 'BLUE';
          // message.message_status = 'YELLOW';
          updatedMessages.push(message);
          await this.#dbInstance.put_message(message);
        }
      }

      // 암호화 실패할경우
      else if (
        receiveMessage.type === 'DECRYPT_FAIL_SIGNAL' &&
        receiveMessage.signal_map &&
        receiveMessage.signal_map.mschat_room_idx === visaCheckData['mschat_room_idx']
      ) {
        // DECRYPT_FAIL_SIGNAL의 mschat_message_idx를 보고 해당 메시지를 꺼내온다
        // 빨간색으로 변경해서 저장한다

        let message = await this.#dbInstance.get_message_by_idx(
          visaCheckData['user_idx'],
          receiveMessage.signal_map.mschat_room_idx,
          receiveMessage.signal_map.mschat_message_idx,
        );
        if (!!message) {
          message.message_status = 'RED';
          updatedMessages.push(message);
          await this.#dbInstance.put_message(message);
        }
      } else {
        // 상태값일 경우 그냥 넘김
        return;
      }
      if (updatedMessages.length !== 0) {
        store.dispatch(chatActions.updateMessages(updatedMessages));
      }
      return;
    }

    // 모든 해쉬는 대문자로 비교한다.
    if (receiveMessage.cur_main_chain_hash)
      receiveMessage.cur_main_chain_hash = receiveMessage.cur_main_chain_hash.toUpperCase();

    // 서버 메시지 수신
    if (
      receiveMessage.type === 'SERVER_MESSAGE' &&
      receiveMessage.receipt === 'PENDING' &&
      receiveMessage.receiver_user_idx === visaCheckData['user_idx'] &&
      receiveMessage.mschat_room_idx === visaCheckData['mschat_room_idx']
    ) {
      let message_builder = this.messageProto;

      message_builder.mschat_room_idx = receiveMessage.mschat_room_idx;
      message_builder.mschat_message_idx = receiveMessage.mschat_message_idx;
      message_builder.client_message_id = crypto.randomUUID();

      // 방마다 고유한 서버 공개키로 검증한다.
      var is_signed = verify_signature(
        server_ik.public_key_hex,
        receiveMessage.signature,
        receiveMessage.message,
      );

      if (is_signed == true) {
        // 영수증 처리
        message_builder.receipt = 'SUCCESS';
        socketSend('/mschat/receipt/', JSON.stringify(message_builder));

        // DB저장용 시스템 메시지 생성
        let system_message = {
          mschat_message_idx: receiveMessage.mschat_message_idx,
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          message_type: receiveMessage.type,
          system_message: convertFromHexToUtf8(receiveMessage.message),
          message_send_t: receiveMessage.message_send_t,
        };

        // DB저장
        let message_idx = await this.#dbInstance.put_message(system_message);
        system_message.message_idx = message_idx;
        system_message.message = system_message.system_message;

        result = {
          ...system_message,
        };
        // console.log('system_message', system_message);
        return result;
      }

      // is_signed 실패
      else {
        console.log('서버 메시지 응답 실패 - Signature 검증 실패');
        message_builder.receipt = 'FAIL';
        message_builder.receipt_detail = '서버 메시지 응답 실패 - Signature 검증 실패';
        socketSend('/mschat/receipt/', JSON.stringify(message_builder));
      }
    }

    // 악수 발신 성공
    else if (
      receiveMessage.type == 'KEY_EXCHANGE' &&
      receiveMessage.receipt == 'SUCCESS' &&
      receiveMessage.sender_user_idx == visaCheckData['user_idx'] &&
      receiveMessage.mschat_room_idx === visaCheckData['mschat_room_idx']
    ) {
      // 서버에서 악수하려고 보낸 키값 db에서 꺼내기
      let db_user_key_ik = await this.#dbInstance.get_user_key(
        receiveMessage.sender_ik.id,
        receiveMessage.sender_user_idx,
        'IK',
      );
      receiveMessage.sender_ik = {
        id: db_user_key_ik.user_key_idx,
        public_key_hex: db_user_key_ik.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_ik.enc_private_key,
          visaCheckData['auth_hash'],
          db_user_key_ik.user_key_idx,
        ),
      };

      let db_user_key_ek = await this.#dbInstance.get_user_key(
        receiveMessage.sender_ek.id,
        receiveMessage.sender_user_idx,
        'EK',
      );
      receiveMessage.sender_ek = {
        id: db_user_key_ek.user_key_idx,
        public_key_hex: db_user_key_ek.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_ek.enc_private_key,
          password,
          db_user_key_ek.user_key_idx,
        ),
      };

      let db_user_key_dh_ck_1 = await this.#dbInstance.get_user_key(
        receiveMessage.sender_dh_ck_1.id,
        receiveMessage.sender_user_idx,
        'DHCK',
      );
      receiveMessage.sender_dh_ck_1 = {
        id: db_user_key_dh_ck_1.user_key_idx,
        public_key_hex: db_user_key_dh_ck_1.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_dh_ck_1.enc_private_key,
          password,
          db_user_key_dh_ck_1.user_key_idx,
        ),
      };

      let db_user_key_dh_ck_2 = await this.#dbInstance.get_user_key(
        receiveMessage.sender_dh_ck_2.id,
        receiveMessage.sender_user_idx,
        'DHCK',
      );
      receiveMessage.sender_dh_ck_2 = {
        id: db_user_key_dh_ck_2.user_key_idx,
        public_key_hex: db_user_key_dh_ck_2.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_dh_ck_2.enc_private_key,
          password,
          db_user_key_dh_ck_2.user_key_idx,
        ),
      };

      // 악수 진행
      let secret_key = await x3dh_sender(receiveMessage);
      let secret_key_hash = await generate_hash(secret_key);
      // 악수 최종 결과값
      let enc_secret_key = await encrypt_key(secret_key, password, '');

      // X3DH 결과 저장
      let key_info = {
        user_idx: receiveMessage.sender_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        main_chain_key_hash: secret_key_hash,
        is_sending: true,
        enc_main_chain_key: enc_secret_key,
        x3dh_create_t: receiveMessage.message_send_t,
      };
      await this.#dbInstance.add_x3dh_key(key_info);

      // 이 시점에 dh link 두 개 생성 가능
      // 먼저 sending_chain 생성
      let dh_send_link = await dh_ratchet(
        receiveMessage.receiver_opk.public_key_hex,
        receiveMessage.sender_dh_ck_1.private_key_hex,
        secret_key,
      );
      await this.#dbInstance.add_dh_link({
        user_idx: receiveMessage.sender_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        main_chain_key_hash: secret_key_hash,
        dh_chain_ind: 0,
        is_sending: true,
        user_key_idx: db_user_key_dh_ck_1.user_key_idx,
        partner_public_key: receiveMessage.receiver_opk.public_key_hex,
        enc_prev_link_key: enc_secret_key,
        enc_link_key: await encrypt_key(dh_send_link.link_key, password, '0'),
        enc_output_key: await encrypt_key(dh_send_link.output_key, password, '0'),
      });

      // sending_chain의 kdf_chain도 하나 미리 생성(필수는 아님)
      let kdf_send_link = await kdf_ratchet(dh_send_link.output_key);
      await this.#dbInstance.put_kdf_link({
        user_idx: receiveMessage.sender_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        main_chain_key_hash: secret_key_hash,
        dh_chain_ind: 0,
        kdf_chain_ind: 0,
        enc_prev_link_key: await encrypt_key(dh_send_link.output_key, password, '0'),
        enc_link_key: await encrypt_key(kdf_send_link.link_key, password, '0_0'),
        enc_output_key: await encrypt_key(kdf_send_link.output_key, password, '0_0'),
      });

      // 다음으로 receiving_chain 생성
      let dh_recv_link = await dh_ratchet(
        receiveMessage.receiver_opk.public_key_hex,
        receiveMessage.sender_dh_ck_2.private_key_hex,
        dh_send_link.link_key,
      );
      await this.#dbInstance.add_dh_link({
        user_idx: receiveMessage.sender_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        main_chain_key_hash: secret_key_hash,
        dh_chain_ind: 1,
        is_sending: false,
        user_key_idx: db_user_key_dh_ck_2.user_key_idx,
        partner_public_key: receiveMessage.receiver_opk.public_key_hex,
        enc_prev_link_key: await encrypt_key(dh_send_link.link_key, password, 0),
        enc_link_key: await encrypt_key(dh_recv_link.link_key, password, '1'),
        enc_output_key: await encrypt_key(dh_recv_link.output_key, password, '1'),
      });
      // receiving_chain의 kdf_chain도 하나 미리 생성(필수는 아님)
      let kdf_recv_link = await kdf_ratchet(dh_recv_link.output_key);
      await this.#dbInstance.put_kdf_link({
        user_idx: receiveMessage.sender_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        main_chain_key_hash: secret_key_hash,
        dh_chain_ind: 1,
        kdf_chain_ind: 0,
        enc_prev_link_key: await encrypt_key(dh_recv_link.output_key, password, '0'),
        enc_link_key: await encrypt_key(kdf_recv_link.link_key, password, '1_0'),
        enc_output_key: await encrypt_key(kdf_recv_link.output_key, password, '1_0'),
      });

      let system_message = {
        mschat_message_idx: receiveMessage.mschat_message_idx,
        user_idx: receiveMessage.sender_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        message_type: receiveMessage.type,
        main_chain_key_hash: key_info.main_chain_key_hash,
        system_message: '채팅방의 암호키가 생성되었습니다.',
        message_send_t: receiveMessage.message_send_t,
      };
      console.log('채팅방의 암호키가 생성되었습니다.의 타입', receiveMessage.type);
      let message_idx = await this.#dbInstance.put_message(system_message);
      system_message.message_idx = message_idx;
      system_message.message = system_message.system_message;
      return {
        type: 'KEY_EXCHANGE',
        currentX3dhKeyInfo: key_info,
        ...system_message,
      };
    }

    // 악수 수신
    else if (
      receiveMessage.type === 'KEY_EXCHANGE' &&
      receiveMessage.receipt === 'PENDING' &&
      receiveMessage.receiver_user_idx === visaCheckData['user_idx'] &&
      receiveMessage.mschat_room_idx === visaCheckData['mschat_room_idx']
    ) {
      // 서버가 고른 내키 키 값 db에서 꺼내기
      let db_user_key_ik = await this.#dbInstance.get_user_key(
        receiveMessage.receiver_ik.id,
        receiveMessage.receiver_user_idx,
        'IK',
      );
      receiveMessage.receiver_ik = {
        id: db_user_key_ik.user_key_idx,
        public_key_hex: db_user_key_ik.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_ik.enc_private_key,
          visaCheckData['auth_hash'],
          db_user_key_ik.user_key_idx,
        ),
      };
      let db_user_key_spk = await this.#dbInstance.get_user_key(
        receiveMessage.receiver_spk.id,
        receiveMessage.receiver_user_idx,
        'SPK',
      );
      receiveMessage.receiver_spk = {
        id: db_user_key_spk.user_key_idx,
        public_key_hex: db_user_key_spk.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_spk.enc_private_key,
          visaCheckData['auth_hash'],
          db_user_key_spk.user_key_idx,
        ),
      };
      let db_user_key_opk = await this.#dbInstance.get_user_key(
        receiveMessage.receiver_opk.id,
        receiveMessage.receiver_user_idx,
        'OPK',
      );
      receiveMessage.receiver_opk = {
        id: db_user_key_opk.user_key_idx,
        public_key_hex: db_user_key_opk.public_key,
        private_key_hex: await decrypt_key(
          db_user_key_opk.enc_private_key,
          visaCheckData['auth_hash'],
          db_user_key_opk.user_key_idx,
        ),
      };

      // 상대방의 IK로 서명 검증
      let is_signed = verify_signature(
        receiveMessage.sender_ik.public_key_hex,
        receiveMessage.signature,
        receiveMessage.message,
      );
      let verify_time = new Date(parseInt(convertFromHex(receiveMessage.message)));
      let message_time = new Date(receiveMessage.message_send_t);

      let message_builder = this.messageProto;
      message_builder.version = 1;
      message_builder.mschat_room_idx = receiveMessage.mschat_room_idx;
      message_builder.mschat_message_idx = receiveMessage.mschat_message_idx;
      message_builder.client_message_id = crypto.randomUUID();

      // 메시지 보낸 시간과 서명된 시간이 60초 이내일 때만 받는다. 이는 누군가 서명된 시간 값을 다시 사용하는 것을 막기 위함이다.
      if (is_signed === true && message_time - verify_time < 60 * 1000) {
        const secret_key = await x3dh_receiver(receiveMessage);
        const secret_key_hash = await generate_hash(secret_key);
        const enc_secret_key = await encrypt_key(secret_key, password, '');

        // 이미 x3dh 결과 중에 main_chain_cur과 동일한게 존재한다면 중복 메시지라 무시
        let x3dh_key_info = await this.#dbInstance.get_x3dh_key(
          receiveMessage.receiver_user_idx,
          receiveMessage.mschat_room_idx,
          secret_key_hash,
        );
        if (x3dh_key_info) {
          return;
        }

        // X3DH 결과 저장
        let key_info = {
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          main_chain_key_hash: secret_key_hash,
          is_sender: false,
          enc_main_chain_key: enc_secret_key,
          x3dh_create_t: receiveMessage.message_send_t,
        };
        await this.#dbInstance.add_x3dh_key(key_info);

        // version^mschat_room_idx^user_idx^qr_hash
        let deep_link =
          'https://share.medistaff.co.kr/share?link_action=qr&link_idx=' +
          convertToHex(
            '1' +
              '^' +
              receiveMessage.mschat_room_idx +
              '^' +
              receiveMessage.receiver_user_idx +
              '^',
          ) +
          secret_key_hash;
        create_qr(encodeURIComponent(deep_link), 'MEDISTAFF');

        // 이 시점에 dh_chain 두 개 생성 가능
        // 먼저 sending_chain 생성
        let dh_recv_link = await dh_ratchet(
          receiveMessage.sender_dh_ck_1.public_key_hex,
          receiveMessage.receiver_opk.private_key_hex,
          secret_key,
        );
        await this.#dbInstance.add_dh_link({
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          main_chain_key_hash: secret_key_hash,
          dh_chain_ind: 0,
          is_sending: false,
          user_key_idx: db_user_key_opk.user_key_idx,
          partner_public_key: receiveMessage.sender_dh_ck_1.public_key_hex,
          enc_prev_link_key: enc_secret_key,
          enc_link_key: await encrypt_key(dh_recv_link.link_key, password, '0'),
          enc_output_key: await encrypt_key(dh_recv_link.output_key, password, '0'),
        });
        // sending_chain의 kdf_chain도 하나 미리 생성(필수는 아님)
        let kdf_recv_link = await kdf_ratchet(dh_recv_link.output_key);
        await this.#dbInstance.put_kdf_link({
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          main_chain_key_hash: secret_key_hash,
          dh_chain_ind: 0,
          kdf_chain_ind: 0,
          enc_prev_link_key: await encrypt_key(dh_recv_link.output_key, password, '0'),
          enc_link_key: await encrypt_key(kdf_recv_link.link_key, password, '0_0'),
          enc_output_key: await encrypt_key(kdf_recv_link.output_key, password, '0_0'),
        });

        // 다음으로 receiving_chain 생성
        let dh_send_link = await dh_ratchet(
          receiveMessage.sender_dh_ck_2.public_key_hex,
          receiveMessage.receiver_opk.private_key_hex,
          dh_recv_link.link_key,
        );
        await this.#dbInstance.add_dh_link({
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          main_chain_key_hash: secret_key_hash,
          dh_chain_ind: 1,
          is_sending: true,
          user_key_idx: db_user_key_opk.user_key_idx,
          partner_public_key: receiveMessage.sender_dh_ck_2.public_key_hex,
          enc_prev_link_key: await encrypt_key(dh_recv_link.link_key, password, 0),
          enc_link_key: await encrypt_key(dh_send_link.link_key, password, '1'),
          enc_output_key: await encrypt_key(dh_send_link.output_key, password, '1'),
        });
        // receiving_chain의 kdf_chain도 하나 미리 생성(필수는 아님)
        let kdf_send_link = await kdf_ratchet(dh_send_link.output_key);
        await this.#dbInstance.put_kdf_link({
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          main_chain_key_hash: secret_key_hash,
          dh_chain_ind: 1,
          kdf_chain_ind: 0,
          enc_prev_link_key: await encrypt_key(dh_send_link.output_key, password, '0'),
          enc_link_key: await encrypt_key(kdf_send_link.link_key, password, '1_0'),
          enc_output_key: await encrypt_key(kdf_send_link.output_key, password, '1_0'),
        });
        message_builder.receipt = 'SUCCESS';
        socketSend('/mschat/receipt/', JSON.stringify(message_builder));
        let system_message = {
          mschat_message_idx: receiveMessage.mschat_message_idx,
          user_idx: receiveMessage.receiver_user_idx,
          mschat_room_idx: receiveMessage.mschat_room_idx,
          message_type: receiveMessage.type,
          main_chain_key_hash: key_info.main_chain_key_hash,
          system_message: '채팅방의 암호키가 생성되었습니다.',
          message_send_t: receiveMessage.message_send_t,
        };
        let message_idx = await this.#dbInstance.put_message(system_message);
        system_message.message_idx = message_idx;
        system_message.message = system_message.system_message;
        return {
          type: 'KEY_EXCHANGE',
          currentX3dhKeyInfo: key_info,
          ...system_message,
        };
      } else {
        let message_builder = this.messageProto;
        message_builder.receipt = 'FAIL';
        message_builder.receipt_detail = 'X3DH 악수 응답 실패 - Signature 시간 검증 실패';
        socketSend('/mschat/receipt/' + mschat_session_idx, JSON.stringify(message_builder));
      }
    }

    // 메시지 발신 성공
    else if (
      (receiveMessage.type === 'MESSAGE' || receiveMessage.type === 'FILE') &&
      receiveMessage.receipt === 'SUCCESS' &&
      receiveMessage.sender_user_idx === visaCheckData['user_idx'] &&
      receiveMessage.mschat_room_idx === visaCheckData['mschat_room_idx']
    ) {
      // 보낸 메세지가 성공했으니 서버가 제공한 id를 주입하고 시간 정보를 입력함
      let message = await this.#dbInstance.get_message_by_index(
        receiveMessage.sender_user_idx,
        receiveMessage.mschat_room_idx,
        receiveMessage.cur_main_chain_hash,
        receiveMessage.dh_chain_ind,
        receiveMessage.kdf_chain_ind,
      );
      message.mschat_message_idx = receiveMessage.mschat_message_idx;

      message.client_message_id = receiveMessage.client_message_id;
      // 이 값을 통해 optimistic UI를 변경해줄수 있음

      message.message_send_t = receiveMessage.message_send_t;
      if (message.message_status === 'PLANE') {
        message.message_status = 'GRAY';
        // message.message_status = 'YELLOW';
      }
      await this.#dbInstance.put_message(message);

      // 약간 낭비이긴 하나 암호화해서 보낸 메시지를 다시 복호화한다.
      let kdf_link = await this.#dbInstance.get_kdf_link(
        receiveMessage.sender_user_idx,
        receiveMessage.mschat_room_idx,
        receiveMessage.cur_main_chain_hash,
        receiveMessage.dh_chain_ind,
        receiveMessage.kdf_chain_ind,
      );
      let dec_message = await decrypt_message(
        message.encrypted_message,
        await decrypt_key(
          kdf_link.enc_output_key,
          password,
          message.dh_chain_ind + '_' + message.kdf_chain_ind,
        ),
        message.dh_chain_ind,
        message.kdf_chain_ind,
        message.tag,
        message.nonce,
      );

      message.message = dec_message;
      message.is_sending = true;
      return {...message};
    }

    // 메시지 수신
    else if (
      (receiveMessage.type === 'MESSAGE' || receiveMessage.type === 'FILE') &&
      receiveMessage.receipt === 'PENDING' &&
      receiveMessage.receiver_user_idx === visaCheckData['user_idx'] &&
      receiveMessage.mschat_room_idx === visaCheckData['mschat_room_idx']
    ) {
      // kdf가 이미 있고 복호화 메시지가 들어있다면 이미 처리한 메시지라고 취급하고 아무런 행동을 취하지 않음
      let alreadyProcessedMessage = await this.#dbInstance.get_message_by_index(
        receiveMessage.receiver_user_idx,
        receiveMessage.mschat_room_idx,
        receiveMessage.cur_main_chain_hash,
        receiveMessage.dh_chain_ind,
        receiveMessage.kdf_chain_ind,
      );
      let message_builder = this.messageProto;
      message_builder.version = 1;
      message_builder.mschat_room_idx = receiveMessage.mschat_room_idx;
      message_builder.mschat_message_idx = receiveMessage.mschat_message_idx;
      message_builder.client_message_id = crypto.randomUUID();
      if (alreadyProcessedMessage) {
        message_builder.receipt = 'SUCCESS';
        socketSend('/mschat/receipt/', JSON.stringify(message_builder));
        return;
      }

      const message = {
        mschat_message_idx: receiveMessage.mschat_message_idx,
        user_idx: receiveMessage.receiver_user_idx,
        mschat_room_idx: receiveMessage.mschat_room_idx,
        message_type: receiveMessage.type,
        main_chain_key_hash: receiveMessage.cur_main_chain_hash,
        dh_chain_ind: receiveMessage.dh_chain_ind,
        kdf_chain_ind: receiveMessage.kdf_chain_ind,
        encrypted_message: receiveMessage.message,
        file_path: receiveMessage.file_path,
        file_type: receiveMessage.file_type,
        tag: receiveMessage.tag,
        nonce: receiveMessage.nonce,
        message_send_t: receiveMessage.message_send_t,
        is_sending: false,
      };

      // 이미 굴려놓은 KDF가 있는지 확인해본다.
      let kdf_link = await this.#dbInstance.get_kdf_link(
        receiveMessage.receiver_user_idx,
        receiveMessage.mschat_room_idx,
        receiveMessage.cur_main_chain_hash,
        receiveMessage.dh_chain_ind,
        receiveMessage.kdf_chain_ind,
      );
      if (!kdf_link) {
        // 현재 방에 받을 수 있는 main_chain이 있는지 확인
        let cur_x3dh_key_info = await this.#dbInstance.get_x3dh_key(
          receiveMessage.receiver_user_idx,
          receiveMessage.mschat_room_idx,
          receiveMessage.cur_main_chain_hash,
        );
        // 빨간색 UI
        if (!cur_x3dh_key_info) {
          console.error('메시지 복호화를 위한 Root키가 마련되어 있지 않습니다.');
          message_builder.receipt = 'FAIL';
          message_builder.receipt_detail = '메시지 복호화를 위한 Root키가 마련되어 있지 않습니다.';
          socketSend('/mschat/receipt/', JSON.stringify(message_builder));
          message.message_status = 'RED';
          await this.#dbInstance.put_message(message);
          return {...message};
        }
        // 서명 검증
        if (
          !verify_signature(
            receiveMessage.sender_ik.public_key_hex,
            receiveMessage.signature,
            receiveMessage.message,
          )
        ) {
          // TODO: 사실상 노란색 방패인데 일단은 빨간색으로 표시
          console.error('메시지의 서명이 올바르지 않습니다.');
          message_builder.receipt = 'FAIL';
          message_builder.receipt_detail = '메시지의 서명이 올바르지 않습니다.';
          socketSend('/mschat/receipt/', JSON.stringify(message_builder));
          message.message_status = 'RED';
          await this.#dbInstance.put_message(message);
          return {...message};
        }
        // 먼저 dh_link 연산
        // 만약 dh_chain_ind가 이미 연산되어 있으면 그대로 사용
        let dh_link = await this.#dbInstance.get_dh_link(
          receiveMessage.receiver_user_idx,
          receiveMessage.mschat_room_idx,
          receiveMessage.cur_main_chain_hash,
          receiveMessage.dh_chain_ind,
        );
        // 없을 경우 새로 굴려야 하는데, 마지막에서 바로 다음 것만 굴릴 수 있음
        if (!dh_link) {
          let last_dh_link = await this.#dbInstance.get_last_dh_link(
            receiveMessage.receiver_user_idx,
            receiveMessage.mschat_room_idx,
            receiveMessage.cur_main_chain_hash,
          );
          if (!last_dh_link.is_sending) {
            // 마지막 dh_link가 받는용이면 그대로 사용
            dh_link = last_dh_link;
          } else {
            // 아니면 새로 하나 굴림
            // 빨간색
            if (last_dh_link.dh_chain_ind + 1 !== receiveMessage.dh_chain_ind) {
              console.error(
                'DH는 한 단계씩만 진행할 수 있는데, 현재 연결하려고 하는 link는 바로 다음 link가 될 수 없습니다.',
              );

              message_builder.receipt = 'FAIL';
              message_builder.receipt_detail =
                'DH는 한 단계씩만 진행할 수 있는데, 현재 연결하려고 하는 link는 바로 다음 link가 될 수 없습니다.';
              socketSend('/mschat/receipt/', JSON.stringify(message_builder));
              message.message_status = 'RED';
              await this.#dbInstance.put_message(message);
              return {...message};
            }
            let db_user_key_dh_ck = await this.#dbInstance.get_user_key_by_idx(
              last_dh_link.user_key_idx,
              receiveMessage.receiver_user_idx,
            );
            if (!db_user_key_dh_ck) {
              // 빨간색
              console.error('이전 DH link에서 사용한 키값을 찾지 못했습니다.');
              message_builder.receipt = 'FAIL';
              message_builder.receipt_detail = '이전 DH link에서 사용한 키값을 찾지 못했습니다.';
              socketSend('/mschat/receipt/', JSON.stringify(message_builder));
              return;
            }
            let temp_password = password;
            if (db_user_key_dh_ck.key_type === 'OPK') {
              temp_password = visaCheckData['auth_hash'];
            }
            let dh_ratchet_output = await dh_ratchet(
              receiveMessage.dh_cur_key.public_key_hex,
              await decrypt_key(
                db_user_key_dh_ck.enc_private_key,
                temp_password,
                db_user_key_dh_ck.user_key_idx,
              ),
              await decrypt_key(last_dh_link.enc_link_key, password, last_dh_link.dh_chain_ind),
            );
            // let dh_ratchet_output = await dh_ratchet(
            //   receiveMessage.dh_cur_key.public_key_hex,
            //   await decrypt_key(
            //     db_user_key_dh_ck.enc_private_key,
            //     password,
            //     db_user_key_dh_ck.user_key_idx,
            //   ),
            //   await decrypt_key(last_dh_link.enc_link_key, password, last_dh_link.dh_chain_ind),
            // );

            let dh_link_idx = await this.#dbInstance.add_dh_link({
              user_idx: receiveMessage.receiver_user_idx,
              mschat_room_idx: receiveMessage.mschat_room_idx,
              main_chain_key_hash: receiveMessage.cur_main_chain_hash,
              dh_chain_ind: receiveMessage.dh_chain_ind,
              is_sending: false,
              user_key_idx: db_user_key_dh_ck.user_key_idx,
              partner_public_key: receiveMessage.dh_cur_key.public_key_hex,
              enc_prev_link_key: last_dh_link.enc_link_key,
              enc_link_key: await encrypt_key(
                dh_ratchet_output.link_key,
                password,
                receiveMessage.dh_chain_ind,
              ),
              enc_output_key: await encrypt_key(
                dh_ratchet_output.output_key,
                password,
                receiveMessage.dh_chain_ind,
              ),
            });
            dh_link = await this.#dbInstance.get_dh_link_by_idx(dh_link_idx);
          }
        }
        let last_kdf_link = await this.#dbInstance.get_last_kdf_link(
          receiveMessage.receiver_user_idx,
          receiveMessage.mschat_room_idx,
          receiveMessage.cur_main_chain_hash,
          receiveMessage.dh_chain_ind,
        );
        // 마지막 kdf link가 없다는 얘기는 dh_link의 kdf_chain이 비어있다는 말이라 초기 한 번을 굴려준다.
        if (!last_kdf_link) {
          let dh_output_key = await decrypt_key(
            dh_link.enc_output_key,
            password,
            receiveMessage.dh_chain_ind,
          );
          let kdf_chain_ind = 0;
          let kdf_ratchet_output = await kdf_ratchet(dh_output_key);
          let kdf_link_idx = await this.#dbInstance.put_kdf_link({
            user_idx: receiveMessage.receiver_user_idx,
            mschat_room_idx: receiveMessage.mschat_room_idx,
            main_chain_key_hash: receiveMessage.cur_main_chain_hash,
            dh_chain_ind: receiveMessage.dh_chain_ind,
            kdf_chain_ind: kdf_chain_ind,
            enc_prev_link_key: dh_link.enc_output_key,
            enc_link_key: await encrypt_key(
              kdf_ratchet_output.link_key,
              password,
              receiveMessage.dh_chain_ind + '_' + kdf_chain_ind,
            ),
            enc_output_key: await encrypt_key(
              kdf_ratchet_output.output_key,
              password,
              receiveMessage.dh_chain_ind + '_' + kdf_chain_ind,
            ),
          });
          last_kdf_link = await this.#dbInstance.get_kdf_link_by_idx(kdf_link_idx);
        }
        // kdf_chain이 현재 메시지를 받은 kdf_chain_ind까지 확보하기 위해 굴린다.
        while (last_kdf_link.kdf_chain_ind < receiveMessage.kdf_chain_ind) {
          let last_kdf_link_key = await decrypt_key(
            last_kdf_link.enc_link_key,
            password,
            receiveMessage.dh_chain_ind + '_' + last_kdf_link.kdf_chain_ind,
          );
          let kdf_chain_ind = last_kdf_link.kdf_chain_ind + 1;
          let kdf_ratchet_output = await kdf_ratchet(last_kdf_link_key);
          const kdf_link_idx = await this.#dbInstance.put_kdf_link({
            user_idx: receiveMessage.receiver_user_idx,
            mschat_room_idx: receiveMessage.mschat_room_idx,
            main_chain_key_hash: receiveMessage.cur_main_chain_hash,
            dh_chain_ind: receiveMessage.dh_chain_ind,
            kdf_chain_ind: kdf_chain_ind,
            enc_prev_link_key: last_kdf_link.enc_link_key,
            enc_link_key: await encrypt_key(
              kdf_ratchet_output.link_key,
              password,
              receiveMessage.dh_chain_ind + '_' + kdf_chain_ind,
            ),
            enc_output_key: await encrypt_key(
              kdf_ratchet_output.output_key,
              password,
              receiveMessage.dh_chain_ind + '_' + kdf_chain_ind,
            ),
          });
          last_kdf_link = await this.#dbInstance.get_kdf_link_by_idx(kdf_link_idx);
        }
        kdf_link = last_kdf_link;
      }

      // 메시지 복호화
      let dec_message = await decrypt_message(
        receiveMessage.message,
        await decrypt_key(
          kdf_link.enc_output_key,
          password,
          receiveMessage.dh_chain_ind + '_' + receiveMessage.kdf_chain_ind,
        ),
        receiveMessage.dh_chain_ind,
        receiveMessage.kdf_chain_ind,
        receiveMessage.tag,
        receiveMessage.nonce,
      );

      if (!dec_message) {
        store.dispatch(chatActions.encryptionKeyFail());
        console.error('산출한 암호키로 복호화에 실패했습니다.');
        message.message_status = 'RED';
        await this.#dbInstance.put_message(message);

        message_builder.receipt = 'FAIL';
        message_builder.receipt_detail = '산출한 암호키로 복호화에 실패했습니다.';
        socketSend('/mschat/receipt/', JSON.stringify(message_builder));
        return {...message};
      }

      message.message_status = 'BLUE';
      let message_idx = await this.#dbInstance.put_message(message);
      message.message_idx = message_idx;
      message.message = dec_message;
      message_builder.receipt = 'SUCCESS';
      socketSend('/mschat/receipt/', JSON.stringify(message_builder));
      return {...message};
    } else if (receiveMessage.receipt === 'FAIL') {
      console.log('메시지 수신 실패 : ' + receiveMessage.receipt_detail);
      return 'fail';
    }
  }

  getUnreadMessageInServer(url, token) {
    return tokenInstance.get(url, {headers: {token}}).then((res) => res.data);
  }

  // 읽지 않은 메시지 가져오기
  async processUnreadMessages(
    visaCheckData,
    password,
    mschatSessionIdx,
    token,
    serverIk,
    socketSend,
  ) {
    try {
      store.dispatch(chatActions.setUnReadMessageShow(true));
      let unreadMessageCount = 0;
      let readMessageCount = 0;
      let initialUnreadMessageCount = 0;
      let justOne = false;
      const url =
        this.#baseURL +
        `/room/get_unread_messages?mschat_room_idx=${visaCheckData['mschat_room_idx']}`;

      // 30개씩 반복
      do {
        await this.getUnreadMessageInServer(url, token).then(async (result) => {
          if (result.Code !== 200) {
            console.error('안 읽은 메시지 처리 중에 오류가 발생했습니다.' + result.Msg);
            return;
          }

          if (initialUnreadMessageCount === 0) {
            initialUnreadMessageCount = result.unread_messages_count;
            if (initialUnreadMessageCount !== 0 && justOne === false) {
              // 맨 처음 로딩시 채팅방이 생성되었습니다.또한 unreadMessage.의 결과값안에 들어있음....
              if (
                !(
                  initialUnreadMessageCount === 1 &&
                  result.unread_messages[0].type === 'SERVER_MESSAGE'
                )
              ) {
                let firstUnreadMessageTime = await this.#dbInstance.get_last_read_t(
                  visaCheckData['user_idx'],
                  visaCheckData['mschat_room_idx'],
                );
                if (firstUnreadMessageTime !== null) {
                  let firstUnreadMessageTimeFormat = new Date(firstUnreadMessageTime);
                  let oneSecondBefore = new Date(firstUnreadMessageTimeFormat.getTime() + 1); // 0.001초 후
                  // 이전의 icon_type: 'un_read_message'은 삭제
                  store.dispatch(chatActions.deleteOldUnReadMessageChats());
                  store.dispatch(
                    chatActions.addChatByTime({
                      message_type: 'SERVER_MESSAGE',
                      icon_type: 'un_read_message',
                      message: '여기까지 읽으셨습니다.',
                      message_send_t: oneSecondBefore,
                      message_idx: oneSecondBefore,
                      mschat_message_idx: visaCheckData['user_contact_number'] + '_un_read_message',
                    }),
                  );
                }
              }
            }
          }
          justOne = true;
          unreadMessageCount = result.unread_messages_count;
          if (unreadMessageCount === 0) {
            store.dispatch(chatActions.setUnReadMessageShow(false));
          }

          // 비동기로 메시지 하나당 수신 처리
          for (let i in result.unread_messages) {
            let receiveMessageResult = await this.receiveMessage(
              result.unread_messages[i],
              visaCheckData,
              mschatSessionIdx,
              token,
              serverIk,
              socketSend,
              password,
            );

            if (receiveMessageResult !== undefined) {
              receiveMessageResult.purpose = 'unreadMessage';
              // 이중에서 서버 메시지중 암호화 키 변경이 있을 경우 기록
              if (receiveMessageResult.message_type === 'KEY_EXCHANGE') {
                store.dispatch(
                  chatActions.setCurrentX3dhKeyInfo(receiveMessageResult.currentX3dhKeyInfo),
                );
              }
              store.dispatch(chatActions.addChatByTime(receiveMessageResult));
            }
            readMessageCount++;
          }
          // console.log('initialUnreadMessageCount', initialUnreadMessageCount);
          // console.log('unreadMessageCount', unreadMessageCount);
          // console.log('readMessageCount', readMessageCount);
          // 퍼센트 계산
          const progressBarPercent =
            initialUnreadMessageCount > 0
              ? Math.min((readMessageCount / initialUnreadMessageCount) * 100, 100)
              : 0;
          store.dispatch(chatActions.setUnReadMessagePercent(Math.ceil(progressBarPercent)));
        });
      } while (unreadMessageCount > 0);
    } catch (error) {
      console.error('<process_unread_messages> 실패', error);
    } finally {
      await this.semaphore.unlock();
    }
  }

  async PreProcessing(
    visaCheckData,
    roomInfo,
    password,
    mschatSessionIdx,
    token,
    serverIk,
    socketSend,
  ) {
    // 읽지 않은 메시지 가져오기
    await this.processUnreadMessages(
      visaCheckData,
      password,
      mschatSessionIdx,
      token,
      serverIk,
      socketSend,
    );
    let updatedMessages = [];

    // 내가 보낸 메시지의 상대방 읽음 처리
    // (멤버의 마지막 접속 시간보다 내가 보낸 메시지가 더 이전 시간이라면 읽음 완료)
    if (roomInfo.member_last_read_times.length !== 0) {
      const messages_to_read = await this.#dbInstance.get_messages_to_read(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
        roomInfo?.member_last_read_times[0],
      );
      for (let i in messages_to_read) {
        const message = messages_to_read[i];
        message.message_status = 'BLUE';
        updatedMessages.push(message);
        await this.#dbInstance.put_message(message);
      }
    }

    // room_info의 lost_messages 처리를 한다
    let lost_messages = roomInfo?.lost_messages;
    if (!!lost_messages) {
      for (let i in lost_messages) {
        const message = await this.#dbInstance.get_message_by_cmi(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          lost_messages[i]?.client_message_id,
        );
        message.mschat_message_idx = lost_messages[i]?.mschat_message_idx;
        message.message_send_t = lost_messages[i]?.message_send_t;
        if (
          roomInfo?.member_last_read_times &&
          lost_messages[i].message_send_t < roomInfo?.member_last_read_times[0]
        ) {
          message.message_status = 'BLUE';
        } else {
          message.message_status = 'GRAY';
        }
        updatedMessages.push(message);
        await this.#dbInstance.put_message(message);
      }
    }
    // 서버에서 매칭되지 못한 lost messages는 실패처리를 한다
    const lost_client_message_ids = await this.#dbInstance.get_lost_messages(
      visaCheckData['user_idx'],
      visaCheckData['mschat_room_idx'],
    );
    if (!!lost_client_message_ids) {
      for (let i in lost_client_message_ids) {
        const message = await this.#dbInstance.get_message_by_cmi(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          lost_client_message_ids[i]?.client_message_id,
        );
        message.message_status = 'YELLOW';
        updatedMessages.push(message);
        await this.#dbInstance.put_message(message);
      }
    }

    // 내가 보낸 메시지의 상대방 복호화 실패 처리
    if (roomInfo.decrypt_fail_messages) {
      for (let i in roomInfo.decrypt_fail_messages) {
        let fail_message = roomInfo.decrypt_fail_messages[i];
        let message = await this.#dbInstance.get_message_by_idx(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          fail_message.M,
        );
        if (message && message.message_status !== 'RED') {
          message.message_status = 'RED';
          updatedMessages.push(message);
          await this.#dbInstance.put_message(message);
        }
      }
    }
    return updatedMessages;
  }
  async checkHandShack(visaCheckData, socketSend, requestSessionData) {
    let cur_x3dh_key_info = await this.#dbInstance.get_last_x3dh_key(
      visaCheckData['user_idx'],
      visaCheckData['mschat_room_idx'],
    );

    // [채팅방의 암호키가 생성되었습니다.]
    //  ****** 2. 악수를 진행한 후에 서버에서 암호화키 생성한 후에 메시지를 보낼 수 있음
    if (!cur_x3dh_key_info) {
      console.log('악수를 진행합니다.');
      await this.handshake(visaCheckData, socketSend, requestSessionData);
      return false;
    }
    return cur_x3dh_key_info;
  }
  async sendMessage(visaCheckData, socketSend, requestSessionData, item) {
    const self = this;
    let cur_x3dh_key_info = await this.checkHandShack(
      visaCheckData,
      socketSend,
      requestSessionData,
    );

    //  ****** 3. optimisticUI를 위한 작업
    let client_message_id = crypto.randomUUID();
    let message_type;
    let currentMessage = item.message;
    if (item.message_type === 'FILE') {
      message_type = 'FILE';
      // currentMessage = await handleFileMessage(item);
      // currentMessage = item;
    } else {
      message_type = 'MESSAGE';
      if (isEmptyText(currentMessage)) {
        return;
      }
    }

    // DH와 KDF를 필요에 의해 새로 굴리거나 이미 굴린것을 꺼낸다.
    let dh_link = await getDhLink(cur_x3dh_key_info);
    const {kdf_link, message_key} = await getKdfLink(dh_link);

    // KDF Link의 결과값으로 암호화
    let enc_message = await encrypt_message(
      currentMessage,
      message_key,
      dh_link.dh_chain_ind,
      kdf_link.kdf_chain_ind,
    );
    enc_message.user_idx = visaCheckData['user_idx'];
    enc_message.mschat_room_idx = visaCheckData['mschat_room_idx'];
    enc_message.main_chain_key_hash = cur_x3dh_key_info.main_chain_key_hash;
    enc_message.dh_chain_ind = dh_link.dh_chain_ind;
    enc_message.kdf_chain_ind = kdf_link.kdf_chain_ind;
    enc_message.message_type = message_type;
    enc_message.message_status = 'PLANE';

    // 파일 메시지일 경우 특수 파라미터 입력
    if (message_type === 'FILE') {
      enc_message.file_type = item.file_type;
      enc_message.file_path = item.file_path;

      // dbInstance에 메시지 넣기
      const message_idx = await this.#dbInstance.put_message(enc_message);
      store.dispatch(
        chatActions.sendMessage({
          client_message_id: client_message_id,
          message_type: 'FILE',
          message_status: 'PLANE',
          message_send_t: new Date(),
          is_sending: true,
          message: currentMessage,
          file_type: item.file_type,
          file_path: item.file_path,
          message_idx: message_idx,
        }),
      );
    } else {
      // dbInstance에 메시지 넣기
      const message_idx = await this.#dbInstance.put_message(enc_message);
      store.dispatch(
        chatActions.sendMessage({
          client_message_id: client_message_id,
          message: currentMessage,
          message_status: 'PLANE',
          message_send_t: new Date(),
          is_sending: true,
          message_idx: message_idx,
        }),
      );
    }

    // 모든 메시지는 내 IK로 서명을 해야한다.
    let dbInstance_user_key_ik = await this.#dbInstance.get_user_keys(
      visaCheckData['user_idx'],
      'IK',
    );
    let signature = calculate_signature(
      await decrypt_key(
        dbInstance_user_key_ik.enc_private_key,
        visaCheckData['auth_hash'],
        dbInstance_user_key_ik.user_key_idx,
      ),
      enc_message.encrypted_message,
    );

    let user_key_info = await this.#dbInstance.get_user_key_by_idx(
      dh_link.user_key_idx,
      visaCheckData['user_idx'],
    );

    const messageBuilder = buildMessageEnvelope(enc_message, dh_link, user_key_info, signature);
    // 웹소캣으로 메시지 보내기
    socketSend('/mschat/message/', JSON.stringify(messageBuilder));

    await asyncDelay(500);

    // TODO: 스크롤 아래로 내리기
    // goScrollBottom();
    // =======================================================================
    async function getDhLink(cur_x3dh_key_info) {
      // 먼저 dh_link 연산
      let last_dh_link = await self.#dbInstance.get_last_dh_link(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
        cur_x3dh_key_info.main_chain_key_hash,
      );
      // 마지막 dh가 발신용이 아니라면 내가 굴릴 차례라는 뜻이다.
      if (!last_dh_link.is_sending) {
        // 위에 예외가 하나 있는데 악수하고 첫번쨰로 보내는 메시지일 경우다. 아직 상대방이 첫 메시지를 보내기 전이라면 악수하면서 만들어놓은 발신용을 사용해야 한다.
        // 그래서 마지막 수신용 DH에 메시지 들어 있는지 확인한다.
        let last_kdf_link = await self.#dbInstance.get_last_kdf_link(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          cur_x3dh_key_info.main_chain_key_hash,
          last_dh_link.dh_chain_ind,
        );
        let last_message = await self.#dbInstance.get_message_by_index(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          cur_x3dh_key_info.main_chain_key_hash,
          last_dh_link.dh_chain_ind,
          last_kdf_link.kdf_chain_ind,
        );
        // 마지막 dh_link가 받는용인데 kdf_link에 메시지가 없다면 예전에 만든 dh_link를 재활용한다
        if (!last_message) {
          return await self.#dbInstance.get_dh_link(
            visaCheckData['user_idx'],
            visaCheckData['mschat_room_idx'],
            cur_x3dh_key_info.main_chain_key_hash,
            last_dh_link.dh_chain_ind - 1,
          );
          // 마지막 dh_link가 받는용이고 kdf_link가 사용된 적이 있으면 새로운 dh_link를 생성한다
        } else {
          // 일회성 키를 생성하고 dbInstance에 넣는다.
          let dh_ck_key_idx = await Api.insertNextKey(
            visaCheckData['user_idx'],
            self.userFromRedux.password,
            'DHCK',
          );
          let dbInstance_user_key_dh_ck = await self.#dbInstance.get_user_key(
            dh_ck_key_idx,
            visaCheckData['user_idx'],
            'DHCK',
          );
          // 상대방의 마지막 공개키와 DH 연산을 한다.
          let dh_ratchet_output = await dh_ratchet(
            last_dh_link.partner_public_key,
            await decrypt_key(
              dbInstance_user_key_dh_ck.enc_private_key,
              self.userFromRedux.password,
              dbInstance_user_key_dh_ck.user_key_idx,
            ),
            await decrypt_key(
              last_dh_link.enc_link_key,
              self.userFromRedux.password,
              last_dh_link.dh_chain_ind,
            ),
          );
          // DH Link를 dbInstance에 넣는다.
          let dh_link_idx = await self.#dbInstance.add_dh_link({
            user_idx: visaCheckData['user_idx'],
            mschat_room_idx: visaCheckData['mschat_room_idx'],
            main_chain_key_hash: cur_x3dh_key_info.main_chain_key_hash,
            dh_chain_ind: last_dh_link.dh_chain_ind + 1,
            is_sending: true,
            user_key_idx: dbInstance_user_key_dh_ck.user_key_idx,
            partner_public_key: last_dh_link.partner_public_key,
            enc_prev_link_key: last_dh_link.enc_link_key,
            enc_link_key: await encrypt_key(
              dh_ratchet_output.link_key,
              self.userFromRedux.password,
              last_dh_link.dh_chain_ind + 1,
            ),
            enc_output_key: await encrypt_key(
              dh_ratchet_output.output_key,
              self.userFromRedux.password,
              last_dh_link.dh_chain_ind + 1,
            ),
          });
          return await self.#dbInstance.get_dh_link_by_idx(dh_link_idx);
        }
      } else {
        return last_dh_link;
      }
    }
    async function getKdfLink(dh_link) {
      let message_key;
      let kdf_link;
      let last_kdf_link = await self.#dbInstance.get_last_kdf_link(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
        cur_x3dh_key_info.main_chain_key_hash,
        dh_link.dh_chain_ind,
      );
      if (last_kdf_link) {
        // 마지막 KDF링크가 사용이 안되었다면 (미리 굴려놓기만 했을 수 있음) 그대로 사용
        let last_message = await self.#dbInstance.get_message_by_index(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          cur_x3dh_key_info.main_chain_key_hash,
          dh_link.dh_chain_ind,
          last_kdf_link.kdf_chain_ind,
        );
        if (!last_message) {
          // 혹시 미리 kdf를 돌려놓고 암호화에 안쓰인 링크가 있다면 사용
          kdf_link = last_kdf_link;
          message_key = await decrypt_key(
            kdf_link.enc_output_key,
            self.userFromRedux.password,
            dh_link.dh_chain_ind + '_' + kdf_link.kdf_chain_ind,
          );
        }
      }
      if (!message_key) {
        // 아니면 kdf 한바퀴
        let kdf_link_idx;
        if (last_kdf_link) {
          let last_kdf_link_key = await decrypt_key(
            last_kdf_link.enc_link_key,
            self.userFromRedux.password,
            dh_link.dh_chain_ind + '_' + last_kdf_link.kdf_chain_ind,
          );
          let kdf_chain_ind = last_kdf_link.kdf_chain_ind + 1;
          let kdf_ratchet_output = await kdf_ratchet(last_kdf_link_key);
          message_key = kdf_ratchet_output.output_key;
          kdf_link_idx = await self.#dbInstance.put_kdf_link({
            user_idx: visaCheckData['user_idx'],
            mschat_room_idx: visaCheckData['mschat_room_idx'],
            main_chain_key_hash: cur_x3dh_key_info.main_chain_key_hash,
            dh_chain_ind: dh_link.dh_chain_ind,
            kdf_chain_ind: kdf_chain_ind,
            enc_prev_link_key: last_kdf_link.enc_link_key,
            enc_link_key: await encrypt_key(
              kdf_ratchet_output.link_key,
              self.userFromRedux.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
            enc_output_key: await encrypt_key(
              kdf_ratchet_output.output_key,
              self.userFromRedux.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
          });
        } else {
          let last_kdf_link_key = await decrypt_key(
            dh_link.enc_output_key,
            self.userFromRedux.password,
            dh_link.dh_chain_ind,
          );
          let kdf_chain_ind = 0;
          let kdf_ratchet_output = await kdf_ratchet(last_kdf_link_key);
          message_key = kdf_ratchet_output.output_key;
          kdf_link_idx = await self.#dbInstance.put_kdf_link({
            user_idx: visaCheckData['user_idx'],
            mschat_room_idx: visaCheckData['mschat_room_idx'],
            main_chain_key_hash: cur_x3dh_key_info.main_chain_key_hash,
            dh_chain_ind: dh_link.dh_chain_ind,
            kdf_chain_ind: kdf_chain_ind,
            enc_prev_link_key: dh_link.enc_output_key,
            enc_link_key: await encrypt_key(
              kdf_ratchet_output.link_key,
              self.userFromRedux.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
            enc_output_key: await encrypt_key(
              kdf_ratchet_output.output_key,
              self.userFromRedux.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
          });
        }
        kdf_link = await self.#dbInstance.get_kdf_link_by_idx(kdf_link_idx);
      }
      return {kdf_link, message_key};
    }
    // 메시지 봉투 생성
    function buildMessageEnvelope(encMessage, dhLink, userKeyInfo, signature) {
      let message_builder = self.messageProto;
      message_builder.mschat_room_idx = visaCheckData['mschat_room_idx'];
      message_builder.type = message_type;
      if (message_type === 'FILE') {
        message_builder.file_type = enc_message.file_type;
        message_builder.file_path = encMessage.file_path;
      }
      message_builder.version = 1;
      message_builder.receipt = 'PENDING';
      message_builder.client_message_id = client_message_id;
      message_builder.cur_main_chain_hash = cur_x3dh_key_info.main_chain_key_hash;
      message_builder.dh_chain_ind = dh_link.dh_chain_ind;
      message_builder.kdf_chain_ind = kdf_link.kdf_chain_ind;
      message_builder.dh_cur_key = {
        id: user_key_info.user_key_idx,
        public_key_hex: user_key_info.public_key,
      };
      message_builder.nonce = enc_message.nonce;
      message_builder.tag = enc_message.tag;
      message_builder.message = enc_message.encrypted_message;
      message_builder.signature = signature;
      return message_builder;
    }
  }
  async handshake(visaCheckData, socketSend, requestSessionData) {
    let initMessageBuilder = this.messageProto;
    try {
      const user = this.userFromRedux;
      const dbInstance = IndexedDB.getInstance();

      // 악수를 위한 일회성 키를 3개 생성하고 저장한다.
      let ek_key_idx = await Api.insertNextKey(
        visaCheckData['user_idx'],
        user.password,
        'EK',
        dbInstance,
      );
      let dh_ck_key_idx_1 = await Api.insertNextKey(
        visaCheckData['user_idx'],
        user.password,
        'DHCK',
        dbInstance,
      );
      let dh_ck_key_idx_2 = await Api.insertNextKey(
        visaCheckData['user_idx'],
        user.password,
        'DHCK',
        dbInstance,
      );
      let dbInstance_user_key_ek = await dbInstance.get_user_key(
        ek_key_idx,
        visaCheckData['user_idx'],
        'EK',
      );

      let dbInstance_user_key_dh_ck_1 = await dbInstance.get_user_key(
        dh_ck_key_idx_1,
        visaCheckData['user_idx'],
        'DHCK',
      );
      let dbInstance_user_key_dh_ck_2 = await dbInstance.get_user_key(
        dh_ck_key_idx_2,
        visaCheckData['user_idx'],
        'DHCK',
      );

      // 악수 신청 시에는 현재 시간의 hex 값을 서명한다.
      let message_to_sign = convertToHex(Date.now().toString());
      let dbInstance_user_key_ik = await dbInstance.get_user_keys(visaCheckData['user_idx'], 'IK');
      const signature = await signMessage(dbInstance_user_key_ik, message_to_sign);

      // 봉투 생성
      const message_builder = buildMessage(
        dbInstance_user_key_ek,
        dbInstance_user_key_dh_ck_1,
        dbInstance_user_key_dh_ck_2,
        message_to_sign,
        signature,
      );

      socketSend('/mschat/message/', JSON.stringify(message_builder));
      store.dispatch(userActions.setIsOtherBrowserConnection(false));
    } catch (error) {
      console.error('악수 진행 실패 error', error);
    }
    // ============================================================
    async function signMessage(dbInstance_user_key_ik, message_to_sign) {
      const privateKey = await decrypt_key(
        dbInstance_user_key_ik.enc_private_key,
        visaCheckData['auth_hash'],
        dbInstance_user_key_ik.user_key_idx,
      );
      return calculate_signature(privateKey, message_to_sign);
    }

    function buildMessage(
      dbInstance_user_key_ek,
      dbInstance_user_key_dh_ck_1,
      dbInstance_user_key_dh_ck_2,
      message,
      signature,
    ) {
      initMessageBuilder.mschat_room_idx = visaCheckData['mschat_room_idx'];
      initMessageBuilder.type = 'KEY_EXCHANGE';
      initMessageBuilder.version = 1;
      initMessageBuilder.receipt = 'PENDING';
      initMessageBuilder.client_message_id = crypto.randomUUID();
      initMessageBuilder.message = message;
      initMessageBuilder.signature = signature;
      initMessageBuilder.sender_ek = {
        id: dbInstance_user_key_ek.user_key_idx,
        public_key_hex: dbInstance_user_key_ek.public_key,
      };
      initMessageBuilder.sender_dh_ck_1 = {
        id: dbInstance_user_key_dh_ck_1.user_key_idx,
        public_key_hex: dbInstance_user_key_dh_ck_1.public_key,
      };
      initMessageBuilder.sender_dh_ck_2 = {
        id: dbInstance_user_key_dh_ck_2.user_key_idx,
        public_key_hex: dbInstance_user_key_dh_ck_2.public_key,
      };

      return initMessageBuilder;
    }
  }

  // S3 파일 업로드
  async uploadFile(formData, _token) {
    return tokenInstance
      .post(this.#baseURL + '/chat/blob/upload', formData, {
        headers: {token: _token},
      })
      .then((result) => {
        return result.data;
      });
  }
}
// 메시지 수신시 다운로드 받은 파일을 UI에 보여줘야함
export async function fileDownload(mschat_room_idx, file_path, token) {
  return tokenInstance
    .post(
      process.env.REACT_APP_BACKEND_URL + '/chat/blob/download',
      returnFormData({
        user_country_code: '82',
        mschat_room_idx: mschat_room_idx,
        key: file_path,
      }),
      {
        responseType: 'blob',
        headers: {token: token},
      },
    )
    .then((result) => {
      return result.data;
    });
}
