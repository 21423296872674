export default class FormFunctions {
  static checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };
}

export function returnFormData(params) {
  const formData = new FormData();
  Object.entries(params).forEach((item) => formData.append(item[0], item[1]));
  return formData;
}
