import React, {useContext} from 'react';
import './modal.scss';
import {ModalDispatchContext} from './ModalContext';
import {ReactComponent as CancelIcon} from 'image/icon/ic_cancel.svg';
export default function Modal({cl, id, openedModals, header, children, type, zIndexStyle}) {
  const {close} = useContext(ModalDispatchContext);
  const closeModal = () => {
    close(id);
  };
  // ******************************************5
  return (
    <article
      className={
        Array.isArray(openedModals) && openedModals.includes(id)
          ? `openModal modal ${id} ${cl}`
          : `modal ${id} ${cl}`
      }
      style={{zIndex: zIndexStyle === 2 && '10005'}}>
      {openedModals.includes(id) && type === 'text' ? (
        <div className="modal_container">
          <div className="modal_header" onClick={closeModal} style={{cursor: 'pointer'}}>
            {/*<div className="flex-1"></div>*/}
            {/*<p style={{marginBottom: 0}}>{header}</p>*/}
            <CancelIcon fill="black" />
          </div>

          <div className="modal_main">
            {id.indexOf('Html') === -1 ? (
              <>{children}</>
            ) : (
              <div dangerouslySetInnerHTML={{__html: children}}></div>
            )}
          </div>
        </div>
      ) : null}

      {openedModals.includes(id) && type === 'image' ? (
        <div className="image_modal">
          <CancelIcon fill="black" onClick={closeModal} />
          {children}
        </div>
      ) : null}
    </article>
  );
}
Modal.defaultProps = {
  type: 'text',
  zIndexStyle: 1,
};
