import React from 'react';
import './test.scss';
import ZoomPanPinchTest from './ZoomPanPinchTest';
import WebViewScrollTest from './WebViewTestTwo';

export default function Tests() {
  // =================================================
  return (
    <div className="C_test">
      {/*<ToastifyTest />*/}
      {/*<ScrollTest />*/}
      {/*<LodashTest />*/}
      {/*<ProgressTest />*/}
      {/*<LoadingTest />*/}
      {/*<WebViewTest />*/}
      {/*<WebViewTestTwo />*/}
      <WebViewScrollTest />
    </div>
  );
}
