import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import Swal from 'sweetalert2';
import classnames from 'classnames';
import AuthAgreement from './AuthAgreement';
import {Api, AuthApi} from '../../api/api';
import {CommonContext} from '../../context/ComomContext';
import {produce} from 'immer';
import {getRoomUrl} from '../../utils/Functions';
import FormFunctions from '../../utils/FormFuntions';

export default function SimpleUserLogin() {
  const {visaCheckData} = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const roomUrl = getRoomUrl(location);
  const commonContext = useContext(CommonContext);
  const authApi = new AuthApi(commonContext);
  const [isLoading, setLoading] = useState(false);

  const [isMvno, setIsMvno] = useState(false);
  const [agreementState, setAgreementState] = useState(['n', 'n', 'n', 'n', 'n']);
  // region ************************ Form **************************
  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors},
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      enc_room_info: roomUrl,
    },
  });
  const [userAuthCodeResult, setUserAuthCodeResult] = useState();
  window.userAuthCodeResult = userAuthCodeResult;
  const onSubmit = useCallback((data) => {
    setLoading(true);
    sendAuthCode(data);
  }, []);
  function sendAuthCode(data) {
    let newData = {
      ...data,
      AGREE1: 'Y',
      AGREE2: 'Y',
      AGREE3: 'Y',
      AGREE4: 'Y',
      AGREEALL: 'on',
    };
    Api.userAuthSend(newData)
      .then((result) => {
        // console.log('result', result);
        if (result.Code === 200 || result.Code === 210) {
          if (!!result.Msg) {
            setValue('OTP_NO', result.Msg);
          }
          setUserAuthCodeResult(result.Msg || result.TX_SEQ_NO);
        } else {
          Swal.fire({
            // httpStatusCode가 400 or 500일때
            icon: 'error',
            text: result.Msg,
          });
          navigate('/');
        }
      })
      .catch((result) => {
        console.error('error result', result);
        Swal.fire({
          // httpStatusCode가 400 or 500일때
          icon: 'error',
          text: '현재 서버에 문제가 있습니다. 관리자에게 문의해주세요.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onLogin() {
    const data = watch(); // 현재 입력된 데이터 가져오기
    let newData = {
      ...data,
      TX_SEQ_NO: userAuthCodeResult,
      user_sex: data.SEX_CD,
      app_name: 'WEB_POP',
      mschat_room_idx: visaCheckData.mschat_room_idx,
      user_idx: visaCheckData.user_idx,
      user_country_code: '82',
      user_contact_number: data.TEL_NO,
    };
    authApi
      .login(newData)
      .then((result) => {
        if (result.Code === 200 || result.Code === 210 || result.Code === 201) {
          navigate(`/password/${roomUrl}`);
        } else {
          Swal.fire({
            // httpStatusCode가 400 or 500일때
            icon: 'error',
            text: result.Msg,
          });
        }
      })
      .catch((result) => {
        console.error('error result', result);
        Swal.fire({
          // httpStatusCode가 400 or 500일때
          icon: 'error',
          text: '현재 서버에 문제가 있습니다. 관리자에게 문의해주세요.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setValue('TEL_NO', visaCheckData.user_contact_number);
    setValue('NAME', visaCheckData.user_name);
    setValue('NTV_FRNR_CD', visaCheckData.is_foreigner);
    setValue('BIRTHDAY', visaCheckData.user_birth.replace(/[^0-9]/g, ''));
    setValue('SEX_CD', visaCheckData.user_gender);
    setValue('TEL_COM_CD', visaCheckData.user_phone_service_provider);
    setValue(
      'TEL_NO_DUMMY',
      visaCheckData.user_contact_number.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3'),
    );
  }, [visaCheckData]);
  // =================================================
  if (agreementState === undefined) return <div>agreementState 값이 dddsf </div>;
  return (
    <section className="C_auth C_SimpleUserLogin">
      <div className="header">
        <h1> 본인 인증</h1>
        <p>채팅방 사용을 위해 본인 인증 후 비밀번호를 설정해 주세요.</p>
      </div>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        onKeyDown={(e) => FormFunctions.checkKeyDown(e)}>
        <input type="hidden" {...register('NAME')} />
        <input type="hidden" {...register('NTV_FRNR_CD')} />
        <input type="hidden" {...register('BIRTHDAY')} />
        <input type="hidden" {...register('SEX_CD')} />
        <input type="hidden" {...register('TEL_NO_REAL')} />
        <div className="input_container phone_number" style={{marginBottom: 0}}>
          <div className="input_box">
            <select
              {...register(`TEL_COM_CD`)}
              onChange={(e) => {
                if (e.target.value === '04' || e.target.value === '05' || e.target.value === '06') {
                  setIsMvno(true);
                } else {
                  setIsMvno(false);
                }

                setAgreementState((prev) =>
                  produce(prev, (draft) => {
                    draft[4] = 'n';
                  }),
                );
              }}>
              <option value="01">SKT</option>
              <option value="02">KT</option>
              <option value="03">LGU+</option>
              <option value="04">알뜰폰 SKT</option>
              <option value="05">알뜰폰 KT</option>
              <option value="06">알뜰폰 LGU+</option>
            </select>
          </div>

          <div className="input_box">
            <input
              type="text"
              placeholder="휴대폰 번호"
              readOnly={true}
              className={classnames({error: errors.conferenceUserName})}
              {...register('TEL_NO_DUMMY', {
                required: '전화번호를 입력하세요.',
              })}
              onBlur={() => trigger('TEL_NO_DUMMY')}
            />
          </div>
          {errors.TEL_NO_DUMMY && (
            <div className="error_message">{errors.TEL_NO_DUMMY.message}</div>
          )}
        </div>
        <AuthAgreement
          isMvno={isMvno}
          agreementState={agreementState}
          setAgreementState={setAgreementState}
        />
        <div>
          {/* 모든 동의값이 true라면 */}
          {agreementState.includes('n') || (
            <div className="input_container auth_number">
              <div className="input_box name">
                <input
                  {...register('OTP_NO')}
                  type="text"
                  placeholder="인증번호 6자리 입력"
                  className={classnames({error: errors.conferenceUserName})}
                />
              </div>
              <button type="submit">인증요청</button>
            </div>
          )}
        </div>
        <div className="bottom_container">
          {!!userAuthCodeResult ? (
            <button type="button" onClick={() => onLogin()}>
              인증하기
            </button>
          ) : (
            <div>인증하기</div>
          )}
          <a href="mailto:support@medistaff.co.kr">문의하기 support@medistaff.co.kr</a>
        </div>
      </form>
    </section>
  );
}
