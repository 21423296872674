import dayjs from 'dayjs';
import 'dayjs/locale/ko';

export default function makeSection(chatList) {
  dayjs.locale('ko');
  const sections = {};
  chatList.forEach((chat) => {
    const monthData = dayjs(chat.message_send_t).format('YYYY년 MM월 DD일 dddd');

    // 이미 만들었다면 채팅 내용만 넣어주기
    if (Array.isArray(sections[monthData])) {
      if (chat.message_status === 'PLANE') {
        sections[monthData].push(chat);
      } else {
        // 혹여나 같은 메시지가 한번더 들어올경우는 값 넣어주지 말기
        const chatExists = sections[monthData].some(
          (existingChat) => existingChat.mschat_message_idx === chat.mschat_message_idx,
        );

        if (chatExists) {
          // console.error('추후에 여기로 값 안들어오게 코드 리팩토링 필요함');
        } else {
          sections[monthData].push(chat);
        }
      }
    } else {
      // 배열 생성해주고 채팅 내용 또한 넣어주기
      sections[monthData] = [chat];
    }
  });
  return sections;
}
