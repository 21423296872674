import React from 'react';
import {ReactComponent as FailIcon} from '../../image/icon/fail.svg';
import {useParams} from 'react-router-dom';

export default function ChatError({errorResult}) {
  // let {errorId} = useParams();
  if (errorResult.Msg === '만료된 채팅방입니다.') {
    return (
      <div className="C_ChatError">
        <FailIcon />
        <h1>만료된 채팅방</h1>
        <div>
          <p>만료된 채팅방입니다</p>
          <p>새로운 채팅방을 이용해 주세요.</p>
        </div>
      </div>
    );
  } else if (errorResult.Msg === '잘못된 경로입니다.') {
    return (
      <div className="C_ChatError">
        <img
          className="error_img"
          src={process.env.PUBLIC_URL + '/image/error404.png'}
          alt="accordion icon"
        />
        <div className="error_text">
          페이지의 주소가 잘못 입력되었거나,
          <br />
          주소 변경 혹은 삭제되어 페이지를 찾을 수 없습니다.
        </div>
      </div>
    );
  } else if (errorResult.Msg === '권한이 만료되었습니다.') {
    return (
      <div className="C_ChatError">
        <FailIcon />
        <h1>만료된 채팅방</h1>
        <div>
          <p>만료된 채팅방입니다</p>
          <p>새로운 채팅방을 이용해 주세요.</p>
        </div>
      </div>
    );
  }
}

// <h1>채팅방 접근 불가</h1>
// <div>
//   <p>죄송합니다. 오류가 발생하여 채팅방에 접근할 수 없습니다. </p>
//   <p>친구에게 새 링크를 요청하신 후 신규 채팅방을 이용해 주세요.</p>
