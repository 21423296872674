import React from 'react';
import './code_status.scss';
import {ReactComponent as FailIcon} from 'image/icon/fail.svg';

function Error({errorCode}) {
  // console.log(`<Error> errorCode : ${errorCode}`);
  // ******************************************
  return (
    <>
      <article className="C_Error">
        {errorCode === 401 && (
          <div className="error_content 401_error_content">
            <img
              className="error_img"
              src={process.env.PUBLIC_URL + '/image/error401.png'}
              alt="accordion icon"
            />
            <div className="error_text">
              <p>해당 페이지에 요청 권한이 없습니다.</p>
              <p>잠시 후 다시 시도해주세요.</p>
            </div>
          </div>
        )}

        {(errorCode === 404 || errorCode === '') && (
          <div className="error_content 404_error_content">
            <img
              className="error_img"
              src={process.env.PUBLIC_URL + '/image/error404.png'}
              alt="accordion icon"
            />
            <div className="error_text">
              페이지의 주소가 잘못 입력되었거나,
              <br />
              주소 변경 혹은 삭제되어 페이지를 찾을 수 없습니다.
            </div>
          </div>
        )}

        {errorCode === 503 && (
          <div className="error_content 401_error_content">
            <FailIcon />
            <div className="error_text">
              데이터를 불러오는데 실패했습니다. <br />
              잠시 후 다시 시도해주세요.
            </div>
          </div>
        )}
      </article>
    </>
  );
}
export default Error;
