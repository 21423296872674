import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  requestSession: null,
  token: null,
  password: null,
  roomInfo: null,
  memberData: null,
  isOtherBrowserConnection: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRequestSession(state, {payload}) {
      state.requestSession = payload;
    },
    setToken(state, {payload}) {
      state.token = payload;
    },
    setPassword(state, {payload}) {
      state.password = payload;
    },
    setRoomInfo(state, {payload}) {
      state.roomInfo = payload;
      state.memberData = {
        me: payload.room_members.find((item) => item.is_me === 'y'),
        you: payload.room_members.find((item) => item.is_me === 'n') || null,
      };
    },
    deleteRoomInfo(state, {payload}) {
      state.roomInfo = null;
    },
    deleteRequestSession(state, {payload}) {
      state.requestSession = null;
    },
    setIsOtherBrowserConnection(state, {payload}) {
      state.isOtherBrowserConnection = payload;
    },
  },
});
export const userActions = userSlice.actions;
export default userSlice.reducer;
