import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {ReactComponent as ChatRightTailIcon} from 'image/icon/chat_right_tail.svg';
import {ReactComponent as ChatLeftTailIcon} from 'image/icon/chat_left_tail.svg';
import {ReactComponent as KeyChangeIcon} from 'image/icon/key_change.svg';
import {ReactComponent as BookMarkIcon} from 'image/icon/book_mark.svg';
import {ReactComponent as ArrowRightIcon} from 'image/icon/arrow_right.svg';
import '../chat.scss';
import dayjs from 'dayjs';
import CIByMessageStatus from './CIByMessageStatus';
import {useDispatch, useSelector} from 'react-redux';
import IndexedDB from 'class/IndexedDB';
import ChatItemInFile from './CIFile';
import OpponentRedMessageItem from './OpponentRedMessageItem';
import CIOpponentProfile from './CIOpponentProfile';
import {modalActions} from '../../../modules/modal';
import {useLongPress} from 'use-long-press';

// 리듀서로 터치 이벤트, 클릭이벤트 빼야할듯
export default function ChatItem({item, setOpenImageData, setProfileData, scrollbarRef}) {
  const [bigText, setBigText] = useState();
  let sizeLimit = 1000; // 앱에서는 30줄에 2000자
  useEffect(() => {
    setBigText(
      item?.message?.length > sizeLimit ? item.message.slice(0, sizeLimit - 3) + '...' : null,
    );
  }, [item]);

  const user = useSelector((state) => state.user);

  function isFile(item) {
    return Object.keys(item).includes('file_type') && item.file_type !== 'NONE';
  }

  return (
    <>
      <article
        className={classnames('C_ChatItem', {
          server: item.message_type === 'SERVER_MESSAGE' || item.message_type === 'KEY_EXCHANGE',
          key_exchange: item.message_type === 'KEY_EXCHANGE',
          me:
            item.message_type !== 'SERVER_MESSAGE' &&
            item.message_type !== 'KEY_EXCHANGE' &&
            item.is_sending &&
            item.is_sending === true,
          you:
            item.message_type !== 'SERVER_MESSAGE' &&
            item.message_type !== 'KEY_EXCHANGE' &&
            (!item.is_sending || item.is_sending === false),
          red: item.message_status === 'RED',
          delete: item.is_delete === true,
        })}>
        {/* 상대방이 보낸 메시지*/}
        {item.is_sending === false ? (
          <>
            <CIOpponentProfile setProfileData={setProfileData} />
            <div className="right_box">
              <div className="name">{user.roomInfo?.mschat_room_name}</div>
              {item.is_delete === true && (
                <div className="tob_box">
                  <div className="message">삭제된 메시지 입니다.</div>
                </div>
              )}
              {item.is_delete !== true && item.message_status === 'RED' && (
                <OpponentRedMessageItem />
              )}
              {item.is_delete !== true && item.message_status !== 'RED' && (
                <OpponentNormalMessageItem
                  item={item}
                  isFile={isFile}
                  setOpenImageData={setOpenImageData}
                  bigText={bigText}
                  scrollbarRef={scrollbarRef}
                />
              )}
              <MessageItemBottom item={item} />
            </div>
          </>
        ) : (
          // 내가 보낸 내용
          <>
            {item.is_delete === true ? (
              <div className="tob_box">
                <div className="message">삭제된 메시지 입니다.</div>
              </div>
            ) : (
              <MyNormalMessageItem
                item={item}
                isFile={isFile}
                setOpenImageData={setOpenImageData}
                bigText={bigText}
                scrollbarRef={scrollbarRef}
              />
            )}
            <MessageItemBottom item={item} />
          </>
        )}
      </article>
    </>
  );
}
// ========================================================================================
function OpponentNormalMessageItem({item, isFile, setOpenImageData, bigText, scrollbarRef}) {
  const dispatch = useDispatch();
  const mobileMessageEvent = useLongPress(
    (e) => dispatch(modalActions.mobileMessageEvent({e, item})),
    {
      threshold: 300, // press 시간 /ms 초 단위이다.
      captureEvent: true, // 첫번째 인자로 들어온 callback 함수가 react MouseEvent를 도와주게 설정
      cancelOnMovement: true, // 확실하진않지만 꾹 눌렀다가 옆으로 이동했을때 취소여부 옵션인것같다
    },
  );
  return (
    <div className="tob_box">
      {isFile(item) ? (
        <ChatItemInFile
          item={item}
          setOpenImageData={setOpenImageData}
          scrollbarRef={scrollbarRef}
        />
      ) : (
        <>
          <div
            className={classnames('message')}
            onMouseDown={(e) => dispatch(modalActions.webMessageEvent({e, item}))}
            onContextMenu={(e) => dispatch(modalActions.webMessageEvent({e, item}))}
            {...mobileMessageEvent}>
            {item.message_type === 'KEY_EXCHANGE' && <KeyChangeIcon />}
            {Object.keys(item).includes('icon_type') === 'un_read_message' && <BookMarkIcon />}
            {bigText ? (
              <div>
                <div>{bigText}</div>
                <div
                  className="flex flex-row mt-2 cursor-pointer"
                  style={{color: '#9698A3'}}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(modalActions.setFullMessage(item.message));
                  }}>
                  전체보기
                  <div className="flex-1"></div>
                  <ArrowRightIcon />
                </div>
              </div>
            ) : (
              item.message
            )}
          </div>
          <ChatLeftTailIcon className="chat_left_tail_icon chat_tail_icon" />
        </>
      )}
    </div>
  );
}
React.memo(OpponentNormalMessageItem);
// ========================================================================================
function MyNormalMessageItem({item, isFile, setOpenImageData, bigText, scrollbarRef}) {
  const dispatch = useDispatch();
  const mobileMessageEvent = useLongPress(
    (e) => dispatch(modalActions.mobileMessageEvent({e, item})),
    {
      threshold: 300, // press 시간 /ms 초 단위이다.
      captureEvent: true, // 첫번째 인자로 들어온 callback 함수가 react MouseEvent를 도와주게 설정
      cancelOnMovement: true, // 확실하진않지만 꾹 눌렀다가 옆으로 이동했을때 취소여부 옵션인것같다
    },
  );
  return (
    <div className="tob_box">
      {isFile(item) ? (
        <ChatItemInFile
          item={item}
          setOpenImageData={setOpenImageData}
          scrollbarRef={scrollbarRef}
        />
      ) : (
        <>
          <div
            className={classnames('message')}
            onMouseDown={(e) => dispatch(modalActions.webMessageEvent({e, item}))}
            onContextMenu={(e) => dispatch(modalActions.webMessageEvent({e, item}))}
            {...mobileMessageEvent}>
            {item.message_type === 'KEY_EXCHANGE' && <KeyChangeIcon />}
            {bigText ? (
              <div>
                <div>{bigText}</div>
                <div
                  className="flex flex-row mt-2 cursor-pointer"
                  style={{color: '#9698A3'}}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(modalActions.setFullMessage(item.message));
                  }}>
                  전체보기
                  <div className="flex-1"></div>
                  <ArrowRightIcon />
                </div>
              </div>
            ) : (
              item.message
            )}
          </div>
          <ChatRightTailIcon className="chat_right_tail_icon chat_tail_icon" />
        </>
      )}
    </div>
  );
}
React.memo(MyNormalMessageItem);
// ========================================================================================
function MessageItemBottom({item}) {
  return (
    <div className={classnames('bottom_box', {'flex flex-row': item.message_status !== 'BLUE'})}>
      <CIByMessageStatus
        messageStatus={item.message_status}
        messageWarning={item.message_warning || null}
      />
      {dayjs(item.message_send_t).format('a hh:mm ')}
    </div>
  );
}
React.memo(MessageItemBottom);
