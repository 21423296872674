import React, {useCallback, useRef, useState} from 'react';
import {ReactComponent as ExitIcon} from 'image/icon/exit.svg';
import {ReactComponent as ExitTwoIcon} from 'image/icon/exit_two.svg';
import {ReactComponent as ArrowRightIcon} from 'image/icon/arrow_right.svg';
import {ReactComponent as MeIcon} from 'image/icon/me.svg';
import {ReactComponent as TrashIcon} from 'image/icon/trash.svg';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import {produce} from 'immer';
import EncryptionKey from './EncryptionKey';
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {Token} from 'class/Token';
import IndexedDB from 'class/IndexedDB';
import {chatActions} from 'modules/chat';
import {getRoomUrl} from '../../utils/Functions';
import {toast} from 'react-toastify';
import {useQueryClient} from 'react-query';

export default function Setting({setIsOpenSetting}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const dbInstance = IndexedDB.getInstance();
  const user = useSelector((state) => state.user);
  const {setProfileData} = useOutletContext();
  const [isOpenEncrypt, setIsOpenEncrypt] = useState(false);

  const onDeleteMessage = useCallback(() => {
    Swal.fire({
      title: '채팅방 대화삭제',
      text: '현재 채팅방의 모든 대화내용을 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '대화삭제',
      cancelButtonText: '취소',
    }).then((swal) => {
      if (swal.isConfirmed) {
        dbInstance.delete_messages(user.memberData.me.user_idx, user.roomInfo.mschat_room_idx);
        dispatch(chatActions.deleteChats());
        toast.dark('대화내용이 삭제되었습니다.', {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: 'allMessageDelete',
          zIndex: 3,
        });
      }
    });
  }, [dbInstance]);

  // 방나가기
  function leaveRoom() {
    Swal.fire({
      title: '채팅방 나가기',
      text: '나가기를 하면 대화내용이 모두 삭제되고 재입장이 불가합니다.',
      showCancelButton: true,
      confirmButtonText: '나가기',
      cancelButtonText: '취소',
    }).then(async (swal) => {
      if (swal.isConfirmed) {
        const roomUrl = getRoomUrl(location);
        const tokenAPi = new Token(user.token);
        await tokenAPi.postLeaveRoom({
          mschat_room_idx: user.roomInfo.mschat_room_idx,
        });
        await dbInstance.destroy_room(user.memberData.me.user_idx, user.roomInfo.mschat_room_idx);
        queryClient.invalidateQueries({queryKey: ['visaCheckData', {enc_room_info: roomUrl}]});
        navigate(`/${roomUrl}`);
      }
    });
  }
  const linkRef = useRef(null);
  function currentCopyLink() {
    if (linkRef.current) {
      const currentUrl = window.location.href;
      const tempInput = document.createElement('input');
      tempInput.value = currentUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      toast.dark('링크가 복사되었습니다.', {
        toastId: 'notChangeEncryptionKey',
        zIndex: 3,
      });
    }
  }
  // =================================================
  return (
    <>
      <div
        className="shadow"
        onClick={() => {
          toast.dismiss();
          setIsOpenSetting(false);
        }}></div>
      <section className="C_Setting">
        <div className="setting_header">
          <ExitIcon
            ref={linkRef}
            onClick={currentCopyLink}
            // onClick={() => {
            //   setIsOpenSetting((prev) => !prev);
            // }}
          />
        </div>
        <div className="setting_body">
          <div
            className="key cursor-pointer"
            onClick={() => {
              if (!user.memberData.you) {
                toast.dark('암호키 변경이 불가합니다.', {
                  position: toast.POSITION.BOTTOM_CENTER,
                  toastId: 'notChangeEncryptionKey',
                  zIndex: 3,
                });
              } else {
                setIsOpenEncrypt(true);
              }
            }}>
            <h6>암호화 키</h6>
            <ArrowRightIcon />
          </div>
          <div className="member">
            <h6>대화상대</h6>
            {/* 현재 자기 자신의 프로필은 데이터를 제공하지 않음 */}
            <div
              className="me"
              // onClick={() => {
              //   setProfileData((prev) =>
              //     produce(prev, (draft) => {
              //       draft.isOpen = true;
              //       draft.isMe = true;
              //     }),
              //   );
            >
              <img
                src={`${process.env.REACT_APP_MEDISTAFF_APP_SERVER}${user.memberData.me.user_profile_thumb_url}`}
              />
              <MeIcon />
              {user.memberData.me.user_name}
            </div>

            {!!user.memberData.you && (
              <div
                className="you cursor-pointer"
                onClick={() => {
                  setProfileData((prev) =>
                    produce(prev, (draft) => {
                      draft.isOpen = true;
                      draft.isMe = false;
                    }),
                  );
                }}>
                <img
                  src={`${process.env.REACT_APP_MEDISTAFF_APP_SERVER}${user.memberData.you.user_profile_thumb_url}`}
                />
                {user.memberData.you.user_name}
              </div>
            )}
          </div>
        </div>
        <div className="dummy_box flex-1"></div>
        <div className="setting_footer">
          <div
            className="cursor-pointer"
            onClick={() => {
              leaveRoom();
              // setIsOpenSetting((prev) => !prev);
            }}>
            <ExitTwoIcon />
            <div className="text">나가기</div>
          </div>

          <div className="dummy_box flex-1"></div>
          <div className="cursor-pointer" onClick={() => onDeleteMessage()}>
            <TrashIcon fill="#9698A3" />
            <div className="text">대화삭제</div>
          </div>
        </div>
      </section>
      {isOpenEncrypt && <EncryptionKey setIsOpenEncrypt={setIsOpenEncrypt} />}
    </>
  );
}
