import axios from 'axios';
import {returnFormData} from '../utils/FormFuntions';
import {convertToHex} from '../utils/Functions';

import {
  calculate_signature,
  decrypt_key,
  encrypt_key,
  generate_key_pair,
} from '../utils/ChatCryptoFunctions';
import {store} from '../store';
import IndexedDB from 'class/IndexedDB';
const instance = axios.create({withCredentials: true});
let baseURL = process.env.REACT_APP_BACKEND_URL;
export class Api {
  // static 함수의 경우 this 접근 못함...
  static dbInstance = IndexedDB.getInstance();
  constructor() {
    this.dbInstance = IndexedDB.getInstance();
  }

  static getUserFromRedux() {
    return store.getState().user;
  }

  static visaCheck(data) {
    return instance.post(baseURL + '/room/visa_check', returnFormData(data), {
      // spring이 @RequestBody Map<String, Object> param 형식일 경우
      headers: {'Content-Type': 'application/json'},
    });
  }

  // 휴대폰 인증번호 요청하기
  static userAuthSend(data) {
    return instance
      .post(baseURL + '/session/user_auth_send', returnFormData(data))
      .then((result) => {
        console.log('userAuthSendResult', result);
        return result.data;
      });
  }

  /*
   * 채팅 룸 비밀번호 설정
   * "PASSWORD" -> "1111"
   * "enc_room_info" -> "dSE+hzU4wSlSKlErQ43N6g=="
   */
  static submitPassword(data) {
    return instance.post(baseURL + '/room/submit_password', returnFormData(data)).then((result) => {
      // console.log('setPasswordResult', result);
      return result.data;
    });
  }

  static async requestSession(visaCheckData, password) {
    try {
      // 서버에서 받아온 값들로 키 값 API 호출
      const myKeysInfo = await this.getMyKeys(visaCheckData);
      if (!myKeysInfo) {
        return {Code: 400, MSG: '서버에 키 값 정보가 없어서 세션 요청을 할 수 없습니다'};
      }
      // console.log('myKeysInfo', myKeysInfo);
      const keyBundle = await this.replaceKeysIfNeeded(visaCheckData, myKeysInfo, password);
      // console.log('keyBundle', keyBundle);
      await this.updateMyKeysInServer(
        visaCheckData.user_auth_code,
        visaCheckData.user_contact_number,
        keyBundle,
      );

      return await this.requestSessionWithSignature(visaCheckData, password);
    } catch (error) {
      console.error('requestSession error', error);
      throw new Error('서버에 세션을 요청할 수 없습니다.');
    }
  }

  // 서버에서 요청한 키값들 교체
  static async replaceKeysIfNeeded(visaCheckData, myKeysInfo, password) {
    try {
      const keyBundle = [];

      for (const keyInfo of myKeysInfo.key_bundle) {
        const dbUserKey = await Api.dbInstance.get_user_key_with_public_key(
          keyInfo.user_key_idx,
          visaCheckData.user_idx,
          keyInfo.key_type,
          keyInfo.public_key,
        );

        // 키 상태가 좋지 않을 경우 키 교체
        if (keyInfo.key_status === 'dirty' || !dbUserKey) {
          await this.replaceKey(visaCheckData, keyInfo, keyBundle, myKeysInfo, password);
        }
      }

      await this.replaceKeysOfType(visaCheckData, 'IK', myKeysInfo.ik_need_count, keyBundle);
      await this.replaceKeysOfType(visaCheckData, 'SPK', myKeysInfo.spk_need_count, keyBundle);
      await this.replaceKeysOfType(visaCheckData, 'OPK', myKeysInfo.opk_need_count, keyBundle);

      return keyBundle;
    } catch (error) {
      console.error('replaceKeysIfNeeded Error', error);
    }
  }

  static async replaceKey(visaCheckData, keyInfo, keyBundle, myKeysInfo, password) {
    try {
      const newKeyIdx = await this.insertNextKey(
        visaCheckData.user_idx,
        visaCheckData.auth_hash,
        keyInfo.key_type,
      );
      const newKeyInfo = await Api.dbInstance.get_user_key(
        newKeyIdx,
        visaCheckData.user_idx,
        keyInfo.key_type,
      );
      delete newKeyInfo.enc_private_key;
      newKeyInfo.key_status = 'new';
      keyInfo.key_status = 'delete';
      keyBundle.push(keyInfo, newKeyInfo);

      if (keyInfo.key_status === 'dirty') {
        if (keyInfo.key_type === 'IK') {
          myKeysInfo.ik_need_count -= 1;
        } else if (keyInfo.key_type === 'SPK') {
          myKeysInfo.spk_need_count -= 1;
        } else if (keyInfo.key_type === 'OPK') {
          myKeysInfo.opc_need_count -= 1;
        }
      }
    } catch (error) {
      console.error('<replaceKey> Error', error);
    }
  }

  // TODO: 맨처음 시작할때 이부분에서 에러
  static async replaceKeyTwo(visaCheckData, keyInfo, keyBundle) {
    try {
      const newKeyIdx = await this.insertNextKey(
        visaCheckData.user_idx,
        visaCheckData.auth_hash,
        keyInfo.key_type,
      );
      const newKeyInfo = await Api.dbInstance.get_user_key(
        newKeyIdx,
        visaCheckData.user_idx,
        keyInfo.key_type,
      );
      delete newKeyInfo.enc_private_key;
      newKeyInfo.key_status = 'new';
      keyBundle.push(newKeyInfo);
    } catch (error) {
      console.error('<replaceKeyTwo> Error', error);
    }
  }

  static async replaceKeysOfType(visaCheckData, keyType, replaceCount, keyBundle) {
    for (let i = 0; i < replaceCount; i++) {
      await this.replaceKeyTwo(visaCheckData, {key_type: keyType}, keyBundle);
    }
  }

  // 서버의 tbl_mschat_user_key에 업데이트 하기
  static async updateMyKeysInServer(userAuthCode, userContactNumber, keyBundle) {
    return instance
      .post(
        baseURL + '/key/update_key_bundle',
        returnFormData({
          user_auth_code: userAuthCode,
          user_contact_number: userContactNumber,
          user_country_code: '82',
          key_bundle: JSON.stringify(keyBundle),
        }),
      )
      .then((result) => {
        // console.log("update_my_keysResult",result);
        return result.data;
      })
      .catch((error) => {
        console.error('<updateMyKeysInServer> Error', error);
        return null;
      });
  }

  // 서버 요청
  static async requestSessionWithSignature(visaCheckData) {
    try {
      const message = convertToHex(Date.now().toString());
      const dbUserKeyIk = await Api.dbInstance.get_user_keys(visaCheckData.user_idx, 'IK');
      const signature = calculate_signature(
        await decrypt_key(
          dbUserKeyIk.enc_private_key,
          visaCheckData.auth_hash,
          dbUserKeyIk.user_key_idx,
        ),
        message,
      );

      if (signature === undefined) {
        console.error('signature -> undefined');
      }

      let last_uuid_from_server = await Api.dbInstance.get_room_uuid(
        visaCheckData.mschat_room_idx,
        visaCheckData.user_idx,
      );

      const result = await instance.post(
        baseURL + '/session/request_session',
        returnFormData({
          user_auth_code: visaCheckData.user_auth_code,
          user_contact_number: visaCheckData.user_contact_number,
          user_country_code: '82',
          signature: signature,
          message: message,
          mschat_room_idx: visaCheckData.mschat_room_idx,
          last_uuid: last_uuid_from_server,
        }),
      );

      return result.data;
    } catch (error) {
      console.error('<requestSessionWithSignature> 에러', error);
    }
  }

  static getMyKeys(visaCheckData) {
    return instance
      .post(
        baseURL + '/key/get_key_bundle',
        returnFormData({
          user_auth_code: visaCheckData.user_auth_code,
          user_contact_number: visaCheckData.user_contact_number,
          user_country_code: '82',
        }),
      )
      .then((result) => {
        // console.log('setPasswordResult', result);
        return result.data;
      });
  }

  /*
  user_idx = 28278
  password = "$2a$10$xFCAkpYVAm5fXw8DIlSNs.YHHARt4e4VljZ9CZ2RA./ROO6dspRni"
  key_type = "IK"
  */
  static async insertNextKey(user_idx, password, key_type) {
    let key_pair = generate_key_pair();
    let key_info = {user_idx: user_idx, key_type: key_type, public_key: key_pair.public_key};
    let user_key_idx = await Api.dbInstance.put_user_key(key_info);
    key_info.user_key_idx = user_key_idx;
    key_info.enc_private_key = await encrypt_key(key_pair.private_key, password, user_key_idx);
    await Api.dbInstance.put_user_key(key_info);
    return user_key_idx;
  }
}

export class AuthApi {
  // 핸드폰 본인 인증 후 로그인하기
  login(data) {
    return instance
      .post(baseURL + '/session/user_auth_check', returnFormData(data))
      .then((result) => {
        console.log('loginResult', result);
        return result.data;
      });
  }
}

// 암호화 QR 데이터 가져오기
export function getQR(deepLink) {
  return instance
    .get(
      baseURL + `/keyutil/create_qr?qr_content=${encodeURIComponent(deepLink)}&app_name=MEDISTAFF`,
      {responseType: 'blob'},
    )
    .then((res) => {
      const blob = new Blob([res.data]);
      return URL.createObjectURL(blob);
    });
}
