import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Token} from 'class/Token';
import {Api} from '../../api/api';
import './chat.scss';
import ChatView from './ChatView';
import InnerError from '../layout/InnerError';
import Loading from '../layout/Loading';
import {getRoomUrl} from 'utils/Functions';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {userActions} from 'modules/user';
import useSocket from '../../hook/useSocket';
import IndexedDB from '../../class/IndexedDB';

function ChatLogic() {
  const dispatch = useDispatch();
  const {visaCheckData} = useOutletContext();
  const user = useSelector((state) => state.user, shallowEqual);
  const location = useLocation();
  const roomUrl = getRoomUrl(location);
  const navigate = useNavigate();
  const tokenAPi = new Token(user.token);
  const [requestSessionFail, setRequestSessionFail] = useState(null);
  const {socket} = useSocket('stompchat');
  const dbInstance = IndexedDB.getInstance();

  // 앱에서 백그라운드에서 포그라운드로 올경우 중복 로그인 체크하기
  const {data: chatVisaCheckData} = useQuery(
    ['chatVisaCheckData', {enc_room_info: roomUrl}],
    () => Api.visaCheck({enc_room_info: roomUrl}).then((res) => res.data),
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      cacheTime: 5000,
      enabled: !!roomUrl && roomUrl !== '/',
      onSuccess: async (response) => {
        // console.log('화면을 벗어났나요?', response);
        let last_uuid_client = await dbInstance.get_room_uuid(
          response.mschat_room_idx,
          response.user_idx,
        );

        if (!!last_uuid_client && last_uuid_client !== response.last_uuid) {
          // 동시로그인이면서 최근에 접근했던 브라우저가 아닌 거만 뒤로 보내기
          if (!!response.mschat_session_idx) {
            navigate(`/password-check/${roomUrl}`);
            return;
          }

          // 동시로그인은 아니지만 그 사이에 다른 브라우저에서 접속한 경우
          console.log('다른 브라우저에서 접속하였습니다.');
          dispatch(userActions.setIsOtherBrowserConnection(true));
        }
      },
    },
  );

  // 채팅방에서 다시 비자 체크 필요
  // useMutation(['visaCheckData', {enc_room_info: roomUrl, check_otp: 'y'}]);
  useEffect(() => {
    if (visaCheckData === undefined) {
      navigate(`/${roomUrl}`);
    } else if (visaCheckData['password_verify'] === 'n' || visaCheckData['Code'] !== 200) {
      navigate(`/${roomUrl}`);
    }

    if (user.password === null) {
      navigate(`/password/${roomUrl}`);
    }
  }, [visaCheckData, user.password]);

  const {
    data: requestSessionData,
    isLoading,
    error,
  } = useQuery(
    ['requestSession', visaCheckData],
    () => Api.requestSession(visaCheckData, user.password),
    {
      enabled: !!visaCheckData && !!user.password,
      onSuccess: async (result) => {
        socket?.deactivate();
        dispatch(userActions.setRequestSession(result));
        dispatch(userActions.setToken(result.token));
        if (result.Code === 100) {
          setRequestSessionFail('result.Msg');
          return;
        }
        let roomInfoResult = await tokenAPi.roomInfo(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          result.token,
        );
        // console.log('roomInfoResult', roomInfoResult);
        if (roomInfoResult !== 401) {
          dispatch(userActions.setRoomInfo(roomInfoResult));
        } else {
          navigate(`/password-check/${roomUrl}`);
        }
      },
    },
  );
  window.requestSessionData = requestSessionData;
  window.user = user;
  // =================================================
  if (error) return <InnerError errorCode={500} />;
  if (!!requestSessionFail) return <InnerError />;
  else if (isLoading) return <Loading />;

  if (!(!!requestSessionData && !!visaCheckData && !!user.roomInfo)) return <Loading />;
  if (user.roomInfo) return <ChatView />;
}
export default React.memo(ChatLogic);
