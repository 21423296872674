import {useEffect, useState} from 'react';

const useDynamicViewportHeight = () => {
  const [vh, setVh] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const newVh = window.innerHeight;
      setVh(newVh + 'px');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return vh;
};

export default useDynamicViewportHeight;
