import {CommonProvider} from './CommonProvider';
import {ModalProvider} from '../component/modal/ModalProvider';

export function ContextProviders({children}) {
  //****************************************
  return (
    <>
      {/*실질적으로 state들을 저장하고 관리하는 공간*/}
      <ModalProvider>
        <CommonProvider>{children}</CommonProvider>
      </ModalProvider>
    </>
  );
}
