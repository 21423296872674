import React from 'react';
import {ReactComponent as MSPIcon} from 'image/icon/message_status_plane.svg';
import {ReactComponent as MSBIcon} from 'image/icon/message_status_blue.svg';
import {ReactComponent as MSRIcon} from 'image/icon/message_status_red.svg';
import {ReactComponent as MSGIcon} from 'image/icon/message_status_gray.svg';
import {ReactComponent as MSYIcon} from 'image/icon/message_status_yellow.svg';
// import {ReactComponent as MSWIcon} from 'image/icon/message_status_warning.svg';

export default function CIByMessageStatus({messageStatus, messageWarning}) {
  if (messageStatus === 'PLANE')
    return (
      <div className="message_status plane">
        <MSPIcon />
      </div>
    );
  else if (messageStatus === 'GRAY')
    return (
      <div className="message_status gray">
        <MSGIcon />
      </div>
    );
  else if (messageStatus === 'BLUE')
    return (
      <div className="message_status blue">
        <MSBIcon />
      </div>
    );
  else if (messageStatus === 'RED')
    return (
      <div className="message_status red">
        <MSRIcon />
      </div>
    );
  // 노란 느낌표
  else if (messageStatus === 'YELLOW')
    return (
      <div className="message_status yellow">
        <MSYIcon />
      </div>
    );
  // 노란 방패
  // else if (!!messageWarning)
  //   return (
  //     <div className="message_status warning">
  //       <MSWIcon />
  //     </div>
  //   );
}
