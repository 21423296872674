import {createSlice} from '@reduxjs/toolkit';
import {isWeb} from '../utils/Functions';

const initialState = {
  blackLineModal: {
    content: null,
    zIndex: 1, // 이거 실제로 아직 사용안
  },
  messageOnClickData: [],
  fullMessage: null,
};
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setBlackLineModal(state, {payload}) {
      state.blackLineModal = payload;
    },

    // 각각의 채팅 메시지 클릭할 경우 보여주는 모달 제어
    deleteMessageEvent(state, {payload}) {
      payload.stopPropagation();
      state.messageOnClickData = [];
    },

    // 긴 메시지를 클릭할 경우 상세페이지
    setFullMessage(state, {payload}) {
      state.fullMessage = payload;
    },
    webMessageEvent(state, {payload}) {
      if (!isWeb) return;
      // 현재 내 장치가 모바일이거나 태블릿일경우 아래 이벤트 무시

      const {e, item} = payload;

      // console.log('message : ', item);
      if (Object.keys(item).includes('is_delete')) return;
      // console.log('e.type', e.type);
      switch (e.type) {
        // 오른쪽 버튼 클릭 시 동작
        case 'contextmenu':
          e.preventDefault();
          if (
            item.message_status === 'BLUE' ||
            item.message_status === 'PLANE' ||
            item.message_status === 'GRAY'
          ) {
            state.messageOnClickData = [
              {
                type: 'delete',
                data: item,
              },
              {
                type: 'copy',
                data: item,
              },
            ];
          }
          break;
        // 터치 이벤트 시 동작
        case 'touchstart':
          if (
            item.message_status === 'BLUE' ||
            item.message_status === 'PLANE' ||
            item.message_status === 'GRAY'
          ) {
            state.messageOnClickData = [
              {
                type: 'delete',
                data: item,
              },
              {
                type: 'copy',
                data: item,
              },
            ];
          } else {
            state.messageOnClickData = [
              {
                type: 're-send',
                data: item,
              },
              {
                type: 'delete',
                data: item,
              },
            ];
          }
          break;

        // 기본적으로 왼쪽 버튼 클릭 시 동작
        default:
          if (item.message_status === 'RED') {
            state.messageOnClickData = [
              {
                type: 'delete',
                data: item,
              },
            ];
          } else if (item.message_status === 'YELLOW') {
            state.messageOnClickData = [
              {
                type: 're-send',
                data: item,
              },
            ];
          }
      }
    },
    mobileMessageEvent(state, {payload}) {},
  },
});
export const modalActions = modalSlice.actions;
export default modalSlice.reducer;
// zIndex 각각의 모달마다 다르게 줘야함
// 덮어씌우기 할때에는 사라지는 이벤트가 없어야함
