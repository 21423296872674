import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {ReactComponent as NotCheckIcon} from '../../image/icon/not-check.svg';
import {ReactComponent as CheckedIcon} from '../../image/icon/checked.svg';
import {produce} from 'immer';
import Modal from '../../component/modal/Modal';
import {ModalDispatchContext, ModalStateContext} from '../../component/modal/ModalContext';

export default function AuthAgreement({isMvno, agreementState, setAgreementState}) {
  window.agreementState = agreementState;
  window.isMvno = isMvno;
  const {open, allClose} = useContext(ModalDispatchContext);
  const openModal = (e) => {
    open(e.target.id);
  };
  const {modalState} = useContext(ModalStateContext);

  const modalData = [
    {
      subject: '개인정보 이용취급위탁',
      content: {
        __html: '<iframe src="https://safe.ok-name.co.kr/eterms/agreement001.jsp"></iframe>',
      },
    },
    {
      subject: '본인확인서비스 이용약관',
      content: {
        __html: '<iframe src="https://safe.ok-name.co.kr/eterms/agreement002.jsp"></iframe>',
      },
    },
    {
      subject: '고유식별정보 처리',
      content: {
        __html: '<iframe src="https://safe.ok-name.co.kr/eterms/agreement003.jsp"></iframe>',
      },
    },
    {
      subject: '통신사 이용약관',
      content: {
        __html: '<iframe src="https://safe.ok-name.co.kr/eterms/agreement004.jsp"></iframe>',
      },
    },
  ];

  const modalDataTwo = [
    {
      subject: '개인정보 제3자 제공',
      content: {
        __html: '<iframe src="https://safe.ok-name.co.kr/eterms/agreement005.jsp"></iframe>',
      },
    },
  ];

  // 동의 여부 변경
  const handleAgreement = useCallback(
    (index, willChangeValue) => {
      setAgreementState((prev) =>
        produce(prev, (draft) => {
          draft[index] = willChangeValue;
        }),
      );
      allClose();
    },
    [agreementState],
  );

  const superToggleBtn = useCallback(() => {
    setAgreementState((prev) =>
      produce(prev, (draft) => {
        // Check if at least one false is present
        const hasFalse = draft.includes('n');

        // Toggle all items only when at least one false is present
        if (hasFalse) {
          for (let i = 0; i < draft.length; i++) {
            draft[i] = 'y';
          }
        } else {
          // Toggle all items to false when all are currently true
          for (let i = 0; i < draft.length; i++) {
            draft[i] = 'n';
          }
        }
      }),
    );
  }, [agreementState]);

  useEffect(() => {
    if (isMvno === false) {
      setAgreementState((prev) =>
        produce(prev, (draft) => {
          draft[4] = 'y';
        }),
      );
    }
  }, [isMvno, agreementState]);

  // =================================================
  return (
    <div>
      {/* 동의 체크박스*/}
      <div className="agreement_container">
        {agreementState.find((item) => item === 'n') !== undefined &&
          modalData.map((item, index) => (
            <div className={`item_${index}`} key={index}>
              {agreementState[index] === 'n' ? (
                <NotCheckIcon onClick={() => handleAgreement(index, 'y')} />
              ) : (
                <CheckedIcon onClick={() => handleAgreement(index, 'n')} />
              )}
              <u onClick={openModal} id={`item_${index}_modal`}>
                {item.subject}
              </u>
              <span>동의</span>
            </div>
          ))}

        {agreementState.find((item) => item === 'n') !== undefined &&
          isMvno &&
          modalDataTwo.map((item, index) => (
            <div className={`item_4`} key={index}>
              {agreementState[4] === 'n' ? (
                <NotCheckIcon onClick={() => handleAgreement(4, 'y')} />
              ) : (
                <CheckedIcon onClick={() => handleAgreement(4, 'n')} />
              )}
              <u onClick={openModal} id={`item_4_modal`}>
                {item.subject}
              </u>
              <span>동의</span>
            </div>
          ))}

        <div className={`item_6 total`} onClick={superToggleBtn}>
          {agreementState.find((item) => item === 'n') !== undefined ? (
            <NotCheckIcon />
          ) : (
            <CheckedIcon />
          )}
          <span className="">전체 동의</span>
        </div>
      </div>

      <div className="login_modal_container">
        {modalData.map((item, index) => (
          <Modal
            key={index}
            id={`item_${index}_modal`}
            openedModals={modalState.openedModals}
            header={'KCB'}
            cl="inner_modal">
            <>
              <div className="agreement_modal_header">KCB</div>
              <div className="agreement_modal_content" dangerouslySetInnerHTML={item.content}></div>
              <div className="agreement_modal_btn_box">
                <button onClick={() => handleAgreement(index, 'n')}>동의 안함</button>
                <button className="true" onClick={() => handleAgreement(index, 'y')}>
                  동의
                </button>
              </div>
            </>
          </Modal>
        ))}
        {modalDataTwo.map((item, index) => (
          <Modal
            key={index}
            id={`item_4_modal`}
            openedModals={modalState.openedModals}
            cl="inner_modal">
            <>
              <div className="agreement_modal_header">KCB</div>
              <div className="agreement_modal_content" dangerouslySetInnerHTML={item.content}></div>
              <div className="agreement_modal_btn_box">
                <button onClick={() => handleAgreement(4, 'n')}>동의 안함</button>
                <button className="true" onClick={() => handleAgreement(4, 'y')}>
                  동의
                </button>
              </div>
            </>
          </Modal>
        ))}
      </div>
    </div>
  );
}
