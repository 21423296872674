import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as PlusIcon} from 'image/icon/plus.svg';
import {ReactComponent as SendIcon} from 'image/icon/send.svg';
import TextareaAutosize from 'react-textarea-autosize';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {store} from '../../store';

export default function ChatSend({sendMessage, inputMessageRef, inputFileRef}) {
  const textAreaRef = useRef();
  const user = useSelector((state) => state.user);
  const [message, setMessage] = useState('');
  const isExistOtherPerson = useMemo(
    () => user.roomInfo?.room_members.find((item) => item.is_me === 'n') !== undefined,
    [user.roomInfo],
  );
  function getUserFromRedux() {
    return store.getState().user;
  }
  // =================================================
  useEffect(() => {
    // console.log('user.isOtherBrowserConnection', getUserFromRedux().isOtherBrowserConnection);
  }, [getUserFromRedux().isOtherBrowserConnection]);
  const onKeydownChat = useCallback(
    (e) => {
      if (!(!e.nativeEvent.isComposing && e.key === 'Enter')) {
        return;
      }
      if (!e.shiftKey) {
        // shiftKey는 줄바꿈
        e.preventDefault();
        textAreaRef.current.focus();
        if (user.isOtherBrowserConnection) {
          toast.dark('채팅 전송이 불가합니다.', {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: 'notChangeEncryptionKey',
          });
          return;
        }
        if (!isExistOtherPerson) {
          toast.dark('채팅 전송이 불가합니다.', {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: 'notChangeEncryptionKey',
          });
          return;
        }
        sendMessage();

        setMessage('');
      }
    },
    [isExistOtherPerson, user.isOtherBrowserConnection],
  );

  // https://itecnote.com/tecnote/html-ios-safari-unwanted-scroll-when-keyboard-is-opened-and-body-scroll-is-disabled/
  // 버튼 컴포넌트에 클릭 이벤트가 발생하면 강제로 input 태그에 클릭 이벤트 전달
  const onFileUpload = () => {
    if (isExistOtherPerson) {
      inputFileRef.current.click();
    } else {
      toast.dark('채팅 전송이 불가합니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'notChangeEncryptionKey',
      });
    }
  };

  const fileHandleChange = (e) => {
    if (user.isOtherBrowserConnection) {
      toast.dark('파일 전송이 불가합니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'notChangeEncryptionKey',
      });
      return;
    }
    if (!isExistOtherPerson) {
      toast.dark('파일 전송이 불가합니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'notChangeEncryptionKey',
      });
      return;
    }

    const files = e.target.files;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    const fileSize = files[0].size;
    if (fileSize > maxSizeInBytes) {
      toast.dark('용량은 5MB를 초과할수 없습니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'notCapacityExceeded',
      });
      inputFileRef.current.value = null;
      return;
    }
    sendMessage(files).then(() => {
      inputFileRef.current.value = null;
    });
  };

  return (
    <section className="C_ChatSend">
      <div className="plus_box">
        <input
          ref={inputFileRef}
          type="file"
          multiple={true}
          id="fileUpload"
          style={{display: 'none'}}
          onChange={fileHandleChange}
        />
        <PlusIcon onClick={onFileUpload} />
      </div>

      {/* 최대 4줄 그 이후로는 스크롤 */}
      <TextareaAutosize
        ref={textAreaRef}
        placeholder={'메시지를 입력하세요.'}
        maxRows={3}
        minRows={3}
        value={message}
        onKeyDown={onKeydownChat}
        onChange={(e) => {
          inputMessageRef.current = e.target.value;
          setMessage(inputMessageRef.current);
        }}
      />

      <SendIcon
        fill={!!message ? '#4D67F6' : '#E6E8EE'}
        onClick={() => {
          textAreaRef.current.focus();
          if (user.isOtherBrowserConnection) {
            toast.dark('채팅 전송이 불가합니다.', {
              position: toast.POSITION.BOTTOM_CENTER,
              toastId: 'notChangeEncryptionKey',
            });
            return;
          }
          if (!isExistOtherPerson) {
            toast.dark('채팅 전송이 불가합니다.', {
              position: toast.POSITION.BOTTOM_CENTER,
              toastId: 'notChangeEncryptionKey',
            });
            return;
          }
          sendMessage();
          setMessage('');
        }}
        className="send_icon"
      />
    </section>
  );
}
