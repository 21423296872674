import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import classnames from 'classnames';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import Swal from 'sweetalert2';
import {Api} from '../../api/api';

import {useDispatch, useSelector} from 'react-redux';
import {userActions} from 'modules/user';
import {getRoomUrl} from '../../utils/Functions';
import IndexedDB from 'class/IndexedDB';

export default function SetPassword() {
  useEffect(() => {
    passwordCheck();
    async function passwordCheck() {
      const response = await Api.visaCheck({enc_room_info: roomUrl}).then((res) => res.data);
      if (!!response.password) {
        navigate(`/password-check/${roomUrl}`);
      }
    }
  }, []);
  // =================================================

  // const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const dbInstance = IndexedDB.getInstance();
  const {visaCheckData} = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const roomUrl = getRoomUrl(location);
  const user = useSelector((state) => state.user);
  // region ******************************** Form ********************************
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      enc_room_info: roomUrl,
    },
  });

  const onSubmit = useCallback((data) => {
    Api.submitPassword(data)
      .then((result) => {
        if (result.Code === 200 || result.Code === 210) {
          Swal.fire({
            title: '비밀번호 설정 완료',
            text: '비밀번호가 설정되었습니다. \n' + '채팅방에 입장합니다.',
            allowOutsideClick: false,
            showCancelButton: false,
          }).then((swal) => {
            if (swal.isConfirmed) {
              if (password === undefined || password === null) {
                alert('password 저장 실패');
                return;
              }
              // mschat_room_idx, user_idx의 메시지, 악수, kdf, dh 관련된 모든 것을 삭제
              dbInstance.delete_old_data();
              dbInstance.delete_messages(visaCheckData.user_idx, visaCheckData.mschat_room_idx);
              dbInstance.put_room_uuid(
                visaCheckData.mschat_room_idx,
                visaCheckData.user_idx,
                crypto.randomUUID(),
              );
              dispatch(userActions.setPassword(password.current));
              navigate(`/chat/${roomUrl}`);
            }
          });
        } else {
          Swal.fire({
            // httpStatusCode가 400 or 500일때
            icon: 'error',
            text: result.Msg,
          });
        }
      })
      .catch((result) => {
        console.error('error result', result);
        Swal.fire({
          // httpStatusCode가 400 or 500일때
          icon: 'error',
          text: '현재 서버에 문제가 있습니다. 관리자에게 문의해주세요.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 비밀번호 체크
  const password = useRef({});
  password.current = watch('PASSWORD');
  // endregion ******************************** Form ********************************

  // =================================================

  return (
    <section className="C_auth">
      <div className="header">
        <h1>비밀번호 설정</h1>
        <p>채팅방 사용을 위해 본인 인증 후 비밀번호를 설정해 주세요.</p>
        <p>
          <span className="red">비밀번호 찾기 및 변경이 불가</span>
          하니 설정하신 비밀번호를 잘 기억해 주세요.
        </p>
      </div>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}>
        <div className="input_container password">
          <div className="input_box">
            <input
              type="PASSWORD"
              placeholder="비밀번호"
              className={classnames({error: errors.password})}
              {...register('PASSWORD', {required: '비밀번호를 입력해주세요.'})}
              onBlur={() => trigger('PASSWORD')}
            />
          </div>
          {errors.PASSWORD && <div className="error_message">{errors.PASSWORD.message}</div>}
        </div>

        <div className="input_container password">
          <div className="input_box">
            <input
              type="password"
              maxLength="20"
              placeholder="비밀번호 확인 "
              className={classnames({error: errors.passwordCheck})}
              {...register('passwordCheck', {
                required: '비밀번호 확인은 필수값입니다.',
                validate: (value) => value === password.current || '비밀번호가 일치하지 않습니다.',
              })}
              onBlur={() => trigger('passwordCheck')}
            />
          </div>
          {errors.passwordCheck && (
            <div className="error_message">{errors.passwordCheck.message}</div>
          )}
        </div>
        <div className="bottom_container">
          <button type="submit">인증하기</button>
          <a href="mailto:support@medistaff.co.kr">문의하기 support@medistaff.co.kr</a>
        </div>
      </form>
    </section>
  );
}
