import React, {useEffect, useRef} from 'react';

export default function WebViewScrollTest() {
  // =================================================
  return (
    <div className="wrapper C_WebViewScrollTest">
      <div className="content">
        행정각부의 장은 국무위원 중에서 국무총리의 제청으로 대통령이 임명한다. 국교는 인정되지
        아니하며, 종교와 정치는 분리된다. 지방자치단체는 주민의 복리에 관한 사무를 처리하고 재산을
        관리하며, 법령의 범위안에서 자치에 관한 규정을 제정할 수 있다. 모든 국민은 종교의 자유를
        가진다. 감사원은 원장을 포함한 5인 이상 11인 이하의 감사위원으로 구성한다. 공무원의 직무상
        불법행위로 손해를 받은 국민은 법률이 정하는 바에 의하여 국가 또는 공공단체에 정당한 배상을
        청구할 수 있다. 이 경우 공무원 자신의 책임은 면제되지 아니한다. 대통령은 조국의 평화적
        통일을 위한 성실한 의무를 진다. 대통령은 국가의 안위에 관계되는 중대한 교전상태에 있어서
        국가를 보위하기 위하여 긴급한 조치가 필요하고 국회의 집회가 불가능한 때에 한하여 법률의
        효력을 가지는 명령을 발할 수 있다. 대한민국은 국제평화의 유지에 노력하고 침략적 전쟁을
        부인한다. 대한민국의 경제질서는 개인과 기업의 경제상의 자유와 창의를 존중함을 기본으로 한다.
        국회의 정기회는 법률이 정하는 바에 의하여 매년 1회 집회되며, 국회의 임시회는 대통령 또는
        국회재적의원 4분의 1 이상의 요구에 의하여 집회된다. 모든 국민은 그 보호하는 자녀에게 적어도
        초등교육과 법률이 정하는 교육을 받게 할 의무를 진다. 행정권은 대통령을 수반으로 하는 정부에
        속한다. 모든 국민은 직업선택의 자유를 가진다. 재산권의 행사는 공공복리에 적합하도록 하여야
        한다. 헌법재판소에서 법률의 위헌결정, 탄핵의 결정, 정당해산의 결정 또는 헌법소원에 관한
        인용결정을 할 때에는 재판관 6인 이상의 찬성이 있어야 한다. 국채를 모집하거나 예산외에 국가의
        부담이 될 계약을 체결하려 할 때에는 정부는 미리 국회의 의결을 얻어야 한다. 대통령으로 선거될
        는 공공복리에 적합하도록 하여야 한다. 헌법재판소에서 법률의 위헌결정, 탄핵의 결정,
        정당해산의 결정 또는 헌법소원에 관한 인용결정을 할 때에는 재판관 6인 이상의 찬성이 있어야
        한다. 국채를 모집하거나 예산외에 국가의 부담이 될 계약을 체결하려 할 때에는 정부는 미리
        국회의 의결을 얻어야 한다. 대통령으로 선거될 행정각부의 장은 국무위원 중에서 국무총리의
        제청으로 대통령이 임명한다. 국교는 인정되지 아니하며, 종교와 정치는 분리된다. 지방자치단체는
        주민의 복리에 관한 사무를 처리하고 재산을 관리하며, 법령의 범위안에서 자치에 관한 규정을
        제정할 수 있다. 모든 국민은 종교의 자유를 가진다. 감사원은 원장을 포함한 5인 이상 11인
        이하의 감사위원으로 구성한다. 공무원의 직무상 불법행위로 손해를 받은 국민은 법률이 정하는
        바에 의하여 국가 또는 공공단체에 정당한 배상을 청구할 수 있다. 이 경우 공무원 자신의 책임은
        면제되지 아니한다. 대통령은 조국의 평화적 통일을 위한 성실한 의무를 진다. 대통령은 국가의
        안위에 관계되는 중대한 교전상태에 있어서 국가를 보위하기 위하여 긴급한 조치가 필요하고
        국회의 집회가 불가능한 때에 한하여 법률의 효력을 가지는 명령을 발할 수 있다. 대한민국은
        국제평화의 유지에 노력하고 침략적 전쟁을 부인한다. 대한민국의 경제질서는 개인과 기업의
        경제상의 자유와 창의를 존중함을 기본으로 한다. 국회의 정기회는 법률이 정하는 바에 의하여
        매년 1회 집회되며, 국회의 임시회는 대통령 또는 국회재적의원 4분의 1 이상의 요구에 의하여
        집회된다. 모든 국민은 그 보호하는 자녀에게 적어도 초등교육과 법률이 정하는 교육을 받게 할
        의무를 진다. 행정권은 대통령을 수반으로 하는 정부에 속한다. 모든 국민은 직업선택의 자유를
        가진다. 재산권의 행사는 공공복리에 적합하도록 하여야 한다. 헌법재판소에서 법률의 위헌결정,
        탄핵의 결정, 정당해산의 결정 또는 헌법소원에 관한 인용결정을 할 때에는 재판관 6인 이상의
        찬성이 있어야 한다. 국채를 모집하거나 예산외에 국가의 부담이 될 계약을 체결하려 할 때에는
        정부는 미리 국회의 의결을 얻어야 한다. 대통령으로 선거될 는 공공복리에 적합하도록 하여야
        한다. 헌법재판소에서 법률의 위헌결정, 탄핵의 결정, 정당해산의 결정 또는 헌법소원에 관한
        인용결정을 할 때에는 재판관 6인 이상의 찬성이 있어야 한다. 국채를 모집하거나 예산외에 국가의
        부담이 될 계약을 체결하려 할 때에는 정부는 미리 국회의 의결을 얻어야 한다. 대통령으로 선거될
      </div>
      <div className="make-scrollable"></div>
      <div className="input_box">
        <input type="text" placeholder="text input" />
      </div>
    </div>
  );
}
