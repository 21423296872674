export default class IndexedDB {
  #dbName;
  #dbVersion;
  constructor() {
    if (!IndexedDB.instance) {
      this.db = null;
      this.#dbName = 'medistaff_mschat_db';
      this.#dbVersion = 6;
      this.initializeDatabase();
      IndexedDB.instance = this;
    }
    return IndexedDB.instance;
  }

  static instance = null;
  static getInstance() {
    if (!IndexedDB.instance) {
      IndexedDB.instance = new IndexedDB();
    }
    return IndexedDB.instance;
  }

  async initializeDatabase() {
    if (!this.db) {
      this.db = await this.openDatabase();
    }
    // console.log('db', this.db);
    return this.db;
  }

  // 데이터베이스를 생성하고 인스턴스 변수에 할당
  async openDatabase() {
    return new Promise((resolve, reject) => {
      // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
      let indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;

      let request = indexedDB.open(this.#dbName, this.#dbVersion);

      request.onerror = (event) => {
        reject(event.target.error);
      };

      // 버전이 변경될때마다 실행(매번 실행 X)
      request.onupgradeneeded = (event) => {
        const database = event.target.result;

        console.log(
          `indexedDB old version : ${event.oldVersion} / new version : ${event.newVersion}`,
        );

        if (event.oldVersion < 6) {
          // [room_info_idx, mschat_room_idx, user_idx, last_uuid]
          let room_info_store_create = database.createObjectStore('room_info_store', {
            keyPath: 'room_info_idx',
            autoIncrement: true,
          });
          room_info_store_create.createIndex('ind_ris_mri_ui', ['mschat_room_idx', 'user_idx'], {
            unique: true,
          });
        }

        if (event.oldVersion < 5) {
          // [user_key_idx, user_idx, key_type, public_key, enc_private_key]
          let user_key_store_create = database.createObjectStore('user_key_store', {
            keyPath: 'user_key_idx',
            autoIncrement: true,
          });

          // [x3dh_key_idx, user_idx, mschat_room_idx, main_chain_key_hash, is_sender, enc_main_chain_key, x3dh_create_t]
          let x3dh_key_store_create = database.createObjectStore('x3dh_key_store', {
            keyPath: 'x3dh_key_idx',
            autoIncrement: true,
          });

          // [dh_link_idx, user_idx, mschat_room_idx, main_chain_key_hash, dh_chain_ind, is_sending, user_key_idx, partner_public_key, enc_prev_link_key, enc_link_key, enc_output_key]
          let dh_link_store_create = database.createObjectStore('dh_link_store', {
            keyPath: 'dh_link_idx',
            autoIncrement: true,
          });
          // [kdf_link_idx, user_idx, mschat_room_idx, main_chain_key_hash, dh_chain_ind, kdf_chain_ind, enc_prev_link_key, enc_link_key, enc_output_key]
          let kdf_link_store_create = database.createObjectStore('kdf_link_store', {
            keyPath: 'kdf_link_idx',
            autoIncrement: true,
          });
          // [message_idx, client_message_id, mschat_message_idx, user_idx, mschat_room_idx, message_type, main_chain_key_hash, dh_chain_ind, kdf_chain_ind, encrypted_message, file_path, file_type, tag, nonce, system_message, message_send_t, message_status, message_warning, is_delete]
          // message_status: PLANE, GRAY, BLUE, RED, YELLOW
          let message_store_create = database.createObjectStore('message_store', {
            keyPath: 'message_idx',
            autoIncrement: true,
          });

          user_key_store_create.createIndex('ind_uks_uki_ui', ['user_key_idx', 'user_idx'], {
            unique: false,
          });
          user_key_store_create.createIndex('ind_uks_ui_kt', ['user_idx', 'key_type'], {
            unique: false,
          });
          user_key_store_create.createIndex(
            'ind_uks_uki_ui_kt',
            ['user_key_idx', 'user_idx', 'key_type'],
            {unique: true},
          );
          user_key_store_create.createIndex(
            'ind_uks_uki_ui_kt_pk',
            ['user_key_idx', 'user_idx', 'key_type', 'public_key'],
            {unique: true},
          );
          x3dh_key_store_create.createIndex('ind_xks_ui_mri', ['user_idx', 'mschat_room_idx'], {
            unique: false,
          });
          x3dh_key_store_create.createIndex(
            'ind_xks_ui_mri_mch',
            ['user_idx', 'mschat_room_idx', 'main_chain_key_hash'],
            {unique: true},
          );
          dh_link_store_create.createIndex(
            'ind_dls_ui_mri_mckh',
            ['user_idx', 'mschat_room_idx', 'main_chain_key_hash'],
            {unique: false},
          );
          dh_link_store_create.createIndex(
            'ind_dls_ui_mri_mckh_dci',
            ['user_idx', 'mschat_room_idx', 'main_chain_key_hash', 'dh_chain_ind'],
            {unique: true},
          );
          kdf_link_store_create.createIndex(
            'ind_kls_ui_mri_mckh_dci',
            ['user_idx', 'mschat_room_idx', 'main_chain_key_hash', 'dh_chain_ind'],
            {unique: false},
          );
          kdf_link_store_create.createIndex(
            'ind_kls_ui_mri_mckh_dci_kci',
            ['user_idx', 'mschat_room_idx', 'main_chain_key_hash', 'dh_chain_ind', 'kdf_chain_ind'],
            {unique: true},
          );
          message_store_create.createIndex(
            'ind_ms_ui_mri_mckh_dci_kci',
            ['user_idx', 'mschat_room_idx', 'main_chain_key_hash', 'dh_chain_ind', 'kdf_chain_ind'],
            {unique: true},
          );
          message_store_create.createIndex(
            'ind_ms_ui_mri_mmi',
            ['user_idx', 'mschat_room_idx', 'mschat_message_idx'],
            {unique: true},
          );
          message_store_create.createIndex('ind_ms_ui_mri_mst', [
            'user_idx',
            'mschat_room_idx',
            'message_send_t',
          ]);

          message_store_create.createIndex('ind_ms_ui_mri_ms_mst', [
            'user_idx',
            'mschat_room_idx',
            'message_status',
            'message_send_t',
          ]);

          message_store_create.createIndex('ind_ms_ui_mri_cmi', [
            'user_idx',
            'mschat_room_idx',
            'client_message_id',
          ]);
        }
      };
      request.onsuccess = (event) => {
        const database = event.target.result;
        // console.log('데이터 베이스가 생성되었습니다.');
        resolve(database);
      };
    });
  }

  get_room_uuid(mschat_room_idx, user_idx) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('room_info_store', 'readwrite')
        .objectStore('room_info_store')
        .index('ind_ris_mri_ui');
      const db_request = db_index.get([mschat_room_idx, user_idx]);
      db_request.onsuccess = (event) => {
        if (db_request.result) {
          resolve(db_request.result.uuid);
        } else {
          resolve(null);
        }
      };
    });
  }

  put_room_uuid(mschat_room_idx, user_idx, uuid) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('room_info_store', 'readwrite')
        .objectStore('room_info_store')
        .put({mschat_room_idx: mschat_room_idx, user_idx: user_idx, uuid: uuid});
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_user_key_by_idx(user_key_idx, user_idx) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('user_key_store', 'readwrite')
        .objectStore('user_key_store')
        .index('ind_uks_uki_ui');
      const db_request = db_index.get([user_key_idx, user_idx]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_user_key(user_key_idx, user_idx, key_type) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('user_key_store', 'readwrite')
        .objectStore('user_key_store')
        .index('ind_uks_uki_ui_kt');
      const db_request = db_index.get([user_key_idx, user_idx, key_type]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_user_key_with_public_key(user_key_idx, user_idx, key_type, public_key) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('user_key_store', 'readwrite')
        .objectStore('user_key_store')
        .index('ind_uks_uki_ui_kt_pk');
      const db_request = db_index.get([user_key_idx, user_idx, key_type, public_key]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_user_keys(user_idx, key_type) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('user_key_store', 'readwrite')
        .objectStore('user_key_store')
        .index('ind_uks_ui_kt');
      const db_request = db_index.openCursor([user_idx, key_type], 'prev');
      db_request.onsuccess = (event) => {
        if (db_request.result) {
          resolve(db_request.result.value);
        } else {
          resolve(null);
        }
      };
    });
  }

  put_user_key(key_info) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('user_key_store', 'readwrite')
        .objectStore('user_key_store')
        .put(key_info);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_last_x3dh_key(user_idx, mschat_room_idx) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('x3dh_key_store', 'readwrite')
        .objectStore('x3dh_key_store')
        .index('ind_xks_ui_mri');
      const db_request = db_index.openCursor([user_idx, mschat_room_idx], 'prev');
      db_request.onsuccess = (event) => {
        if (db_request.result) {
          resolve(db_request.result.value);
        } else {
          resolve(null);
        }
      };
    });
  }

  get_x3dh_key(user_idx, mschat_room_idx, main_chain_key_hash) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('x3dh_key_store', 'readwrite')
        .objectStore('x3dh_key_store')
        .index('ind_xks_ui_mri_mch');
      const db_request = db_index.get([user_idx, mschat_room_idx, main_chain_key_hash]);
      db_request.onsuccess = (event) => {
        if (db_request.result) {
          resolve(db_request.result);
        } else {
          resolve(null);
        }
      };
    });
  }

  add_x3dh_key(key_info) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('x3dh_key_store', 'readwrite')
        .objectStore('x3dh_key_store')
        .add(key_info);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_last_dh_link(user_idx, mschat_room_idx, main_chain_key_hash) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('dh_link_store', 'readwrite')
        .objectStore('dh_link_store')
        .index('ind_dls_ui_mri_mckh');
      const db_request = db_index.openCursor(
        [user_idx, mschat_room_idx, main_chain_key_hash],
        'prev',
      );
      db_request.onsuccess = (event) => {
        if (db_request.result) {
          resolve(db_request.result.value);
        } else {
          resolve(null);
        }
      };
    });
  }

  get_dh_link(user_idx, mschat_room_idx, main_chain_key_hash, dh_chain_ind) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('dh_link_store', 'readwrite')
        .objectStore('dh_link_store')
        .index('ind_dls_ui_mri_mckh_dci');
      const db_request = db_index.get([
        user_idx,
        mschat_room_idx,
        main_chain_key_hash,
        dh_chain_ind,
      ]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_dh_link_by_idx(dh_link_idx) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('dh_link_store', 'readwrite')
        .objectStore('dh_link_store')
        .get(dh_link_idx);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  add_dh_link(key_info) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('dh_link_store', 'readwrite')
        .objectStore('dh_link_store')
        .add(key_info);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_last_kdf_link(user_idx, mschat_room_idx, main_chain_key_hash, dh_chain_ind) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('kdf_link_store', 'readwrite')
        .objectStore('kdf_link_store')
        .index('ind_kls_ui_mri_mckh_dci');
      const db_request = db_index.openCursor(
        [user_idx, mschat_room_idx, main_chain_key_hash, dh_chain_ind],
        'prev',
      );
      db_request.onsuccess = (event) => {
        if (db_request.result) {
          resolve(db_request.result.value);
        } else {
          resolve(null);
        }
      };
    });
  }

  get_kdf_link_by_idx(kdf_link_idx) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('kdf_link_store', 'readwrite')
        .objectStore('kdf_link_store')
        .get(kdf_link_idx);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  put_kdf_link(key_info) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('kdf_link_store', 'readwrite')
        .objectStore('kdf_link_store')
        .put(key_info);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_kdf_link(user_idx, mschat_room_idx, main_chain_key_hash, dh_chain_ind, kdf_chain_ind) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('kdf_link_store', 'readwrite')
        .objectStore('kdf_link_store')
        .index('ind_kls_ui_mri_mckh_dci_kci');
      const db_request = db_index.get([
        user_idx,
        mschat_room_idx,
        main_chain_key_hash,
        dh_chain_ind,
        kdf_chain_ind,
      ]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  // ?? 마지막으로 읽은 시간 ???
  async get_last_read_t(user_idx, mschat_room_idx) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_mst');
      const db_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, -Infinity],
        [user_idx, mschat_room_idx, Infinity],
        false,
        false,
      );

      const db_request = db_index.openCursor(db_range, 'prev');
      db_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          resolve(cursor.value.message_send_t);
        } else {
          resolve(null);
        }
      };
    });
  }

  get_message_by_index(
    user_idx,
    mschat_room_idx,
    main_chain_key_hash,
    dh_chain_ind,
    kdf_chain_ind,
  ) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_mckh_dci_kci');
      const db_request = db_index.get([
        user_idx,
        mschat_room_idx,
        main_chain_key_hash,
        dh_chain_ind,
        kdf_chain_ind,
      ]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_lost_messages(user_idx, mschat_room_idx) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_ms_mst');
      const db_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, 'PLANE', -Infinity],
        [user_idx, mschat_room_idx, 'PLANE', Infinity],
      );
      const db_request = db_index.openCursor(db_range, 'prev');
      const results = [];
      db_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor && results.length < 30) {
          results.push(cursor.value.client_message_id);
          cursor.continue();
        } else {
          resolve(results);
        }
      };
    });
  }

  get_message_by_idx(user_idx, mschat_room_idx, mschat_message_idx) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_mmi');
      const db_request = db_index.get([user_idx, mschat_room_idx, mschat_message_idx]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_message_by_cmi(user_idx, mschat_room_idx, client_message_id) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_cmi');
      const db_request = db_index.get([user_idx, mschat_room_idx, client_message_id]);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  get_messages_batch(user_idx, mschat_room_idx, message_send_t) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_mst');
      if (!message_send_t) {
        message_send_t = '3000-02-19 10:10:10';
      }
      const db_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, -Infinity],
        [user_idx, mschat_room_idx, message_send_t],
        false,
        true,
      );
      const db_request = db_index.openCursor(db_range, 'prev');
      const results = [];
      db_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor && results.length < 30) {
          results.push(cursor.value);
          cursor.continue();
        } else {
          resolve(results);
        }
      };
    });
  }

  async get_messages_to_read(user_idx, mschat_room_idx, message_read_t) {
    return new Promise((resolve, reject) => {
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_ms_mst');
      const db_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, 'GRAY', -Infinity],
        [user_idx, mschat_room_idx, 'GRAY', message_read_t],
        true,
        false,
      );

      const db_request = db_index.openCursor(db_range);
      const results = [];
      db_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          results.push(cursor.value);
          cursor.continue();
        } else {
          resolve(results);
        }
      };
    });
  }

  async put_message(message) {
    return new Promise((resolve, reject) => {
      const db_request = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .put(message);
      db_request.onsuccess = (event) => {
        resolve(db_request.result);
      };
    });
  }

  // 모든 메시지 삭제
  async delete_messages(user_idx, mschat_room_idx) {
    return new Promise((resolve, reject) => {
      let delete_count = 0;
      const db_index = this.db
        .transaction('message_store', 'readwrite')
        .objectStore('message_store')
        .index('ind_ms_ui_mri_mst');
      const db_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, -Infinity],
        [user_idx, mschat_room_idx, Infinity],
        false,
        false,
      );

      const db_request = db_index.openCursor(db_range);
      db_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          cursor.delete();
          delete_count += 1;
          cursor.continue();
        } else {
          resolve(delete_count);
        }
      };
    });
  }

  // 방 삭제
  async destroy_room(user_idx, mschat_room_idx) {
    console.log(typeof user_idx, typeof mschat_room_idx);
    // 메시지 삭제
    // console.log('Destroy user(' + user_idx + ') room(' + mschat_room_idx + ')');
    let delete_message_count = await this.delete_messages(user_idx, mschat_room_idx);
    // console.log('Destroy ' + delete_message_count + ' messages');

    // kdf 삭제
    let delete_kdf_count = await new Promise((resolve, reject) => {
      let delete_count = 0;
      const db_kdf_index = this.db
        .transaction('kdf_link_store', 'readwrite')
        .objectStore('kdf_link_store')
        .index('ind_kls_ui_mri_mckh_dci');
      const db_kdf_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, '', -Infinity],
        [user_idx, mschat_room_idx, '\uffff', Infinity],
        false,
        false,
      );
      const db_kdf_request = db_kdf_index.openCursor(db_kdf_range);
      db_kdf_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          cursor.delete();
          delete_count += 1;
          cursor.continue();
        } else {
          resolve(delete_count);
        }
      };
    });
    // console.log('Destroy ' + delete_kdf_count + ' kdf links');

    // dh 삭제
    let delete_dh_count = await new Promise((resolve, reject) => {
      let delete_count = 0;
      const db_dh_index = this.db
        .transaction('dh_link_store', 'readwrite')
        .objectStore('dh_link_store')
        .index('ind_dls_ui_mri_mckh');
      const db_dh_range = IDBKeyRange.bound(
        [user_idx, mschat_room_idx, ''],
        [user_idx, mschat_room_idx, '\uffff'],
        false,
        false,
      );
      const db_dh_request = db_dh_index.openCursor(db_dh_range);
      db_dh_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          cursor.delete();
          delete_count += 1;
          cursor.continue();
        } else {
          resolve(delete_count);
        }
      };
    });
    // console.log('Destroy ' + delete_dh_count + ' dh links');

    // x3dh 삭제
    let delete_x3dh_count = await new Promise((resolve, reject) => {
      let delete_count = 0;
      const db_x3dh_index = this.db
        .transaction('x3dh_key_store', 'readwrite')
        .objectStore('x3dh_key_store')
        .index('ind_xks_ui_mri');
      const db_x3dh_request = db_x3dh_index.openCursor([user_idx, mschat_room_idx]);
      db_x3dh_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          cursor.delete();
          delete_count += 1;
          cursor.continue();
        } else {
          resolve(delete_count);
        }
      };
    });
    // console.log('Destroy ' + delete_x3dh_count + ' x3dh');
  }

  async delete_old_data() {
    let room_date_map = await new Promise((resolve, reject) => {
      let room_date_map = {};
      const db_store = this.db
        .transaction('x3dh_key_store', 'readwrite')
        .objectStore('x3dh_key_store');
      const db_request = db_store.openCursor(null, 'prev');
      db_request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          if (!(cursor.value.mschat_room_idx in room_date_map)) {
            room_date_map[cursor.value.mschat_room_idx] = {
              x3dh_create_t: cursor.value.x3dh_create_t,
              user_idx: cursor.value.user_idx,
            };
          }
          cursor.continue();
        } else {
          resolve(room_date_map);
        }
      };
    });
    // 30일 전 시간
    const MONTH_IN_MS = 1000 * 60 * 60 * 24 * 30;
    const expire_timestamp = Date.now() - MONTH_IN_MS;
    for (let mschat_room_idx in room_date_map) {
      if (room_date_map[mschat_room_idx].x3dh_create_t < expire_timestamp) {
        await this.destroy_room(room_date_map[mschat_room_idx].user_idx, Number(mschat_room_idx));
      }
    }
  }
}
