import React from 'react';
import './code_status.scss';
export default function Loading({loadingType}) {
  // =================================================
  return (
    <article className={`C_ChatView C_Loading ${loadingType}`}>
      <img
        className="error_img"
        src={process.env.PUBLIC_URL + '/image/loading.gif'}
        alt="loading"
        style={{width: '100px'}}
      />
      로딩중입니다
    </article>
  );
}
