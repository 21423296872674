import React from 'react';
import {useSelector} from 'react-redux';
import {produce} from 'immer';
import {ReactComponent as HumanIcon} from 'image/icon/human.svg';

function CIOpponentProfile({setProfileData}) {
  const user = useSelector((state) => state.user);
  function hasOpponent(user) {
    return user.roomInfo.room_members.find((item) => item.is_me === 'n');
  }

  if (hasOpponent(user)) {
    return (
      <div className="left_box">
        <img
          onClick={() => {
            setProfileData((prev) =>
              produce(prev, (draft) => {
                draft.isOpen = !prev.isOpen;
                draft.isMe = false;
              }),
            );
          }}
          src={`${process.env.REACT_APP_MEDISTAFF_APP_SERVER}${
            user.roomInfo?.room_members.find((item) => item.is_me === 'n').user_profile_thumb_url
          }`}
          alt=""
        />
      </div>
    );
  } else {
    return (
      <div className="left_box">
        <HumanIcon />
      </div>
    );
  }
}
export default React.memo(CIOpponentProfile);
