import React, {useEffect} from 'react';
import makeSection from '../../utils/chat';
import './chat.scss';
import ChatItem from './item/ChatItem';
import {Scrollbars} from 'react-custom-scrollbars-2';
import ChatExpireTime from './ChatExpireTime';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {ReactComponent as ArrowBottomIcon} from 'image/icon/arrow_bottom.svg';
import {isScrolledToBottom} from '../../utils/Functions';
import {modalActions} from '../../modules/modal';
import classnames from 'classnames';

export default function ChatList({scrollbarRef, onScroll, setOpenImageData, setProfileData}) {
  const chat = useSelector((state) => state.chat);
  const user = useSelector((state) => state.user);
  const chatSections = makeSection(chat.chats);
  const dispatch = useDispatch();
  function showNewChatToast() {
    if (!chat.newChat) return;
    if (isScrolledToBottom(scrollbarRef)) {
      return;
    }
    toast.dismiss();
    dispatch(
      modalActions.setBlackLineModal({
        content: (
          <div className="message_item" onClick={() => scrollbarRef.current.scrollToBottom()}>
            <img
              src={`${process.env.REACT_APP_MEDISTAFF_APP_SERVER}${user.memberData?.you.user_profile_thumb_url}`}
              alt="profile"
            />
            <span className="name">{user.memberData?.you.user_name}</span>
            <span className="text">
              {Object.keys(chat.newChat).includes('file_type') && chat.newChat.file_type !== 'NONE'
                ? chat.newChat.file_type === 'PHOTO'
                  ? '사진을 보냈습니다.'
                  : '파일을 보냈습니다.'
                : chat.newChat.message}
            </span>
            <span className="icon">
              <ArrowBottomIcon />
            </span>
          </div>
        ),
        zIndex: 1,
      }),
    );
  }
  useEffect(() => {
    showNewChatToast();
  }, [chat.newChat]);
  // ==================================================

  return (
    <section className={classnames('C_ChatList')}>
      {!!user.roomInfo && <ChatExpireTime roomInfoData={user.roomInfo} />}
      <Scrollbars autoHide={true} ref={scrollbarRef} onScrollFrame={onScroll}>
        <div className="body">
          {Object.entries(chatSections).map(([date, chats]) => {
            return (
              <section className={`section-${date} old`} key={date}>
                <div className="date_box">
                  <div className="line"></div>
                  <div className="chat_send_date">{date}</div>
                  <div className="line"></div>
                </div>

                {chats.map((chatItem) => {
                  if (Object.keys(chatItem).includes('icon_type') === 'un_read_message') {
                    scrollbarRef.current.scrollToBottom();
                  }
                  return (
                    <ChatItem
                      key={chatItem.message_idx}
                      item={chatItem}
                      setOpenImageData={setOpenImageData}
                      setProfileData={setProfileData}
                      scrollbarRef={scrollbarRef}
                    />
                  );
                })}
              </section>
            );
          })}
        </div>
      </Scrollbars>
    </section>
  );
}
