import React from 'react';
import {ReactComponent as MessageDeleteIcon} from 'image/icon/message_delete.svg';
import {ReactComponent as MessageReSendIcon} from 'image/icon/message_resend.svg';
import {ReactComponent as CopyIcon} from 'image/icon/message_copy.svg';
import IndexedDB from 'class/IndexedDB';
import {useOutletContext} from 'react-router-dom';
import {chatActions} from 'modules/chat';
import {useDispatch, useSelector} from 'react-redux';
import {Token} from '../../../class/Token';
import useSocket from '../../../hook/useSocket';
import {useQuery} from 'react-query';
import {Api} from '../../../api/api';
import {modalActions} from '../../../modules/modal';
function MessageOnClickModal({item}) {
  const dispatch = useDispatch();
  const {visaCheckData} = useOutletContext();
  const dbInstance = IndexedDB.getInstance();
  const tokenClass = new Token();
  const user = useSelector((state) => state.user);
  const {socketSend} = useSocket('stompchat');
  const {data: requestSessionData} = useQuery(
    ['requestSession', visaCheckData],
    () => Api.requestSession(visaCheckData, user.password),
    {
      enabled: !!visaCheckData && !!user.password,
    },
  );
  async function onDelete(e) {
    dispatch(modalActions.deleteMessageEvent(e));
    e.stopPropagation();
    // UI에서 삭제
    dispatch(chatActions.deleteChat(item.data.mschat_message_idx));

    //  TODO : DB에서 삭제하기 전에 마지막 메시지가 is_sending이 true이고 해당하는
    // kdf_chaind_ind가 있을 경우 한번 kdf 링크를 돌리기

    // 먼저 DB에서 삭제
    let message = await dbInstance.get_message_by_idx(
      visaCheckData['user_idx'],
      visaCheckData['mschat_room_idx'],
      item.data.mschat_message_idx,
    );
    message.is_delete = true;
    await dbInstance.put_message(message);
  }
  const onReSendMessage = async (e) => {
    dispatch(modalActions.deleteMessageEvent(e));
    tokenClass.sendMessage(visaCheckData, socketSend, requestSessionData, item.data);
  };

  // 클릭한 메시지 복사
  const onCopyMessage = async (e) => {
    const tempInput = document.createElement('input');
    tempInput.value = item.data.message;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };
  if (item.type === 'delete')
    return (
      <div className="item delete" onClick={(e) => onDelete(e)}>
        <span className="text">삭제</span>
        <MessageDeleteIcon />
      </div>
    );

  if (item.type === 're-send')
    return (
      <div className="item" onClick={(e) => onReSendMessage(e)}>
        <span className="text">재전송</span>
        <MessageReSendIcon />
      </div>
    );

  if (item.type === 'copy')
    return (
      <div className="item" onClick={(e) => onCopyMessage(e)}>
        <span className="text">복사</span>
        <CopyIcon />
      </div>
    );
  // =================================================
  return <div></div>;
}
export default React.memo(MessageOnClickModal);
