import React, {useCallback, useEffect, useRef, useState} from 'react';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import ChatSend from './ChatSend';
import useSocket from '../../hook/useSocket';
import {useOutletContext} from 'react-router-dom';
import {Token} from 'class/Token';
import {
  calculate_signature,
  decrypt_key,
  decrypt_message,
  dh_ratchet,
  encrypt_file,
  encrypt_key,
  encrypt_message,
  generate_key,
  kdf_ratchet,
} from 'utils/ChatCryptoFunctions';
import {asyncDelay, getFileType, isEmptyText, isScrolledToBottom} from '../../utils/Functions';
import {Api} from '../../api/api';
import {toast} from 'react-toastify';
import ImageModal from 'component/modal/ImageModal';
import Setting from 'page/setting/Setting';
import {useDispatch, useSelector} from 'react-redux';
import {chatActions} from '../../modules/chat';
import UnReadMessagePercent from './UnReadMessagePercent';
import FullMessage from './item/FullMessage';
import classnames from 'classnames';
import IndexedDB from 'class/IndexedDB';
import {ReactComponent as ArrowBottomIcon} from '../../image/icon/arrow_bottom.svg';
import {modalActions} from '../../modules/modal';
import MessageOnClickModal from './item/MessageOnClickModal';

export default function ChatView() {
  // console.log('ChatView 렌더링');
  const dbInstance = IndexedDB.getInstance();
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chat);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [openImageData, setOpenImageData] = useState({
    url: null,
    extension: null,
  });
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const inputMessageRef = useRef(null);
  const inputFileRef = useRef(null);
  const tokenClass = new Token(user.token);
  const {visaCheckData, setProfileData} = useOutletContext();
  const {socketConnect, socketSend, disConnectSocket} = useSocket('stompchat');
  const [isReachingEnd, setIsReachingEnd] = useState(false);
  const scrollbarRef = useRef(null);
  const goScrollBottom = useCallback(() => {
    scrollbarRef?.current?.scrollToBottom();
    // console.log('scrollbarRef.current가 변경됩니다.');
  }, [scrollbarRef]);
  window.scroll = scrollbarRef.current;

  useEffect(() => {
    if (!user.token && !user.roomInfo) return;
    goSocketConnect();
    async function goSocketConnect() {
      console.log('웹소켓을 연결합니다.');
      // 이전 메시지들 가져오기
      await loadMessageInDB();
      goScrollBottom();
      if (socketConnect && user.token && user.roomInfo) {
        socketConnect(visaCheckData);
      }
    }
  }, [user.token]);

  // region ********************* 메시지 보내기 기능 ************************
  const [tempMessage, setTempMessage] = useState([]);
  window.tempMessage = tempMessage;
  useEffect(() => {
    if (tempMessage.length === 0) return;
    async function checkX3dhAndSend() {
      let cur_x3dh_key_info = await dbInstance.get_last_x3dh_key(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
      );
      if (!cur_x3dh_key_info) {
        return;
      }
      for (let messageItem of tempMessage) {
        if (messageItem) sendMessage(messageItem);
      }
      setTempMessage([]);
    }
    checkX3dhAndSend();
  }, [dbInstance, tempMessage]);

  async function sendMessage(_files = []) {
    let currentMessage = null;
    let cur_x3dh_key_info = await checkHandShack();
    if (cur_x3dh_key_info === null) return;

    //  ****** 3. optimisticUI를 위한 작업
    let client_message_id = crypto.randomUUID();

    let message_type;
    // let files = document.getElementById('file_input').files;
    let file_path;

    if ((_files.length === 1 && _files[0] instanceof File) || _files[0] instanceof Blob) {
      message_type = 'FILE';
      currentMessage = await handleFileMessage(_files);
    } else {
      message_type = 'MESSAGE';
      currentMessage = inputMessageRef.current;
      inputMessageRef.current = '';
      if (isEmptyText(currentMessage)) {
        // toast.dark('빈 메시지를 보낼 수 없습니다.', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        //   toastId: 'notEmptyMessage',
        // });
        return;
      }
    }

    // DH와 KDF를 필요에 의해 새로 굴리거나 이미 굴린것을 꺼낸다.
    let dh_link = await getDhLink(cur_x3dh_key_info);
    const {kdf_link, message_key} = await getKdfLink(dh_link);

    // KDF Link의 결과값으로 암호화
    let enc_message = await encrypt_message(
      currentMessage,
      message_key,
      dh_link.dh_chain_ind,
      kdf_link.kdf_chain_ind,
    );
    enc_message.user_idx = visaCheckData['user_idx'];
    enc_message.mschat_room_idx = visaCheckData['mschat_room_idx'];
    enc_message.main_chain_key_hash = cur_x3dh_key_info.main_chain_key_hash;
    enc_message.dh_chain_ind = dh_link.dh_chain_ind;
    enc_message.kdf_chain_ind = kdf_link.kdf_chain_ind;
    enc_message.message_type = message_type;
    enc_message.message_status = 'PLANE';

    // 파일 메시지일 경우 특수 파라미터 입력
    if (message_type === 'FILE') {
      enc_message.file_type = getFileType(_files); // PHOTO, OTHER
      enc_message.file_path = file_path;

      // dbInstance에 메시지 넣기
      const message_idx = await dbInstance.put_message(enc_message);
      dispatch(
        chatActions.sendMessage({
          client_message_id: client_message_id,
          message_type: 'FILE',
          message_status: 'PLANE',
          message_send_t: new Date(),
          is_sending: true,
          message: currentMessage,
          file_type: getFileType(_files),
          file_path,
          message_idx: message_idx,
        }),
      );
    } else {
      // dbInstance에 메시지 넣기
      const message_idx = await dbInstance.put_message(enc_message);
      dispatch(
        chatActions.sendMessage({
          client_message_id: client_message_id,
          message: currentMessage,
          message_status: 'PLANE',
          message_send_t: new Date(),
          is_sending: true,
          message_idx: message_idx,
        }),
      );
    }

    // 모든 메시지는 내 IK로 서명을 해야한다.
    let dbInstance_user_key_ik = await dbInstance.get_user_keys(visaCheckData['user_idx'], 'IK');
    let signature = calculate_signature(
      await decrypt_key(
        dbInstance_user_key_ik.enc_private_key,
        visaCheckData['auth_hash'],
        dbInstance_user_key_ik.user_key_idx,
      ),
      enc_message.encrypted_message,
    );

    let user_key_info = await dbInstance.get_user_key_by_idx(
      dh_link.user_key_idx,
      visaCheckData['user_idx'],
    );

    const messageBuilder = buildMessageEnvelope(enc_message, dh_link, user_key_info, signature);
    // 웹소캣으로 메시지 보내기
    socketSend('/mschat/message/', JSON.stringify(messageBuilder));
    goScrollBottom();
    // =======================================================================
    async function checkHandShack() {
      let cur_x3dh_key_info = await dbInstance.get_last_x3dh_key(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
      );

      // [채팅방의 암호키가 생성되었습니다.]
      //  ****** 2. 악수를 진행한 후에 서버에서 암호화키 생성한 후에 메시지를 보낼 수 있음
      if (!cur_x3dh_key_info) {
        console.log('악수를 진행합니다.');
        await tokenClass.handshake(visaCheckData, socketSend, user.requestSession);
        setTempMessage([...tempMessage, currentMessage]);
        return false;
      }
      return cur_x3dh_key_info;
    }
    // 파일 암호화 및 S3에 파일 업로드
    async function handleFileMessage(_files) {
      // 파일 암호화용 키 생성
      let file_key = generate_key();
      let hmac_key = generate_key();

      // 파일 암호화. 너무 큰 파일은 현재 지원 못하는 이유
      let file_enc_result = await encrypt_file(file_key, hmac_key, _files[0]);

      // 서버에 업로드 하기 전에 이름과 경로 지정
      let formData = new FormData();
      let today = new Date();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      // 파일 이름은 2/20220711/hash 형태
      file_path =
        visaCheckData['mschat_room_idx'] +
        '/' +
        today.getFullYear() +
        mm +
        dd +
        '/' +
        file_enc_result.hmac_hash;

      formData.append('key', file_path);
      let file_blob = new Blob([file_enc_result.encrypted_file], {
        type: 'application/octet-stream',
      });
      formData.append('file', file_blob);
      formData.append('mschat_room_idx', visaCheckData['mschat_room_idx']);

      // 암호화된 파일 업로드
      let file_upload_result = await tokenClass.uploadFile(formData, user.token);
      if (file_upload_result.Code !== 200) {
        toast.dark('파일 업로드에 실패했습니다.', {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: 'fileUploadFailure',
        });
        return;
      } else {
        console.log('파일 업로드 성공');
      }

      // 실제 메시지에 들어가는건 아래 JSON
      let message_json = {
        file_key: file_key,
        hmac_key: hmac_key,
        nonce: file_enc_result.nonce,
        file_name: _files[0].name,
        file_size: file_blob.size,
      };
      return JSON.stringify(message_json);
    }

    async function getDhLink(cur_x3dh_key_info) {
      // 먼저 dh_link 연산
      let last_dh_link = await dbInstance.get_last_dh_link(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
        cur_x3dh_key_info.main_chain_key_hash,
      );
      // 마지막 dh가 발신용이 아니라면 내가 굴릴 차례라는 뜻이다.
      if (!last_dh_link.is_sending) {
        // 위에 예외가 하나 있는데 악수하고 첫번쨰로 보내는 메시지일 경우다. 아직 상대방이 첫 메시지를 보내기 전이라면 악수하면서 만들어놓은 발신용을 사용해야 한다.
        // 그래서 마지막 수신용 DH에 메시지 들어 있는지 확인한다.
        let last_kdf_link = await dbInstance.get_last_kdf_link(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          cur_x3dh_key_info.main_chain_key_hash,
          last_dh_link.dh_chain_ind,
        );
        let last_message = await dbInstance.get_message_by_index(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          cur_x3dh_key_info.main_chain_key_hash,
          last_dh_link.dh_chain_ind,
          last_kdf_link.kdf_chain_ind,
        );
        // 마지막 dh_link가 받는용인데 kdf_link에 메시지가 없다면 예전에 만든 dh_link를 재활용한다
        if (!last_message) {
          return await dbInstance.get_dh_link(
            visaCheckData['user_idx'],
            visaCheckData['mschat_room_idx'],
            cur_x3dh_key_info.main_chain_key_hash,
            last_dh_link.dh_chain_ind - 1,
          );
          // 마지막 dh_link가 받는용이고 kdf_link가 사용된 적이 있으면 새로운 dh_link를 생성한다
        } else {
          // 일회성 키를 생성하고 dbInstance에 넣는다.
          let dh_ck_key_idx = await Api.insertNextKey(
            visaCheckData['user_idx'],
            user.password,
            'DHCK',
            dbInstance,
          );
          let dbInstance_user_key_dh_ck = await dbInstance.get_user_key(
            dh_ck_key_idx,
            visaCheckData['user_idx'],
            'DHCK',
          );
          // 상대방의 마지막 공개키와 DH 연산을 한다.
          let dh_ratchet_output = await dh_ratchet(
            last_dh_link.partner_public_key,
            await decrypt_key(
              dbInstance_user_key_dh_ck.enc_private_key,
              user.password,
              dbInstance_user_key_dh_ck.user_key_idx,
            ),
            await decrypt_key(last_dh_link.enc_link_key, user.password, last_dh_link.dh_chain_ind),
          );
          // DH Link를 dbInstance에 넣는다.
          let dh_link_idx = await dbInstance.add_dh_link({
            user_idx: visaCheckData['user_idx'],
            mschat_room_idx: visaCheckData['mschat_room_idx'],
            main_chain_key_hash: cur_x3dh_key_info.main_chain_key_hash,
            dh_chain_ind: last_dh_link.dh_chain_ind + 1,
            is_sending: true,
            user_key_idx: dbInstance_user_key_dh_ck.user_key_idx,
            partner_public_key: last_dh_link.partner_public_key,
            enc_prev_link_key: last_dh_link.enc_link_key,
            enc_link_key: await encrypt_key(
              dh_ratchet_output.link_key,
              user.password,
              last_dh_link.dh_chain_ind + 1,
            ),
            enc_output_key: await encrypt_key(
              dh_ratchet_output.output_key,
              user.password,
              last_dh_link.dh_chain_ind + 1,
            ),
          });
          return await dbInstance.get_dh_link_by_idx(dh_link_idx);
        }
      } else {
        return last_dh_link;
      }
    }
    async function getKdfLink(dh_link) {
      let message_key;
      let kdf_link;
      let last_kdf_link = await dbInstance.get_last_kdf_link(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
        cur_x3dh_key_info.main_chain_key_hash,
        dh_link.dh_chain_ind,
      );
      if (last_kdf_link) {
        // 마지막 KDF링크가 사용이 안되었다면 (미리 굴려놓기만 했을 수 있음) 그대로 사용
        let last_message = await dbInstance.get_message_by_index(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          cur_x3dh_key_info.main_chain_key_hash,
          dh_link.dh_chain_ind,
          last_kdf_link.kdf_chain_ind,
        );
        if (!last_message) {
          // 혹시 미리 kdf를 돌려놓고 암호화에 안쓰인 링크가 있다면 사용
          kdf_link = last_kdf_link;
          message_key = await decrypt_key(
            kdf_link.enc_output_key,
            user.password,
            dh_link.dh_chain_ind + '_' + kdf_link.kdf_chain_ind,
          );
        }
      }
      if (!message_key) {
        // 아니면 kdf 한바퀴
        let kdf_link_idx;
        if (last_kdf_link) {
          let last_kdf_link_key = await decrypt_key(
            last_kdf_link.enc_link_key,
            user.password,
            dh_link.dh_chain_ind + '_' + last_kdf_link.kdf_chain_ind,
          );
          let kdf_chain_ind = last_kdf_link.kdf_chain_ind + 1;
          let kdf_ratchet_output = await kdf_ratchet(last_kdf_link_key);
          message_key = kdf_ratchet_output.output_key;
          kdf_link_idx = await dbInstance.put_kdf_link({
            user_idx: visaCheckData['user_idx'],
            mschat_room_idx: visaCheckData['mschat_room_idx'],
            main_chain_key_hash: cur_x3dh_key_info.main_chain_key_hash,
            dh_chain_ind: dh_link.dh_chain_ind,
            kdf_chain_ind: kdf_chain_ind,
            enc_prev_link_key: last_kdf_link.enc_link_key,
            enc_link_key: await encrypt_key(
              kdf_ratchet_output.link_key,
              user.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
            enc_output_key: await encrypt_key(
              kdf_ratchet_output.output_key,
              user.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
          });
        } else {
          let last_kdf_link_key = await decrypt_key(
            dh_link.enc_output_key,
            user.password,
            dh_link.dh_chain_ind,
          );
          let kdf_chain_ind = 0;
          let kdf_ratchet_output = await kdf_ratchet(last_kdf_link_key);
          message_key = kdf_ratchet_output.output_key;
          kdf_link_idx = await dbInstance.put_kdf_link({
            user_idx: visaCheckData['user_idx'],
            mschat_room_idx: visaCheckData['mschat_room_idx'],
            main_chain_key_hash: cur_x3dh_key_info.main_chain_key_hash,
            dh_chain_ind: dh_link.dh_chain_ind,
            kdf_chain_ind: kdf_chain_ind,
            enc_prev_link_key: dh_link.enc_output_key,
            enc_link_key: await encrypt_key(
              kdf_ratchet_output.link_key,
              user.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
            enc_output_key: await encrypt_key(
              kdf_ratchet_output.output_key,
              user.password,
              dh_link.dh_chain_ind + '_' + kdf_chain_ind,
            ),
          });
        }
        kdf_link = await dbInstance.get_kdf_link_by_idx(kdf_link_idx);
      }
      return {kdf_link, message_key};
    }
    // 메시지 봉투 생성
    function buildMessageEnvelope(encMessage, dhLink, userKeyInfo, signature) {
      let message_builder = tokenClass.messageProto;
      message_builder.mschat_room_idx = visaCheckData['mschat_room_idx'];
      message_builder.type = message_type;
      if (message_type === 'FILE') {
        message_builder.file_type = enc_message.file_type;
        message_builder.file_path = file_path;
      }
      message_builder.version = 1;
      message_builder.receipt = 'PENDING';
      message_builder.client_message_id = client_message_id;
      message_builder.cur_main_chain_hash = cur_x3dh_key_info.main_chain_key_hash;
      message_builder.dh_chain_ind = dh_link.dh_chain_ind;
      message_builder.kdf_chain_ind = kdf_link.kdf_chain_ind;
      message_builder.dh_cur_key = {
        id: user_key_info.user_key_idx,
        public_key_hex: user_key_info.public_key,
      };
      message_builder.nonce = enc_message.nonce;
      message_builder.tag = enc_message.tag;
      message_builder.message = enc_message.encrypted_message;
      message_builder.signature = signature;
      return message_builder;
    }
  }
  // endregion ********************* 메시지 보내기 기능 *********************
  // 30개씩 IndexeddbInstance에 저장된 메시지 불러오기
  const chatRef = useRef(null);
  const loadMessageInDB = useCallback(async () => {
    const preload_messages = await dbInstance.get_messages_batch(
      visaCheckData['user_idx'],
      visaCheckData['mschat_room_idx'],
      chatRef.current,
    );

    if (preload_messages.length === 0) {
      console.log('더 이상 불러올 데이터가 없습니다.');
      return null;
    }

    // 반대로 넣기
    let items = [];
    for (let i = preload_messages.length - 1; i >= 0; i--) {
      let item = preload_messages[i];
      // 키교환이나 시스템 메시지일 경우에는 평문으로 저장되어 있으니 그대로 불러오기
      if (['KEY_EXCHANGE', 'SERVER_MESSAGE'].includes(item.message_type)) {
        item.message = item.system_message;
      }

      // 파일이나 메시지일 경우에 KDF Link에 저장되어 있는 암호키 가져와서 메시지 복호화하기
      else if (['FILE', 'MESSAGE'].includes(item.message_type)) {
        let dh_link = await dbInstance.get_dh_link(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          item.main_chain_key_hash,
          item.dh_chain_ind,
        );
        let kdf_link = await dbInstance.get_kdf_link(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
          item.main_chain_key_hash,
          item.dh_chain_ind,
          item.kdf_chain_ind,
        );

        // message_status RED일때(빨간 방패) 메시지를 저장했을 경우 kdf_link가 없는 경우가 있다.
        if (!kdf_link) continue;
        let dec_message = await decrypt_message(
          item.encrypted_message,
          await decrypt_key(
            kdf_link.enc_output_key,
            user.password,
            item.dh_chain_ind + '_' + item.kdf_chain_ind,
          ),
          item.dh_chain_ind,
          item.kdf_chain_ind,
          item.tag,
          item.nonce,
        );
        item.is_sending = dh_link.is_sending;
        item.message = dec_message;
      }
      items.push(item);
    }
    chatRef.current = items[0] && items[0].message_send_t;
    dispatch(chatActions.addChatsFromLoadMessage(items));
    if (preload_messages.length < 30) {
      setIsReachingEnd(true);
    }

    goScrollBottom();
    return 'finish';
  }, [isReachingEnd, chat, user.requestSession['mschat_session_idx']]);
  //region ***************************** 스크롤 *****************************
  const [arrowHtml, setArrowHtml] = useState('');
  const onScroll = useCallback(
    async (values) => {
      // 스크롤이 최상단에 위치할 경우 데이터 새로운 데이터 가져오기
      if (values.scrollTop === 0 && isReachingEnd === false && !!user.roomInfo) {
        await loadMessageInDB();
        // 현재 스크롤 위치 유지(기존의 스크롤 높이 - 이전의 스크롤 높이(스크롤 했을때 직후의 높이))
        scrollbarRef.current?.scrollTop(
          scrollbarRef.current?.getScrollHeight() - values.scrollHeight,
        );
        // 새로운 데이터가 추가된 후에 저장된 스크롤 위치로 복원
      }

      if (isScrolledToBottom(scrollbarRef)) {
        toast.dismiss();
        if (!!chat.newChat) {
          dispatch(chatActions.setNewChat(null));
          dispatch(modalActions.setBlackLineModal({content: null}));
        }
        setArrowHtml('');
      } else if (modal.blackLineModal.content !== null) {
        setArrowHtml(
          <div
            className="arrow_bottom_icon_box bg-black"
            onClick={() => scrollbarRef.current.scrollToBottom()}>
            <ArrowBottomIcon />
          </div>,
        );
      }
    },
    [isReachingEnd, scrollbarRef.current, modal.blackLineModal.content, chat.newChat],
  );

  //endregion ***************************** 스크롤 *****************************
  // =================================================
  return (
    <>
      {!!modal.fullMessage && <FullMessage message={modal.fullMessage} />}
      {/*{chat.unReadMessage && chat.unReadMessage.show === false ? (*/}
      <section className={classnames('C_ChatView', {hidden: !!modal.fullMessage})}>
        {arrowHtml || ''}
        {chat.unReadMessage && chat.unReadMessage.show === true && <UnReadMessagePercent />}
        {modal.messageOnClickData.length !== 0 && (
          <div
            className="C_Modal C_MessageOnClickModal"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(modalActions.deleteMessageEvent(e));
            }}>
            <div className="content">
              {modal.messageOnClickData.map((item, index) => (
                <MessageOnClickModal
                  item={item}
                  key={index}
                  tempMessage={tempMessage}
                  setTempMessage={setTempMessage}
                />
              ))}
            </div>
          </div>
        )}

        <div
          className={classnames(
            'C_Modal C_innerBlackLineModal',
            {show: modal.blackLineModal.content !== null},
            {hidden: modal.blackLineModal.content === null},
          )}
          onClick={(e) => {
            dispatch(modalActions.setBlackLineModal({content: null}));
          }}>
          {modal.blackLineModal.content}
        </div>

        <ChatHeader setIsOpenSetting={setIsOpenSetting} />

        <ChatList
          scrollbarRef={scrollbarRef}
          onScroll={onScroll}
          setOpenImageData={setOpenImageData}
          setProfileData={setProfileData}></ChatList>
        <ChatSend
          tempMessage={tempMessage}
          setTempMessage={setTempMessage}
          inputMessageRef={inputMessageRef}
          inputFileRef={inputFileRef}
          sendMessage={sendMessage}
        />
        <div className="make-scrollable"></div>
        {/*<button type="button" onClick={() => disConnectSocket()}>*/}
        {/*  소켓 끊기 테스트*/}
        {/*</button>*/}
      </section>
      {isOpenSetting && <Setting setIsOpenSetting={setIsOpenSetting} />}

      {!!openImageData.url && (
        <ImageModal setOpenImageData={setOpenImageData} openImageData={openImageData} />
      )}
    </>
  );
}
