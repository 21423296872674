import React from 'react';
import {ReactComponent as CancelIcon} from 'image/icon/ic_cancel_two.svg';
import defaultProfile from 'image/profile_default_two.svg';
import {Token} from 'class/Token';
import {useQuery} from 'react-query';
import {produce} from 'immer';
import {useSelector} from 'react-redux';

export default function ProfileModal({setProfileData, profileData}) {
  const user = useSelector((state) => state.user);
  const tokenApi = new Token(user.token);

  const {data: userProfileData, error} = useQuery(
    ['userProfileData', user.roomInfo, profileData],
    () =>
      tokenApi.getUserProfile(
        {
          user_idx:
            profileData.isMe === true
              ? user.roomInfo?.room_members.find((item) => item.is_me === 'y').user_idx
              : user.roomInfo?.room_members.find((item) => item.is_me === 'n').user_idx,
        },
        user.token,
      ),
    {enabled: !!user.roomInfo},
  );
  window.userProfileData = userProfileData;

  if (!userProfileData || error) return <div>유저 데이터를 가져올 수 없습니다.</div>;
  return (
    <div className="profile_container">
      <div className="cancel_box">
        <div
          className="aaa"
          onClick={() => {
            setProfileData((prev) =>
              produce(prev, (draft) => {
                draft.isOpen = !prev.isOpen;
              }),
            );
          }}>
          <CancelIcon fill="black" />
        </div>
      </div>
      <img
        src={
          !!userProfileData.user_info.friend_profile_url
            ? process.env.REACT_APP_MEDISTAFF_APP_SERVER +
              userProfileData.user_info.friend_profile_url
            : defaultProfile
        }
        alt="profile"
      />
      {/*<div>*/}
      {/*<Scrollbars autoHide={ true} ref={scrollbarRef}>*/}
      <div className="top_box inner_box">
        <div className="dummy_box"></div>
        <div className="name_box">
          <p className="name">{userProfileData.user_info.friend_user_name}</p>
          {userProfileData.user_info.friend_status_msg && (
            <p className="motto">{userProfileData.user_info.friend_status_msg}</p>
          )}
        </div>

        <div className="contour"></div>

        <div className="info_box">
          {userProfileData.user_detail_info_1.map((item, index) => (
            <div className="info_item" key={index}>
              <span className="aaa">{item.name}</span>
              <span className="bbb">{item.value}</span>
            </div>
          ))}
        </div>
      </div>

      {/*      <div className="history_box inner_box">
        <div className="item">
          <div className="top">
            <CareerIcon /> 경력
          </div>
          <div className="bottom">
            <span>메디스태프병원</span>
            <span>협력의사 / 2000 - 2000</span>
          </div>
        </div>

        <div className="item">
          <div className="top">
            <StethoscopeIcon /> 관심질환
          </div>
          <div className="bottom">
            <span>메디스태프병원</span>
            <span>협력의사 / 2000 - 2000</span>
          </div>
        </div>

        <div className="item">
          <div className="top">
            <SeveralIcon /> 복수 전문의
          </div>
          <div className="bottom">
            <span>메디스태프병원</span>
            <span>협력의사 / 2000 - 2000</span>
          </div>
        </div>

        <div className="item">
          <div className="top">
            <AwardIcon /> 수상&업적
          </div>
          <div className="bottom">
            <span>메디스태프병원</span>
            <span>협력의사 / 2000 - 2000</span>
          </div>
        </div>
      </div>*/}
      {/*</Scrollbars>*/}
      {/*</div>*/}
    </div>
  );
}
