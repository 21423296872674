import {useLocation, useNavigate} from 'react-router-dom';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {CommonContext} from '../../context/ComomContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {calculateRemainingTime, extractExpFromJWT} from '../../utils/Functions';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useQueryClient} from 'react-query';
import {modalActions} from '../../modules/modal';

export default function ChatExpireTime({roomInfoData}) {
  window.roomInfoData = roomInfoData;
  const queryClient = useQueryClient();
  dayjs.extend(utc);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const roomUrl = location.pathname.substring(location.pathname.indexOf('/', 1) + 1);
  const isExistOtherPerson = useMemo(
    () => roomInfoData?.room_members.find((item) => item.is_me === 'n') !== undefined,
    [roomInfoData],
  );
  // START ******************* 토큰 만료 시간 ********************
  const [tokenExpireTime, setTokenExpireTime] = useState();
  const updateTokenExpireTime = useCallback(() => {
    if (!user.token) return;
    // 방의 만료시간이 지났을때
    if (calculateRemainingTime(user.token) < 1) {
      console.log('토큰의 만료시간이 지났습니다.');
      toast.dismiss();
      dispatch(modalActions.setBlackLineModal({content: null}));
      navigate(`/password-check/${roomUrl}`);
      return;
    }
    setTokenExpireTime(calculateRemainingTime(user.token));
  }, [user.token]);
  useEffect(() => {
    const id = setInterval(updateTokenExpireTime, 1000);
    return () => clearInterval(id);
  }, [updateTokenExpireTime]);
  // EMD ******************* 토큰 만료 시간 **********************

  // START ******************* 웹 만료 시간 ********************
  const [roomExpireTime, setRoomExpireTime] = useState();
  const updateWebExpireTime = useCallback(() => {
    const currentTime = new Date();
    const expiryTime = new Date(roomInfoData['mschat_expire_t']);
    if (currentTime > expiryTime) {
      console.log('방의 만료시간이 지났습니다.');
      toast.dismiss();
      dispatch(modalActions.setBlackLineModal({content: null}));
      queryClient.invalidateQueries({queryKey: ['visaCheckData', {enc_room_info: roomUrl}]});
      navigate(`/${roomUrl}`);
    }

    setRoomExpireTime(timeExtraction(expiryTime, currentTime));
  }, [roomInfoData, roomUrl]);

  function padZero(number) {
    return number < 10 ? '0' + number : number;
  }

  function timeExtraction(expiryTime, currentTime) {
    // console.log('expiryTime', expiryTime);
    const timeDifference = Math.floor((expiryTime - currentTime) / 1000);
    const hours = padZero(Math.floor(timeDifference / 3600));
    const minutes = padZero(Math.floor((timeDifference % 3600) / 60));
    const seconds = padZero(timeDifference % 60);
    return {hours, minutes, seconds};
  }

  useEffect(() => {
    const id = setInterval(updateWebExpireTime, 1000);
    return () => clearInterval(id);
  }, [updateWebExpireTime]);
  // END ******************* 웹 만료 시간 **********************

  return (
    <div className="chat_expire_time_box">
      {isExistOtherPerson ? (
        <>
          <p className="info">
            채팅 개설 후{' '}
            {roomInfoData['mschat_duration_hours'] > 1 ? roomInfoData['mschat_duration_hours'] : ''}
            시간이 지나면 만료됩니다.
          </p>
          <p className="time">
            (
            {roomExpireTime &&
              `${roomExpireTime.hours}:${roomExpireTime.minutes}:${roomExpireTime.seconds}`}
            남음){' '}
          </p>
        </>
      ) : (
        <div className="info flex flex-col items-center">
          <p>채팅이 불가한 채팅방입니다.</p>
          <p>새로운 채팅방을 만들어 주세요.</p>
        </div>
      )}

      {/* 아래는 토큰의 만료시간 */}
      {/*<div className="time">*/}
      {/*  <p>{tokenExpireTime && dayjs(tokenExpireTime).utc().format('HH:mm:ss')}</p>*/}
      {/*</div>*/}
    </div>
  );
}
