import {createContext} from 'react';

// 현재 open된 modal들을 나타냄
export const ModalStateContext = createContext({
  modalState: {
    // 현재 open된 모달 컴포넌트를 나타내는 state
    openedModals: [],
  },
  actions: {
    setOpenedModals: () => {},
  },
});

// 모달을 열고 닫는 함수
export const ModalDispatchContext = createContext({
  open: () => {},
  close: () => {},
  change: () => {},
  allClose: () => {},
});
