import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {ReactComponent as EncryptionFailed} from 'image/icon/encryption_failed.svg';
import {ReactComponent as EncryptionFailedTwo} from 'image/icon/encryption_failed_two.svg';
import Swal from 'sweetalert2';
import {Token} from '../../../class/Token';
import {useOutletContext} from 'react-router-dom';
import useSocket from '../../../hook/useSocket';
import {useQuery} from 'react-query';
import {Api} from '../../../api/api';

function OpponentRedMessageItem() {
  const tokenClass = new Token();
  const chat = useSelector((state) => state.chat);
  const user = useSelector((state) => state.user);
  const {visaCheckData} = useOutletContext();
  const {socketSend} = useSocket('stompchat');

  const {data: requestSessionData} = useQuery(
    ['requestSession', visaCheckData],
    () => Api.requestSession(visaCheckData, user.password),
    {
      enabled: !!visaCheckData && !!user.password,
    },
  );
  const goHandshake = useCallback(() => {
    try {
      Swal.fire({
        title: '암호키 변경',
        text: '암호키를 변경하시겠습니까? 변경된 암호키는 상단 더보기 메뉴에서 확인이 가능합니다.',
        allowOutsideClick: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '암호키 변경',
      }).then(async (swal) => {
        if (swal.isConfirmed) {
          await tokenClass.handshake(visaCheckData, socketSend, requestSessionData);
        }
      });
    } catch (error) {
      console.error('악수 진행 실패 error', error);
    }
  }, []);
  if (chat.didEncryptionKeyFail === true) {
    return (
      <div className="tob_box red">
        <p className="aaa">암호화가 실패된 메시지 입니다.</p>
        <p className="bbb">보안을 위해 암호키를 변경하세요.</p>
        <div className="ccc cursor-pointer" onClick={goHandshake}>
          <EncryptionFailed className="mr-2" />
          암호키 변경하기
        </div>
      </div>
    );
  } else {
    return (
      <div className="tob_box red">
        <p className="aaa">
          <EncryptionFailedTwo className="mr-2" />
          암호화가 실패된 메시지 입니다.
        </p>
      </div>
    );
  }
}
export default React.memo(OpponentRedMessageItem);
