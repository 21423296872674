export function convertToHex(str) {
  var hex = '';
  for (var i = 0; i < str.length; i++) {
    hex += '' + str.charCodeAt(i).toString(16);
  }
  return hex;
}

export function createSemaphore(isLocked, queue) {
  const lock = () => {
    if (isLocked) {
      return new Promise((resolve) => {
        queue.push(resolve);
      });
    } else {
      isLocked = true;
      return Promise.resolve();
    }
  };

  const unlock = () => {
    if (queue.length > 0) {
      const resolve = queue.shift();
      resolve();
    } else {
      isLocked = false;
    }
  };

  return {lock, unlock};
}

export function convertFromHexToUtf8(hex) {
  return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
}
export function convertFromHex(hex) {
  let hexString = hex.toString(); //force conversion
  let str = '';
  for (let i = 0; i < hexString.length; i += 2)
    str += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
  return str;
}

export function create_qr(qr_content) {
  let a = document.createElement('a');
  a.download = 'QR';
  let img = document.createElement('img');
  let url = `/keyutil/create_qr?qr_content=${qr_content}&app_name=MEDISTAFF`;
  fetch(url).then(function (res) {
    res.blob().then((blob) => (img.src = URL.createObjectURL(blob)));
  });
  a.append(img);
  img.style.width = '300px';
  return a;
}

// TODO: 수정 필요
export function download_file(mschat_room_idx, file_path, file_type, message) {
  return null;
}

export function appStoreClick(_type) {
  if (_type === 'ios') window.open(process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE);
  else window.open(process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE);
}

export const getFileType = (files) => {
  if (files.length > 0) {
    const firstFile = files[0];
    const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'];

    if (imageTypes.includes(firstFile.type)) {
      return 'PHOTO';
    } else {
      return 'OTHER';
    }
  }

  // Return a default value or handle the case when there are no files
  return 'OTHER';
};

export const extractFileExtension = (_fileName) => {
  // 파일 이름을 마침표(.)로 분리하고 배열로 반환
  const parts = _fileName.split('.');
  // 배열의 마지막 요소가 확장자이므로 반환
  return parts.length > 1 ? parts.pop() : '';
};

export const isEmptyText = (_text) => {
  if (_text === '' || _text == null) return true;
  else if (_text.trim() === '' || _text.replace('\n', '') === '') {
    return true;
  }
  return false;
};

// JWT 토큰 중 => 1701936710
export function extractExpFromJWT(token) {
  // JWT는 세 부분으로 나누어져 있으며, 각 부분은 점으로 구분되어 있습니다.
  const tokenParts = token.split('.');

  // JWT의 두 번째 부분이 payload입니다.
  const encodedPayload = tokenParts[1];

  // base64 디코딩 후 JSON 파싱{
  //   "jti": "6916",
  //   "iat": 1701925910,
  //   "exp": 1701936710
  // }
  const decodedPayload = JSON.parse(atob(encodedPayload));

  // 'exp' 값을 반환
  const expValue = decodedPayload.exp;

  return expValue * 1000;
}

export function calculateRemainingTime(token) {
  const expTime = extractExpFromJWT(token);
  const currentTime = new Date().getTime();
  const remainingTime = expTime - currentTime;
  return remainingTime;
}

export function getRoomUrl(location) {
  return location.pathname.startsWith('/chat/') ||
    location.pathname.startsWith('/password/') ||
    location.pathname.startsWith('/password-check/')
    ? location.pathname.substring(location.pathname.indexOf('/', 1) + 1)
    : location.pathname.substring(location.pathname.indexOf('/', 0) + 1);
}

export function isScrolledToBottom(scrollbarRef, tolerance = 20) {
  // 스크롤 가능한 전체 영역의 높이
  const scrollHeight = scrollbarRef.current.getScrollHeight();

  // 현재 스크롤 위치
  const currentScrollTop = scrollbarRef.current.getScrollTop();

  // 실제로 보이는 스크롤 컨테이너의 높이
  const clientHeight = scrollbarRef.current.getClientHeight();

  // return scrollHeight - scrollTop === clientHeight
  // 현재 스크롤 위치에서 아래로 tolerance(px) 만큼 여유를 두어 계산
  // console.log(`스크롤 위치 : ${scrollHeight}, ${currentScrollTop}, ${clientHeight}`);
  // console.log('isScrolledToBottom', scrollHeight - currentScrollTop <= clientHeight + tolerance);
  return scrollHeight - currentScrollTop <= clientHeight + tolerance;
}

export function isWeb() {
  // user-agent 문자열을 가져옵니다.
  const userAgent = navigator.userAgent;

  // 모바일 및 태블릿 기기를 나타내는 키워드를 확인합니다.
  const mobileKeywords = ['Mobi', 'Android', 'iOS', 'Tablet', 'IEMobile'];

  // 모바일 기기 키워드가 user-agent에 포함되어 있으면 웹이 아닌 것으로 판단합니다.
  for (const keyword of mobileKeywords) {
    if (userAgent.includes(keyword)) {
      return false;
    }
  }

  // 모바일 기기 키워드가 없으면 웹으로 판단합니다.
  return true;
}

// await asyncSleep(1000);
export function asyncDelay(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}
