import React from 'react';
import './auth.scss';
import {useOutletContext} from 'react-router-dom';
import Error from '../layout/Error';
import SimpleUserLogin from './SimpleUserLogin';
import NormalUserLogin from './NormalUserLogin';

// http://localhost:3000/pop/l0XKhsb1OCMr+SPG1eMDYw==
export default function LoginEntrance() {
  const {visaCheckData} = useOutletContext();
  // =================================================
  if (visaCheckData?.Code === 100) return <Error errorCode={401} />;
  return (
    <section className="C_LoginEntrance">
      {visaCheckData && visaCheckData.user_name ? (
        // 간편 로그인
        <SimpleUserLogin />
      ) : (
        <NormalUserLogin />
      )}
    </section>
  );
}
