import React from 'react';
import {ReactComponent as MedistaffIcon} from 'image/icon/top_medistaff_icon_scroll.svg';

export default function LeftLayout() {
  // =================================================
  return (
    <section className="C_LeftLayout layout_item">
      <MedistaffIcon
        className="cursor-pointer"
        onClick={() => window.open('https://medistaff.co.kr/')}
      />
      <div className="banner_box">
        {/*<img src={`${process.env.PUBLIC_URL}/image/web_left_ad.png`} alt="AD" />*/}
      </div>
    </section>
  );
}
