import React from 'react';

export default function TopAdArea() {
  // =================================================
  return (
    <div className="C_topAdArea">
      {/*<img src={`${process.env.PUBLIC_URL}/image/top_banner.png`} alt="AD" />*/}
    </div>
  );
}
