import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import LeftLayout from './layout/LeftLayout';
import RightLayout from './layout/RightLayout';
import './main.scss';
import {useQuery} from 'react-query';
import {Api} from '../api/api';
import Error from './layout/Error';
import Loading from './layout/Loading';
import TopAdArea from './layout/TopAdArea';
import React, {useState} from 'react';
import {getRoomUrl} from '../utils/Functions';
import ChatError from './layout/ChatError';
import useDynamicViewportHeight from '../hook/useDynamicViewportHeight';

export default function Main() {
  const location = useLocation();
  const roomUrl = getRoomUrl(location);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    isOpen: false,
    isMe: false,
  });
  const [visaCheckError, setVisaCheckError] = useState();
  const vh = useDynamicViewportHeight();
  // ***********************************************************
  const {
    data: visaCheckData,
    error,
    isLoading,
  } = useQuery(
    ['visaCheckData', {enc_room_info: roomUrl}],
    () => Api.visaCheck({enc_room_info: roomUrl}).then((res) => res.data),
    {
      enabled: !!roomUrl && roomUrl !== '/',
      onSuccess: (response) => {
        if (response.Code === 100) {
          setVisaCheckError(response);
        }
      },
    },
  );
  window.visaCheckData = visaCheckData;
  // ====================================================================
  if (isLoading) return <Loading />;
  if (error) return <Error errorCode={404} />;
  if (!roomUrl || roomUrl === '/') return <Error errorCode={404} />;
  return (
    <article className="C_Main" style={{height: `${vh}`}}>
      <LeftLayout />
      <div className="layout_item main_layout" style={{height: `${vh}`}}>
        <TopAdArea />
        {visaCheckError ? (
          <ChatError errorResult={visaCheckError} />
        ) : (
          <Outlet context={{setProfileData, visaCheckData}} />
        )}
      </div>
      <RightLayout
        visaCheckData={visaCheckData}
        setProfileData={setProfileData}
        profileData={profileData}
      />
    </article>
  );
}
