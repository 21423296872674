import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
const initialState = {
  didEncryptionKeyFail: true,
  currentX3dhKeyInfo: null,
  connectedCount: 0,
  chats: [],
  newChat: null,
  unReadMessage: {
    show: false,
    percent: 0,
  },
  socketState: 'close',
  scrollRef: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    encryptionKeyFail(state) {
      state.didEncryptionKeyFail = true;
    },
    // 현재 실시간 사용하고 있는 암호키
    setCurrentX3dhKeyInfo(state, {payload}) {
      state.currentX3dhKeyInfo = payload;

      // 암호키 실패 여부를 true로 변경
      if (state.didEncryptionKeyFail === true) {
        state.didEncryptionKeyFail = false;
      }
    },

    /* region ****************** ChatDat 관련 ****************** */

    // sendMessage 직후 optimisticUI를 위한 chat Data 추가
    // 내가 보낸 메시지
    sendMessage(state, {payload}) {
      state.chats.push(payload);
    },

    // roomInfo.lost_messages 상태 변경
    updateMessages(state, {payload}) {
      for (let i in payload) {
        const message = payload[i];
        // console.log('updateMessages message', message);
        const updateIndex = state.chats.findIndex(
          (item) =>
            item.message_type !== 'SERVER_MESSAGE' &&
            item.message_type !== 'KEY_EXCHANGE' &&
            (item.client_message_id === message.client_message_id ||
              item.mschat_message_idx === message.mschat_messsage_idx),
        );
        // console.log('updateMessages updateIndex', updateIndex);
        if (updateIndex !== -1) {
          state.chats[updateIndex] = {
            ...state.chats[updateIndex],
            ...payload[i],
          };
        }
      }
    },

    addChatsFromLoadMessage(state, {payload}) {
      let sortedArrNew = [];
      let sortedArrA = state.chats;
      let sortedArrB = payload;
      let indexA = 0;
      let indexB = 0;

      while (indexA < sortedArrA.length && indexB < sortedArrB.length) {
        if (sortedArrA[indexA]['message_send_t'] < sortedArrB[indexB]['message_send_t']) {
          sortedArrNew.push(sortedArrA[indexA]);
          indexA++;
        } else {
          sortedArrNew.push(sortedArrB[indexB]);
          indexB++;
        }
      }
      if (indexB < payload.length) {
        sortedArrNew = sortedArrNew.concat(sortedArrB.slice(indexB));
      } else {
        sortedArrNew = sortedArrNew.concat(sortedArrA.slice(indexA));
      }
      state.chats = sortedArrNew;
    },

    // START *********** receiverMessage 이후 가공 ***********
    // 내가 메시지를 보냄 -> 서버로부터 receive message 응답받음 -> chatData 수정 필요(비행기 표시를 회색방패로 변경)
    updateChatByClientMsgId(state, {payload}) {
      const updateIndex = state.chats.findIndex(
        (item) => item.client_message_id === payload.client_message_id,
      );
      if (updateIndex !== -1) {
        state.chats[updateIndex] = {
          ...state.chats[updateIndex],
          ...payload,
        };
      }
    },

    deleteOldUnReadMessageChats(state, {payload}) {
      const index = state.chats.findIndex((item) => item.icon_type === 'un_read_message');
      state.chats.splice(index, 1);
    },

    // 서버로부터 receive message가 올때마다 시간에 맞게 chat에 추가하기
    addChatByTime(state, {payload}) {
      state.chats.splice(_.sortedIndexBy(state.chats, payload, 'message_send_t'), 0, payload);

      if (
        payload.message_type !== 'SERVER_MESSAGE' &&
        payload.message_type !== 'KEY_EXCHANGE' &&
        payload.is_sending === false &&
        !Object.keys(payload).includes('purpose')
      ) {
        state.newChat = payload;
      }
    },

    deleteChats(state) {
      state.chats = [];
    },
    deleteChat(state, {payload}) {
      const chatIndex = state.chats.findIndex((item) => item.mschat_message_idx === payload);

      if (chatIndex !== -1) {
        state.chats[chatIndex].is_delete = true;
      }
    },
    // END *********** receiverMessage 이후 가공 ***********
    setNewChat(state, {payload}) {
      state.newChat = payload;
    },
    /* endregion ****************** ChatDat 관련 ****************** */
    setUnReadMessagePercent(state, {payload}) {
      state.unReadMessage.percent = payload;
    },
    setUnReadMessageShow(state, {payload}) {
      state.unReadMessage.show = payload;
    },
    setSocketState(state, {payload}) {
      state.socketState = payload;
    },
  },
});
export const chatActions = chatSlice.actions;
export default chatSlice.reducer;
