import React from 'react';
import {ReactComponent as AppleAppIcon} from 'image/icon/apple_app_store.svg';
import {ReactComponent as GoogleIcon} from 'image/icon/google_app_store.svg';
import 'page/main.scss';
import {appStoreClick} from '../../utils/Functions';
import ProfileModal from '../../component/modal/ProfileModal';

export default function RightLayout({setProfileData, profileData}) {
  // =================================================
  return (
    <section className="C_RightLayout layout_item">
      {profileData.isOpen === true && (
        <ProfileModal setProfileData={setProfileData} profileData={profileData} />
      )}

      <div className="flex flex-row icon_box">
        <AppleAppIcon className="aaa cursor-pointer" onClick={() => appStoreClick('ios')} />
        <GoogleIcon className="bbb cursor-pointer" onClick={() => appStoreClick('aos')} />
      </div>
    </section>
  );
}
