import React, {useCallback, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {useForm} from 'react-hook-form';
import {Api} from '../../api/api';
import Swal from 'sweetalert2';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';

import {useDispatch} from 'react-redux';
import {userActions} from 'modules/user';
import useSocket from '../../hook/useSocket';
import IndexedDB from '../../class/IndexedDB';
import {useQueryClient} from 'react-query';
export default function PasswordCheck() {
  const {visaCheckData} = useOutletContext();
  const queryClient = useQueryClient();
  const dbInstance = IndexedDB.getInstance();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const roomUrl = location.pathname.substring(location.pathname.indexOf('/', 1) + 1);
  const [isDuplicateLogin, setIsDuplicateLogin] = useState(false);
  const [isOtherBrowserConnection, setIsOtherBrowserConnection] = useState(false);
  const {disConnectSocket} = useSocket('stompchat');
  useEffect(() => {
    disConnectSocket();
    // 사용자가 뒤로가기 버튼을 클릭해서 이 페이지로 이동했을 경우 소켓 끊기
    window.addEventListener('popstate', () => {});
  }, []);

  const fetchData = async () => {
    try {
      // console.log('fetchData를 실행합니다.');
      dispatch(userActions.deleteRoomInfo());
      await queryClient.invalidateQueries({queryKey: ['requestSession', visaCheckData]});
      setIsDuplicateLogin(false);
      setIsOtherBrowserConnection(false);
      dispatch(userActions.setIsOtherBrowserConnection(false));
      const response = await Api.visaCheck({enc_room_info: roomUrl}).then((res) => res.data);
      // console.log('fetchData visaCheck', response);

      if (response.Code !== 200) {
        navigate(`/${roomUrl}`);
        return false;
      }

      // 현재 내방의 uuid 가져오기
      let last_uuid_client = await dbInstance.get_room_uuid(
        response.mschat_room_idx,
        response.user_idx,
      );

      // 처음으로 접속하는 브라우저의 경우
      if (!last_uuid_client) {
        console.log('처음으로 접속하는 브라우저입니다.!');
        last_uuid_client = crypto.randomUUID();
        dbInstance.put_room_uuid(response.mschat_room_idx, response.user_idx, last_uuid_client);
        return 'success';
      }
      if (response.mschat_session_idx != null) {
        console.log('동시로그인을 하시고 계시군요!');
        setIsDuplicateLogin(true);
        showBlockingDuplicateLoginModal();
        return 'fail';
      }
      // 두번 이상 접속하는 브라우저의 경우
      // 서버에 마지막으로 접속한 UUID와 다르다면(즉 다른 환경에서 접속한 경우)
      if (last_uuid_client !== response.last_uuid) {
        // 심지어 동시 로그인이라면
        if (response.mschat_session_idx != null) {
          console.log('동시로그인을 하시고 계시군요!');
          setIsDuplicateLogin(true);
          showBlockingDuplicateLoginModal();
        } else {
          console.log('최근에 접속한 브라우저가 아닙니다.');
          setIsOtherBrowserConnection(() => true);
          dispatch(userActions.setIsOtherBrowserConnection(true));
          return 'otherBrowserConnection';
        }
      } else {
        return 'success';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const showBlockingDuplicateLoginModal = () => {
    Swal.fire({
      icon: 'info',
      title: '중복 로그인 알림',
      html: `
    <p>새로운 브라우저에서 채팅을 이용하시려면 기존에 사용했던 채팅을 종료해 주세요.</p>
    <p>채팅 종료 후 새로운 브라우저에서 재접속 시 채팅을 이용할 수 있으며 기존에 주고받은 메시지의 접근은 불가합니다.</p>
  `,
      allowOutsideClick: false,
      showCancelButton: false,
    }).then((swal) => {
      if (swal.isConfirmed) {
        fetchData();
      }
    });
  };

  // region ******************************** Form ********************************
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    trigger,
  } = useForm({
    defaultValues: {
      enc_room_info: roomUrl,
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      const result = await fetchData();
      // console.log('fetchData result', result);
      if (result === 'success') {
        sendPasswordApi(data);
        return;
      } else if (result === 'otherBrowserConnection') {
        Swal.fire({
          icon: 'info',
          title: '다른 브라우저 로그인',
          html: `
   <p>기존에 사용하던 브라우저가 아닙니다.</p>
  <p>새로운 브라우저에서 채팅을 시작하시면 기존에 주고받은 메시지의 접근이 불가합니다.</p>
  <p>진행을 원하시지 않는 경우 기존에 접속했던 브라우저에서 채팅방에 접속해 주세요.</p>
`,
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((swal) => {
          if (swal.isConfirmed) {
            sendPasswordApi(data);
          }
        });
      }
    },
    [isDuplicateLogin, isOtherBrowserConnection],
  );

  function sendPasswordApi(data) {
    Api.submitPassword(data)
      .then((result) => {
        if (result.Code === 200 || result.Code === 210) {
          Swal.fire({
            title: '비밀번호 확인 완료',
            text: '채팅방에 입장합니다.',
            allowOutsideClick: false,
            showCancelButton: false,
          }).then((swal) => {
            if (swal.isConfirmed) {
              dispatch(userActions.setPassword(data.PASSWORD));
              navigate(`/chat/${roomUrl}`);
            }
          });
        } else {
          Swal.fire({
            // httpStatusCode가 400 or 500일때
            title: '비밀번호 불일치',
            text: `비밀번호가 틀렸습니다.\n비밀번호를 다시 입력해 주세요.`,
          });
        }
      })
      .catch((result) => {
        console.error('error result', result);
        Swal.fire({
          // httpStatusCode가 400 or 500일때
          icon: 'error',
          text: '현재 서버에 문제가 있습니다. 관리자에게 문의해주세요.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  // endregion ******************************** Form ********************************

  // =================================================
  return (
    <section className="C_auth">
      <div className="header">
        <h1>비밀번호 입력</h1>
        <p>채팅방을 개설할 때 설정했던 비밀번호를 입력해 주세요.</p>
      </div>
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}>
        <div className="input_container password">
          <div className="input_box">
            <input
              type="PASSWORD"
              placeholder="비밀번호"
              className={classnames({error: errors.password})}
              {...register('PASSWORD', {required: '비밀번호를 입력해주세요.'})}
              onBlur={() => trigger('PASSWORD')}
            />
          </div>
          {errors.PASSWORD && <div className="error_message">{errors.PASSWORD.message}</div>}
        </div>

        <div className="bottom_container">
          <button type="submit">인증하기</button>
          <a href="mailto:support@medistaff.co.kr">문의하기 support@medistaff.co.kr</a>
        </div>
      </form>
    </section>
  );
}
