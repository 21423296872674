import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {fileDownload} from '../../../class/Token';
import dayjs from 'dayjs';
import {extractFileExtension} from '../../../utils/Functions';
import {produce} from 'immer';
import {decrypt_file} from '../../../utils/ChatCryptoFunctions';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {ReactComponent as ClipIcon} from '../../../image/icon/clip.svg';
import {useDispatch, useSelector} from 'react-redux';
import {modalActions} from 'modules/modal';
import classnames from 'classnames';

function CIFile({item, setOpenImageData, scrollbarRef}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const {data: fileDownLoadData} = useQuery(
    ['fileItemDownLoad', item],
    () => fileDownload(user.roomInfo.mschat_room_idx, item.file_path, user.token),
    {
      enabled: Object.keys(item).includes('file_type') && item.file_type !== 'NONE',
      onError: (error) => {
        console.log('파일 다운로드 실패', error);
        toast.dark('파일 다운로드에 실패하였습니다.', {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: 'fileDownLoadFailure',
        });
      },
    },
  );
  // let memberData = commonContext.roomInfo?.room_members.find((item) => item.is_me === 'n');
  window.fileDownLoadData = fileDownLoadData;
  dayjs.locale('ko');

  useEffect(() => {
    downloadedFileCreate();
  }, [fileDownLoadData]);

  const [fileHtml, setFileHtml] = useState('');

  // 이미지 클릭시 확대, 저장이 가능한 모달 호출하기
  function openImageModal(item, url) {
    // console.log("openImageModal",item);
    const messageJson = JSON.parse(item.message);
    const extension = extractFileExtension(messageJson.file_name);

    setOpenImageData(
      produce((draft) => {
        draft.url = url;
        draft.extension = extension;
        draft.sender_name = user.roomInfo?.room_members.find(
          (i) => i.user_idx === item.user_idx,
        ).user_name;
        draft.message_send_t = item.message_send_t;
        draft.mschat_message_idx = item.mschat_message_idx;
      }),
    );
  }

  async function downloadedFileCreate() {
    if (!fileDownLoadData) return;
    let fileJsx = [];
    const messageJson = JSON.parse(item.message);
    let file_paths = item.file_path.split(/\//);

    // 파일 복호화
    let file_dec_result = await decrypt_file(
      messageJson.file_key,
      messageJson.hmac_key,
      messageJson.nonce,
      file_paths[file_paths.length - 1],
      fileDownLoadData,
    );
    let file_blob = new Blob([file_dec_result], {type: 'application/octet-stream'});
    let url = window.URL.createObjectURL(file_blob);

    function handleFileDownloadClick(url, fileName) {
      Swal.fire({
        title: '파일 다운로드',
        text: '파일을 다운로드하시겠습니까?',
        allowOutsideClick: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
      }).then((result) => {
        if (result.isConfirmed) {
          // 다운로드 실행
          try {
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.dismiss();
            toast.dark('파일 다운로드가 완료되었습니다.', {
              position: toast.POSITION.BOTTOM_CENTER,
              toastId: 'fileDownLoadSuccess',
            });
          } catch (error) {
            toast.dark('파일 다운로드에 실패하였습니다.', {
              position: toast.POSITION.BOTTOM_CENTER,
              toastId: 'fileDownLoadFailure',
            });
          }
        }
      });
    }

    if (item.file_type === 'OTHER') {
      fileJsx.push(
        <div
          key={url}
          onClick={(e) => {
            if (item.message_status === 'RED' || item.message_status === 'YELLOW') {
              dispatch(modalActions.webMessageEvent({e, item}));
            } else {
              handleFileDownloadClick(url, messageJson.file_name, messageJson.file_size);
            }
          }}
          className="file_other_box">
          <div className="icon">
            <ClipIcon />
          </div>
          <div>
            <div className="name">{messageJson.file_name}</div>
            <div className="size">{messageJson.file_size}B</div>
          </div>
        </div>,
      );
    } else if (item.file_type === 'PHOTO') {
      const objectURL = window.URL.createObjectURL(file_blob);

      fileJsx.push(
        // <a href={url} download={messageJson.file_name}>
        <img
          className={classnames({
            me: item.is_sending === true,
            you: item.is_sending === false,
          })}
          key={url}
          onClick={(e) => {
            if (item.message_status === 'RED' || item.message_status === 'YELLOW') {
              dispatch(modalActions.webMessageEvent({e, item}));
            } else {
              openImageModal(item, url);
            }
          }}
          style={{maxWidth: '300px'}}
          src={url}
          alt={file_paths[file_paths.length - 1]}
          title={`${messageJson.file_name}(${messageJson.file_size}B)`}
          onLoad={() => {
            // 이미지 로딩이 완료되었을 때 실행될 로직을 여기에 추가
            if (item.is_sending === true) {
              scrollbarRef.current.scrollToBottom();
            }
          }}
        />,
      );
      window.URL.revokeObjectURL(objectURL); // 메모리 누수를 방지
    }
    setFileHtml(fileJsx);
  }
  // *********************************************
  return <div className="file_box">{fileHtml}</div>;
}
export default React.memo(CIFile);
