import React, {useContext} from 'react';
import {ReactComponent as MenuIcon} from '../../image/icon/hamberger_menu.svg';
import {CommonContext} from '../../context/ComomContext';
import {useSelector} from 'react-redux';

export default function ChatHeader({setIsOpenSetting}) {
  const user = useSelector((state) => state.user);

  // =================================================
  return (
    <section className="C_ChatHeader">
      <div className="user_name">{user.roomInfo?.mschat_room_name}</div>
      <div
        className="menu_btn"
        onClick={() => {
          setIsOpenSetting(true);
        }}>
        <MenuIcon />
      </div>
    </section>
  );
}
