import {useState} from 'react';
import {ModalDispatchContext, ModalStateContext} from './ModalContext';

export const ModalProvider = ({children}) => {
  // 현재 열려 있는 모달들
  const [openedModals, setOpenedModals] = useState([]);
  // window.openedModals = openedModals;
  const open = (plusId) => {
    const newOpenedModals = [plusId];
    // 동시에 두개 열 필요가없다.
    setOpenedModals(newOpenedModals);
    // setOpenedModals(plusId);
    document.body.style.overflow = 'hidden';
  };

  const close = (closedId) => {
    // console.log("closedId : "+closedId)
    const newOpenedModals = openedModals.filter((modal) => modal !== closedId);
    setOpenedModals(newOpenedModals);
    document.body.style.overflow = 'auto';
  };

  const change = (closedId) => {
    // console.log("closedId : "+closedId)
    const newOpenedModals = openedModals.filter((modal) => modal !== closedId);
    setOpenedModals(newOpenedModals);
    document.body.style.overflow = 'auto';
  };

  const allClose = () => {
    setOpenedModals([]);
    document.body.style.overflow = 'auto';
  };

  const openedModalsValue = {
    modalState: {openedModals},
    actions: {setOpenedModals},
  };

  const dispatch = {open, close, allClose};

  return (
    // <PDFViewer>
    <ModalDispatchContext.Provider value={dispatch}>
      <ModalStateContext.Provider value={openedModalsValue}>{children}</ModalStateContext.Provider>
    </ModalDispatchContext.Provider>
    // </PDFViewer>
  );
};
