import {useState} from 'react';
import {CommonContext} from './ComomContext';

export const CommonProvider = ({children}) => {
  const setIsConnected = (data) => {
    // setCommonState((prevState) => ({
    //   ...prevState,
    //   isConnected: data,
    // }));
  };
  const [commonState, setCommonState] = useState({
    // isConnected: 0,
    // setIsConnected,
  });
  return <CommonContext.Provider value={commonState}>{children}</CommonContext.Provider>;
};
