import React, {useEffect, useState} from 'react';
import {ReactComponent as ArrowLeftIcon} from 'image/icon/arrow_left.svg';
import {ReactComponent as InfoIcon} from 'image/icon/info.svg';
import './setting.scss';
import {convertToHex} from '../../utils/Functions';
import {useOutletContext} from 'react-router-dom';
import {Api, getQR} from '../../api/api';
import {useQuery} from 'react-query';
import {Token} from 'class/Token';
import useSocket from '../../hook/useSocket';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import {chatActions} from 'modules/chat';
import Swal from 'sweetalert2';
import IndexedDB from 'class/IndexedDB';
import useDynamicViewportHeight from '../../hook/useDynamicViewportHeight';

function EncryptionKey({setIsOpenEncrypt}) {
  const vh = useDynamicViewportHeight();
  const dbInstance = new IndexedDB();
  const [showEncryptionKeyReason, setShowEncryptionKeyReason] = useState(false);
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chat);
  const tokenClass = new Token();
  const {visaCheckData} = useOutletContext();
  const {socketSend} = useSocket('stompchat');
  dayjs.locale('ko');
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentX3dhKeyInfo();
    async function getCurrentX3dhKeyInfo() {
      if (!chat.currentX3dhKeyInfo) {
        let cur_x3dh_key_info = await dbInstance.get_last_x3dh_key(
          visaCheckData['user_idx'],
          visaCheckData['mschat_room_idx'],
        );
        if (!cur_x3dh_key_info) return;
        dispatch(chatActions.setCurrentX3dhKeyInfo(cur_x3dh_key_info));
      }
    }
  }, [chat.currentX3dhKeyInfo]);

  // ************************************************************************

  const {data: requestSessionData} = useQuery(
    ['requestSession', visaCheckData],
    () => Api.requestSession(visaCheckData, user.password),
    {
      enabled: !!visaCheckData && !!user.password,
    },
  );

  const {data: qrData} = useQuery(
    ['qrData', chat.currentX3dhKeyInfo],
    async () => {
      let cur_x3dh_key_info = await dbInstance.get_last_x3dh_key(
        visaCheckData['user_idx'],
        visaCheckData['mschat_room_idx'],
      );
      return getQR(
        `https://share.medistaff.co.kr/share?link_action=qr&link_idx=${convertToHex(
          `1^${user.roomInfo.mschat_room_idx}^${
            user.roomInfo?.room_members.find((item) => item.is_me === 'y').user_idx
          }^`,
        )}${cur_x3dh_key_info.main_chain_key_hash}`,
      );
    },
    {enabled: !!chat.currentX3dhKeyInfo && !!user.roomInfo},
  );

  async function goHandshake() {
    try {
      Swal.fire({
        title: '암호키 변경',
        text: '암호키를 변경하시겠습니까? 변경된 암호키는 상단 더보기 메뉴에서 확인이 가능합니다.',
        allowOutsideClick: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '암호키 변경',
      }).then(async (swal) => {
        if (swal.isConfirmed) {
          await tokenClass.handshake(visaCheckData, socketSend, requestSessionData);
        }
      });
    } catch (error) {
      console.error('악수 진행 실패 error', error);
    }
  }

  // =================================================
  return (
    <>
      {showEncryptionKeyReason && (
        <div className="C_EncryptionKeyReasonModal">
          <div className="body">
            <div className="header">암호키 변경은 왜 하나요?</div>
            <div className="content">
              <p>
                모든 메시지는 암호화하여 보호되는데 암호화된 메시지는 채팅 환경에 따라 복호화에
                실패할 수 있습니다.
              </p>
              <p>메시지 복호화에 실패 시 암호키를 변경하면 채팅은 정상적으로 동작합니다.</p>
              <p>메시지 복호화에 실패하더라도 보안상 문제는 없으니 안심하고 채팅을 이용하세요.</p>
              <p>또한 아무 문제가 없더라도 보안을 강화하기 위해 암호키를 변경할 수 있습니다.</p>
            </div>
            <div className="footer" onClick={() => setShowEncryptionKeyReason(false)}>
              확인
            </div>
          </div>
        </div>
      )}
      <section className="C_EncryptionKey" style={{height: `${vh}`}}>
        <div className="header">
          <div className="aaa">
            <ArrowLeftIcon
              className="cursor-pointer"
              onClick={() => {
                setIsOpenEncrypt((prev) => !prev);
              }}
            />
          </div>
          <div className="text">암호화 키</div>
        </div>

        {!chat.currentX3dhKeyInfo ? (
          <div className="body">
            <div className="top no_key">
              <InfoIcon />
              <h1>암호키 없음</h1>
              <div className="no_key_guide">
                <div>현재 암호화 키가 없습니다.</div>
                <div>하단의 버튼을 눌러 새로운 암호키를 생성하세요.</div>
              </div>
            </div>
            <div className="flex-1"></div>
            <div className="bottom">
              <button
                type="button"
                onClick={() => {
                  goHandshake();
                }}>
                암호키 생성하기
              </button>
            </div>
          </div>
        ) : (
          <div className="body">
            <div className="top">
              <div className="key">
                <div className="qr">{qrData && <img src={qrData} alt="qr" />}</div>
                <div className="hash">{chat.currentX3dhKeyInfo?.main_chain_key_hash || ''}</div>
              </div>
              <div className="guide">
                <p>
                  채팅에서 주고받은 모든 메시지는 <b>종단간 암호화</b>로 보호되고 있습니다.
                </p>
                <p>
                  보안을 검증하려면 상단의 번호를 비교하거나, 서로의 QR코드를 스캔해 확인이
                  가능합니다.
                </p>
                <p className="blue cursor-pointer" onClick={() => setShowEncryptionKeyReason(true)}>
                  암호키 자세히 알아보기
                </p>
              </div>
            </div>
            <div className="flex-1"></div>
            <div className="bottom">
              <button type="button" onClick={() => goHandshake()}>
                암호키 변경하기
              </button>
              <div className="change_guide">
                최근 암호키 변경{' '}
                {dayjs(chat.currentX3dhKeyInfo?.x3dh_create_t).format('YYYY. MM. DD. a hh:mm')}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
export default React.memo(EncryptionKey);
