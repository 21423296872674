import React, {useCallback, useContext, useEffect, useState} from 'react';
import FormFunctions from '../../utils/FormFuntions';
import classnames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import {ReactComponent as NotCheckIcon} from 'image/icon/not-check.svg';
import {ReactComponent as CheckedIcon} from 'image/icon/checked.svg';
import Swal from 'sweetalert2';
import {Api, AuthApi} from '../../api/api';
import {CommonContext} from '../../context/ComomContext';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import AuthAgreement from './AuthAgreement';
import {produce} from 'immer';
import {getRoomUrl} from '../../utils/Functions';

/*  Code:200
  alien_type:"PT"
  has_valid_visa:"y"
  mschat_room_idx:605
  password_verified:"n"
  user_auth_code:"862159"
  user_contact_number:"dfsfsdfsds"
  user_idx:28213*/
export default function NormalUserLogin() {
  const {visaCheckData} = useOutletContext();
  const navigate = useNavigate();
  const authApi = new AuthApi();
  const location = useLocation();
  const roomUrl = getRoomUrl(location);

  //동의 여부
  const [isMvno, setIsMvno] = useState(false);
  const [agreementState, setAgreementState] = useState(['n', 'n', 'n', 'n', 'n']);
  // region ************************ Form **************************

  const [isLoading, setLoading] = useState(false);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {errors},
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      enc_room_info: roomUrl,
      SEX_CD: 'M',
      NTV_FRNR_CD: 'L', // 내국인

      // 아래는 테스트를 위해서
      // BIRTHDAY: '19951129',
      // NAME: '메디스태프 정혜경',
    },
  });
  window.errors = errors;

  const onSendAuthCode = useCallback((data) => {
    setLoading(true);
    userAuthSend(data);
  }, []);

  // endregion ************************ Form ************************

  // 인증 번호 요청 보내기
  const [userAuthCodeResult, setUserAuthCodeResult] = useState();
  window.userAuthCodeResult = userAuthCodeResult;

  // 인증번호 요청하기
  function userAuthSend(data) {
    let newData = {
      ...data,
      NAME: data.NAME.trim(),
      AGREE1: 'Y',
      AGREE2: 'Y',
      AGREE3: 'Y',
      AGREE4: 'Y',
      AGREEALL: 'on',
    };
    Api.userAuthSend(newData)
      .then((result) => {
        console.log('result', result);
        if (result.Code === 200 || result.Code === 210) {
          if (!!result.Msg) {
            setValue('OTP_NO', result.Msg);
          }
          setUserAuthCodeResult(result.Msg || result.TX_SEQ_NO);
          // Swal.fire({ // httpStatusCode가 400 or 500일때
          //   icon: 'success',
          //   text: result.Msg
          // })
        } else {
          Swal.fire({
            // httpStatusCode가 400 or 500일때
            icon: 'error',
            text: result.Msg,
          });
        }
      })
      .catch((result) => {
        console.error('error result', result);
        Swal.fire({
          // httpStatusCode가 400 or 500일때
          icon: 'error',
          text: '현재 서버에 문제가 있습니다. 관리자에게 문의해주세요.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    return () => {
      setValue('user_auth_code', userAuthCodeResult);
    };
  }, [userAuthCodeResult]);

  function onLogin() {
    const data = watch(); // 현재 입력된 데이터 가져오기
    let newData = {
      ...data,
      NAME: data.NAME.trim(),
      TX_SEQ_NO: userAuthCodeResult,
      user_sex: data.SEX_CD,
      app_name: 'WEB_POP',
      mschat_room_idx: visaCheckData.mschat_room_idx,
      user_idx: visaCheckData.user_idx,
      user_country_code: '82',
      user_contact_number: data.TEL_NO,
    };
    authApi
      .login(newData)
      .then((result) => {
        if (result.Code === 200 || result.Code === 210 || result.Code === 201) {
          navigate(`/password/${roomUrl}`);
        } else {
          Swal.fire({
            // httpStatusCode가 400 or 500일때
            icon: 'error',
            text: result.Msg,
          });
        }
      })
      .catch((result) => {
        console.error('error result', result);
        Swal.fire({
          // httpStatusCode가 400 or 500일때
          icon: 'error',
          text: '현재 서버에 문제가 있습니다. 관리자에게 문의해주세요.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // =================================================
  return (
    <>
      <section className="C_NewUserLogin C_auth">
        <div className="header">
          <h1>본인 인증</h1>
          <p>채팅방 사용을 위해 본인 인증 후 비밀번호를 설정해 주세요.</p>
        </div>
        <form
          onSubmit={handleSubmit((data) => {
            onSendAuthCode(data);
          })}
          onKeyDown={(e) => FormFunctions.checkKeyDown(e)}>
          <div className="input_container name_and_check">
            <div className="input_box name">
              <input
                type="text"
                placeholder="이름 (영문입력 가능)"
                className={classnames({error: errors.conferenceUserName})}
                {...register('NAME', {required: '이름을 입력해주세요.'})}
                onBlur={() => trigger('NAME')}
              />
            </div>

            <Controller
              name="NTV_FRNR_CD"
              control={control}
              render={({field}) => (
                <div className="input_box check_foreigner">
                  <button
                    className={classnames('', {clicked: field.value === 'F'})}
                    type="button"
                    onClick={() => {
                      field.onChange(field.value === 'L' ? 'F' : 'L');
                    }}>
                    {field.value === 'F' ? <CheckedIcon /> : <NotCheckIcon />}
                    외국인
                  </button>
                </div>
              )}
            />
            {errors.NAME && <div className="error_message">{errors.NAME.message}</div>}
          </div>

          <div className="input_container birthday">
            <div className="input_box">
              <input
                type="text"
                placeholder="생년월일 8자리"
                className={classnames({error: errors.conferenceUserName})}
                {...register('BIRTHDAY', {
                  required: '생년월일 8자리를 입력해주세요.',
                  pattern: {
                    value: /^[0-9]{8}$/, // 0 이상의 6자리 정수
                    message: '생년월일 8자리를 입력해주세요.',
                  },
                })}
                onBlur={() => trigger('BIRTHDAY')}
              />
            </div>
            {errors.BIRTHDAY && <div className="error_message">{errors.BIRTHDAY.message}</div>}
          </div>

          <div className="input_container gender">
            <Controller
              name="SEX_CD"
              control={control}
              render={({field}) => (
                <>
                  <p>성별</p>
                  <button
                    className={classnames('flex flex-row items-center ', {
                      clicked: field.value === 'M',
                    })}
                    type="button"
                    onClick={() => {
                      field.onChange('M');
                    }}>
                    {field.value === 'M' ? <CheckedIcon /> : <NotCheckIcon />}
                    남성
                  </button>

                  <button
                    className={classnames('flex flex-row items-center ', {
                      clicked: field.value === 'F',
                    })}
                    type="button"
                    onClick={() => {
                      field.onChange('F');
                    }}>
                    {field.value === 'F' ? <CheckedIcon /> : <NotCheckIcon />}
                    여성
                  </button>
                </>
              )}
            />
            {errors.GENDER && <div className="error_message">{errors.GENDER.message}</div>}
          </div>

          <div className="input_container phone_number">
            <div className="input_box">
              <select
                {...register(`TEL_COM_CD`)}
                onChange={(e) => {
                  if (
                    e.target.value === '04' ||
                    e.target.value === '05' ||
                    e.target.value === '06'
                  ) {
                    setIsMvno(true);
                  } else {
                    setIsMvno(false);
                  }

                  setAgreementState((prev) =>
                    produce(prev, (draft) => {
                      draft[4] = 'n';
                    }),
                  );
                }}>
                <option value="01">SKT</option>
                <option value="02">KT</option>
                <option value="03">LGU+</option>
                <option value="04">알뜰폰 SKT</option>
                <option value="05">알뜰폰 KT</option>
                <option value="06">알뜰폰 LGU+</option>
              </select>
            </div>

            <div className="input_box TEL_NO">
              <input
                type="text"
                placeholder="휴대폰 번호"
                className={classnames({error: errors.conferenceUserName})}
                {...register('TEL_NO', {
                  required: '전화번호를 입력하세요.',
                  /*     pattern: {
                      value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/, // 0 이상의 6자리 정수
                      message: "올바른 전화번호 패턴이 아닙니다."
                    },*/
                })}
                onBlur={() => trigger('TEL_NO')}
              />
            </div>
            {errors.TEL_NO && <div className="error_message">{errors.TEL_NO.message}</div>}
          </div>

          <AuthAgreement
            isMvno={isMvno}
            agreementState={agreementState}
            setAgreementState={setAgreementState}
          />

          <div>
            {/* 모든 동의값이 true라면 */}
            {agreementState.includes('n') || (
              <>
                <div className="input_container auth_number">
                  <div className="input_box name">
                    <input
                      {...register('OTP_NO')}
                      type="text"
                      placeholder="인증번호 6자리 입력"
                      className={classnames({error: errors.OTP_NO})}
                    />
                  </div>
                  <button type="submit">인증요청</button>
                </div>
              </>
            )}
          </div>

          <div className="bottom_container">
            {!!userAuthCodeResult ? (
              <button type="button" onClick={() => onLogin()}>
                인증하기
              </button>
            ) : (
              <div>인증하기</div>
            )}
            <a href="mailto:support@medistaff.co.kr">문의하기 support@medistaff.co.kr</a>
          </div>
        </form>
        {/* 모달들 */}
      </section>
    </>
  );
}
