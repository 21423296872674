import React from 'react';
// import loadable from '@loadable/component';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'variable.css';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from './page/Main';
import * as PropTypes from 'prop-types';
import LoginEntrance from './page/auth/LoginEntrance';
import {ContextProviders} from './context/ContextProviders';
import Error from './page/layout/Error';
import SetPassword from './page/auth/SetPassword';
import ChatLogic from './page/chat/ChatLogic';
import PasswordCheck from './page/auth/PasswordCheck';
import Tests from './test/tests';
import {Provider} from 'react-redux';
import {store} from './store/index';
import ChatError from './page/layout/ChatError';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false, // 화면에서 벗어날 경우 데이터를 새로 불러올 것인지
      retry: false,
      retryDelay: 500,
    },
  },
});

Error.propTypes = {errorCode: PropTypes.number};
const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    lazy: () => import('./page/Main'),
    errorElement: <Error errorCode={404} />,

    children: [
      {path: '/password/*', element: <SetPassword />},
      {path: '/password-check/*', element: <PasswordCheck />},
      {path: '/chat/*', element: <ChatLogic />},
      {path: '/*', element: <LoginEntrance />},
      {path: '/error/:errorId', element: <ChatError />},
    ],
  },
  {path: '/test', element: <Tests />, lazy: () => import('./test/tests')},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <ContextProviders>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer
          hideProgressBar={true}
          limit={1}
          autoClose={2000}
          // autoClose={false}
          enableMultiContainer={false}
          // transition={opacity}
          pauseOnHover={false}
          closeButton={false}
          onClose={() => {
            toast.clearWaitingQueue();
          }}
          position={toast.POSITION.BOTTOM_CENTER}
        />
      </Provider>
    </ContextProviders>
  </QueryClientProvider>,
);
reportWebVitals();
