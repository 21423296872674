// https://11001.tistory.com/189#google_vignette
import {configureStore} from '@reduxjs/toolkit';
import user from '../modules/user';
import chat from '../modules/chat';
import {createLogger} from 'redux-logger/src';
import modal from '../modules/modal';

// 특정 유형은 로그 제외
const blacklist = {
  slice: ['modal'],
  action: ['chat/setUnReadMessagePercent', 'chat/setNewChat', 'user/deleteRoomInfo'],
};

const Logger = createLogger({
  predicate: (getState, action) => {
    const [sliceName, actionName] = action.type.split('/');

    if (blacklist.slice.includes(sliceName)) {
      return false; // Exclude entire slice
    }

    if (blacklist.action.includes(action.type)) {
      return false; // Exclude specific action
    }

    return true; // Include all other actions
  },
});

export const store = configureStore({
  reducer: {user, chat, modal},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(Logger),
  devTools: true,
  // process.env.NODE_ENV !== 'production', //  개발자 도구 on/off
  preloadedState: {}, // 스토어의 초기값
  // 개발자가 원하는 store enhancer를 미들웨어가 적용되는 순서보다 앞서서 추가 가능
  // enhancers: [reduxBatch],
});
