import React, {useState} from 'react';
import {TransformComponent, TransformWrapper, useControls} from 'react-zoom-pan-pinch';
import {ReactComponent as BackIc} from 'image/icon/ic_back.svg';
import {ReactComponent as DownloadIc} from 'image/icon/ic_download.svg';
import {ReactComponent as PlusIc} from 'image/icon/ic_plus.svg';
import {ReactComponent as MinusIc} from 'image/icon/minus.svg';
import {ReactComponent as TrashIc} from 'image/icon/ic_trash.svg';
import {produce} from 'immer';
import dayjs from 'dayjs';
import {chatActions} from '../../modules/chat';
import {useDispatch} from 'react-redux';
import {useOutletContext} from 'react-router-dom';
import IndexedDB from '../../class/IndexedDB';
import useDynamicViewportHeight from '../../hook/useDynamicViewportHeight';
function ImageModal({openImageData, setOpenImageData}) {
  dayjs.locale('ko');
  // console.log('openImageUrl', openImageUrl);
  const dispatch = useDispatch();
  const {visaCheckData} = useOutletContext();
  const dbInstance = IndexedDB.getInstance();
  const [isToggle, setIsToggle] = useState(true);
  function imageDownload(e) {
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = openImageData.url;
    link.download = `downloaded_image.${openImageData.extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function onToggle() {
    setIsToggle((prev) => !prev);
  }

  // 해당 파일 삭제
  async function deleteFile() {
    // UI에서 삭제
    dispatch(chatActions.deleteChat(openImageData.mschat_message_idx));

    // 먼저 DB에서 삭제
    let message = await dbInstance.get_message_by_idx(
      visaCheckData['user_idx'],
      visaCheckData['mschat_room_idx'],
      openImageData.mschat_message_idx,
    );
    message.is_delete = true;
    await dbInstance.put_message(message);

    // 해당 모달 숨기기
    setOpenImageData(
      produce((draft) => {
        draft.url = null;
      }),
    );
  }

  // =================================================
  return (
    <TransformWrapper
      initialScale={1}
      minScale={1}
      maxScale={10}
      transformEnabled={false}
      initialPositionX={0}
      initialPositionY={0}
      positionX={0}
      positionY={0}>
      {({zoomIn, zoomOut}) => (
        <React.Fragment>
          <article className="image_modal" onClick={onToggle}>
            <div className="tob">
              {isToggle && (
                <>
                  <BackIc
                    onClick={() => {
                      setOpenImageData(
                        produce((draft) => {
                          draft.url = null;
                        }),
                      );
                    }}
                  />
                  <div className="center">
                    <div className="title">{openImageData.sender_name}</div>
                    <div className="sub_title">
                      {dayjs(openImageData.message_send_t).format('YYYY. MM. DD. a hh:mm ')}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="middle">
              {/* 실제 이미지 영역*/}
              <TransformComponent set>
                <img src={openImageData.url} alt="clicked img" />
              </TransformComponent>
            </div>
            <div className="guide"></div>

            <div className="bottom">
              {isToggle && (
                <>
                  <DownloadIc onClick={(e) => imageDownload(e)} />

                  {/* 확대 버튼 축소 버튼 */}
                  <div className="flex flex-row gap-14 mins_plus_box">
                    <MinusIc
                      onClick={(e) => {
                        e.stopPropagation();
                        zoomOut();
                      }}
                    />
                    <PlusIc
                      onClick={(e) => {
                        e.stopPropagation();
                        zoomIn();
                      }}
                    />
                  </div>
                  <TrashIc
                    fill="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteFile();
                    }}
                  />
                </>
              )}
            </div>
          </article>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
}
export default React.memo(ImageModal);
